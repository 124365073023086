import React from 'react';
import { useParams } from 'react-router';
import { SynonymsTablePage } from 'src/components-bl/AugmentedSearch/Synonyms/SynonymsTablePage/SynonymsTablePage';
import { SynonymLanguage } from 'src/services/src/service/augmented-search/synonyms/types';
import { AppRoute, AppSwitch, RoutedComponentProps } from 'src/app-routes';
import { Dispatch } from 'src/components-bl';
import { useAppSelector } from 'src/hooks';
import { SynonymFormModal } from 'src/components-bl';
import { SynonymsFileUploadModalContainer } from '../../../../../components-bl/AugmentedSearch/Synonyms/SynonymImport/SynonymsFileUploadModalContainer';
import { EditSynonymContainer } from './EditSynonymContainer';

interface SynonymsTableContainerProps extends RoutedComponentProps {
  shopId: number;
  hasAugmentedSearchCatalog: boolean | null;
  availableLanguageCodes: string[];
  dispatch: Dispatch;
}

export const SynonymsTableContainer = ({
  shopId,
  permittedRouteMap,
  hasAugmentedSearchCatalog,
  availableLanguageCodes,
  dispatch,
}: SynonymsTableContainerProps): JSX.Element => {
  const { language } = useParams<{ language: SynonymLanguage }>();
  const synonymsData = useAppSelector(state => state.augmentedSearch.synonymsData);

  const isReady = language && !!synonymsData?.synonyms && hasAugmentedSearchCatalog !== null;

  return (
    <>
      <AppSwitch>
        <AppRoute
          route={permittedRouteMap.augmentedSearchCreateSynonym}
          Component={SynonymFormModal}
          componentProps={{
            permittedRouteMap,
            shopId,
            language,
            isReady,
            hasAugmentedSearchCatalog,
            dispatch,
          }}
          exact
        />
        <AppRoute
          route={permittedRouteMap.augmentedSearchImportSynonyms}
          Component={SynonymsFileUploadModalContainer}
          componentProps={{
            permittedRouteMap,
            shopId,
            language,
            isReady,
            hasAugmentedSearchCatalog,
            dispatch,
          }}
          exact
        />
        <AppRoute
          route={permittedRouteMap.augmentedSearchEditSynonym}
          Component={EditSynonymContainer}
          componentProps={{
            permittedRouteMap,
            shopId,
            language,
            isReady,
            hasAugmentedSearchCatalog,
            dispatch,
          }}
          exact
        />
      </AppSwitch>
      <SynonymsTablePage
        language={language}
        shopId={shopId}
        dispatch={dispatch}
        synonymsData={synonymsData}
        permittedRouteMap={permittedRouteMap}
        hasAugmentedSearchCatalog={hasAugmentedSearchCatalog}
        availableLanguageCodes={availableLanguageCodes}
        isReady={isReady}
      />
    </>
  );
};
