import { AxiosResponse } from 'axios';
import { ApiServiceBase } from '../api-service-base';
import {
  DataUsageModeEnum,
  IInsightsShopMetadataAPIModel,
  IKpiDataReponse,
  InsightsFilters,
  KPITypesEnum,
} from '../types/insights';

export class InsightsService extends ApiServiceBase {
  constructor() {
    super('shops');
  }

  private getUrl(shopId: number): string {
    return `${this.serviceBaseUri}/${shopId}/insights`;
  }

  async getInsightsKPIData({
    shopId,
    kpiType,
    filters,
    dataUsageMode,
    cancellationSignal,
  }: {
    shopId: number;
    kpiType: KPITypesEnum;
    filters: InsightsFilters;
    dataUsageMode: DataUsageModeEnum;
    cancellationSignal?: AbortSignal;
  }): Promise<IKpiDataReponse> {
    const params = { ...filters, dataUsageMode };

    const url = `${this.getUrl(shopId)}/kpis/${kpiType}`;

    const { data }: AxiosResponse<{ data: IKpiDataReponse }> = await this.httpService.get({
      url,
      requestConfig: { params, signal: cancellationSignal },
    });

    return data as unknown as IKpiDataReponse;
  }

  /**
   * @returns unique shop products list from snowflake
   */
  async getInsightsProductsNames({
    shopId,
    cancellationSignal,
  }: {
    shopId: number;
    cancellationSignal?: AbortSignal;
  }): Promise<string[]> {
    const url = `${this.getUrl(shopId)}/products`;

    const { data }: AxiosResponse<{ data: string[] }> = await this.httpService.get({
      url,
      requestConfig: { signal: cancellationSignal },
    });

    return data as unknown as string[];
  }

  /**
   * @returns IInsightsShopMetadataAPIModel - shop metdata - has metdata info on last stored analytics
   */
  async getInsightsShopMetdata({
    shopId,
    cancellationSignal,
  }: {
    shopId: number;
    cancellationSignal?: AbortSignal;
  }): Promise<IInsightsShopMetadataAPIModel> {
    const url = `${this.getUrl(shopId)}/metdata`;

    const { data }: AxiosResponse<{ data: IInsightsShopMetadataAPIModel }> =
      await this.httpService.get({
        url,
        requestConfig: { signal: cancellationSignal },
      });

    return data as unknown as IInsightsShopMetadataAPIModel;
  }
}

export const insightsService = new InsightsService();
