import React, { MouseEvent, useCallback, useState } from 'react';
import { startCase } from 'lodash';
import { AvailableIcons, MenuItem, TypographyType, TypographyVariant } from 'src/components-dummy';
import {
  SynonymLanguage,
  SynonymLanguageCode,
} from 'src/services/src/service/augmented-search/synonyms/types';
import { languagePropsMap } from '../../../helpers';
import {
  SynonymsPageHeaderTitleLanguage,
  SynonymsPageMainHeaderTitle,
  DropdownMenuStyled,
  MenuItemTextStyled,
  CaretIconStyled,
  FlagIcon,
} from './LanguageSelectorDropdown.styles';

interface LanguageSelectorDropdownProps {
  language: SynonymLanguage;
  availableLanguageCodes: string[];
  navigateToSynonymLanguage: (language: SynonymLanguage) => void;
}

export function LanguageSelectorDropdown({
  language,
  availableLanguageCodes,
  navigateToSynonymLanguage,
}: LanguageSelectorDropdownProps): JSX.Element {
  const [menuAnchorElement, setMenuAnchorElement] = useState<HTMLElement | null>(null);
  const isOpen = Boolean(menuAnchorElement);

  const onMenuClicked = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setMenuAnchorElement(event.currentTarget);
    },
    [setMenuAnchorElement]
  );

  const onMenuClose = useCallback(() => {
    setMenuAnchorElement(null);
  }, [setMenuAnchorElement]);

  const onLanguageClick = useCallback(
    (navigateToLanguage: SynonymLanguage) => () => {
      navigateToSynonymLanguage(navigateToLanguage);
      onMenuClose();
    },
    [navigateToSynonymLanguage]
  );

  const selectedLanguageCode = availableLanguageCodes.find(
    key => SynonymLanguageCode[key] === language
  );
  const iconName = selectedLanguageCode
    ? languagePropsMap[selectedLanguageCode].iconName
    : undefined;

  return (
    <>
      <SynonymsPageMainHeaderTitle
        type={TypographyType.Button}
        variant={TypographyVariant.MediumMedium}
      >
        Language /{iconName && <FlagIcon name={iconName as AvailableIcons} />}
        <SynonymsPageHeaderTitleLanguage onClick={onMenuClicked}>
          {startCase(language)}
          <CaretIconStyled name={AvailableIcons.SelectCaret} shouldRotate={isOpen} />
        </SynonymsPageHeaderTitleLanguage>
      </SynonymsPageMainHeaderTitle>
      <DropdownMenuStyled
        open={isOpen}
        anchorEl={menuAnchorElement}
        onClose={onMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: -5,
          horizontal: 'center',
        }}
      >
        {availableLanguageCodes.map(languageCode => (
          <MenuItem
            key={languageCode}
            onClick={onLanguageClick(SynonymLanguageCode[languageCode] as any)}
          >
            <MenuItemTextStyled
              type={TypographyType.Body}
              variant={TypographyVariant.MediumRegular}
            >
              <FlagIcon name={languagePropsMap[languageCode]?.iconName as AvailableIcons} />
              {startCase(SynonymLanguageCode[languageCode])}
            </MenuItemTextStyled>
          </MenuItem>
        ))}
      </DropdownMenuStyled>
    </>
  );
}
