import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AutoCompleteOption, useDebounce } from '../../../../components-dummy';
import { useAppSelector } from '../../../../hooks';
import { visualEditorActions } from '../../state';
import { SuggestedOffer } from '../../types';
import { PermittedRouteMap } from '../../../../app-routes';
import { PreviewFeature } from '../../../../services';
import { useSyncParamsWithState } from '../../helpers/use-sync-params-with-state';
import { useAddParamsToRoute } from '../../hooks/useAddParamsToRoute';

export interface DebouncedAutoCompleteProps {
  selectedValue: string;
  onChange: (value: string) => void;
  options: AutoCompleteOption[];
  onClose: VoidFunction;
  onOpen: VoidFunction;
  loading: boolean;
}

type UseAutoCompleteFetchState = {
  shopId: number;
  onOfferChange: (offer: SuggestedOffer | null) => void;
  selectedExperience?: PreviewFeature;
  initialValue?: string | null;
  permittedRouteMap?: PermittedRouteMap;
  selectedCollectionName?: string;
};

type UseAutoCompleteFetchStateReturn = DebouncedAutoCompleteProps & {
  reset: (e: React.MouseEvent) => void;
  onSelectOption: (selectedOption: any) => void;
};

export const useAutoCompleteFetchState = ({
  shopId,
  onOfferChange,
  selectedExperience,
  initialValue,
  permittedRouteMap,
  selectedCollectionName,
}: UseAutoCompleteFetchState): UseAutoCompleteFetchStateReturn => {
  const appDispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState<string>(initialValue || '');
  const { autocompleteOptions, isSearchLoading } = useAppSelector(state => state.visualEditor);

  useSyncParamsWithState({ paramName: 'search', setState: setSelectedValue });

  const { addParamsToRoute } = useAddParamsToRoute({
    shopId,
    route: permittedRouteMap?.visualEditor,
  });

  const refIsOpen = useRef(false);

  const fetchResultsFromApi = useCallback(
    (searchTerm: string) =>
      appDispatch(visualEditorActions.fetchSearchResults({ shopId, searchTerm })),
    [shopId]
  );

  const fetchFunctionDebounced = useDebounce(fetchResultsFromApi, 300);

  const onChange = useCallback(
    (searchValue: string): void => {
      setSelectedValue(searchValue);
      fetchFunctionDebounced(searchValue);
      addParamsToRoute({
        shopperExperience: selectedExperience,
        collectionName: selectedCollectionName,
        search: searchValue,
      });
    },
    [selectedCollectionName, selectedExperience]
  );

  const onClose = useCallback(() => {
    refIsOpen.current = false;
  }, []);

  const onOpen = useCallback(() => {
    refIsOpen.current = true;
  }, []);

  const reset = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      appDispatch(visualEditorActions.setAutocompleteOptions([]));
      setSelectedValue('');
      onOfferChange(null);
      addParamsToRoute({
        shopperExperience: selectedExperience,
        collectionName: selectedCollectionName,
      });
    },
    [onOfferChange, selectedCollectionName, selectedExperience]
  );

  useEffect(() => {
    fetchResultsFromApi('');
  }, [shopId, fetchResultsFromApi]);

  return {
    reset,
    selectedValue,
    onChange,
    loading: isSearchLoading,
    options: autocompleteOptions,
    onClose,
    onOpen,
    onSelectOption: onChange,
  };
};
