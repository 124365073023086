import React, { ForwardRefExoticComponent, useCallback, useMemo } from 'react';
import { generatePath, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppRoute, AppSwitch, RouteSettings, RoutedComponentProps } from 'src/app-routes';
import { useAppSelector } from 'src/hooks';
import { Page, PageSideNav, RouteGroup } from 'src/components-dummy';
import {
  AugmentedSearchCatalogFiltersContainer,
  AugmentedSearchDeepTagFiltersContainer,
  AugmentedSearchFiltersSettingsContainer,
} from '../AugSearchFilters';
import { augmentedSearchContainerActions } from './Actions';
import {
  AugmentedSearchContainerPageStyled,
  ButtonStyled,
  FooterStyled,
} from './AugmentedSearchContainer.styles';
import { useAugmentedSearchForm } from './hooks';
import { ContainerComponentProps } from '../AugSearchFilters/augmented-search-filters.types';
import {
  AugmentedSearchSettingsContainer,
  RelevancyTuningContainer,
  SynonymsContainer,
} from './components';
import { useFetchFiltersSets } from '../AugSearchFilters/hooks';

interface ComponentRoute<Props> {
  route: RouteSettings;
  Component: ForwardRefExoticComponent<Props>;
  props: Props;
  exact?: boolean;
}

export function AugmentedSearchContainer({ permittedRouteMap }: RoutedComponentProps): JSX.Element {
  const dispatch = useDispatch();

  const shopId = useAppSelector(state => state.shop.current?.shopId);

  const { filterSets } = useFetchFiltersSets({ currentShopId: shopId, dispatch });

  const filterSetId = filterSets?.[0]?.id;

  const routeMatch = useRouteMatch(permittedRouteMap.augmentedSearchSynonyms);

  const isSynonymsPage = Boolean(routeMatch);

  const { onSubmit, formApiRef, canSubmit, setCanSubmit } = useAugmentedSearchForm();

  const containerProps = useMemo(
    () => ({
      permittedRouteMap,
      filterSetId,
      ref: formApiRef,
      onSubmitStatusChange: setCanSubmit,
      filterSets,
      shopId,
      dispatch,
    }),
    [permittedRouteMap, filterSetId, setCanSubmit, formApiRef, filterSets, shopId, dispatch]
  );

  const searchSettingsRoutesGroup = useMemo(() => {
    return [
      {
        route: permittedRouteMap.augmentedSearchSettings,
        Component: AugmentedSearchSettingsContainer,
        props: containerProps,
      },
    ].filter(routeComponent =>
      Boolean(routeComponent.route)
    ) as ComponentRoute<ContainerComponentProps>[];
  }, [permittedRouteMap.augmentedSearchSettings, containerProps]);

  const filterRoutesGroup = useMemo(() => {
    if (!filterSetId) {
      return [];
    }

    return [
      {
        route: permittedRouteMap.filtersSettings,
        Component: AugmentedSearchFiltersSettingsContainer,
        props: containerProps,
      },
      {
        route: permittedRouteMap.catalogFilters,
        Component: AugmentedSearchCatalogFiltersContainer,
        props: containerProps,
      },
      {
        route: permittedRouteMap.deepTags,
        Component: AugmentedSearchDeepTagFiltersContainer,
        props: containerProps,
      },
    ].filter(routeComponent => !!routeComponent.route) as ComponentRoute<ContainerComponentProps>[];
  }, [
    permittedRouteMap.filtersSettings,
    permittedRouteMap.catalogFilters,
    permittedRouteMap.deepTags,
    containerProps,
    filterSetId,
  ]);

  const relevancyTuningRoutesGroup = useMemo(() => {
    return [
      {
        route: permittedRouteMap.augmentedSearchRelevancyTuning,
        Component: RelevancyTuningContainer,
        props: containerProps,
      },
    ].filter(routeComponent =>
      Boolean(routeComponent.route)
    ) as ComponentRoute<ContainerComponentProps>[];
  }, [permittedRouteMap.augmentedSearchRelevancyTuning, containerProps]);

  const synonymsRoutesGroup = useMemo(() => {
    return [
      {
        route: permittedRouteMap.augmentedSearchSynonyms,
        Component: SynonymsContainer,
        props: containerProps,
        exact: false,
      },
    ].filter(routeComponent =>
      Boolean(routeComponent.route)
    ) as ComponentRoute<ContainerComponentProps>[];
  }, [permittedRouteMap.augmentedSearchSynonyms, containerProps]);

  const sideNavRoutes = useMemo(
    () =>
      [
        // TODO: uncomment when we will have synonyms page
        // {
        //   routes: synonymsRoutesGroup.map(({ route }) => ({
        //     url: generatePath(route.path, { shopId }),
        //     title: route.title,
        //     pathTemplate: route.path,
        //   })),
        // },
        {
          routes: relevancyTuningRoutesGroup.map(({ route }) => ({
            url: generatePath(route.path, { shopId }),
            title: route.title,
            pathTemplate: route.path,
          })),
        },
        {
          routes: searchSettingsRoutesGroup.map(({ route }) => ({
            url: generatePath(route.path, { shopId }),
            title: route.title,
            pathTemplate: route.path,
          })),
        },
        filterSetId
          ? {
              routes: filterRoutesGroup.map(({ route }) => ({
                url: generatePath(route.path, { shopId, filterSetId }),
                title: route.title,
                pathTemplate: route.path,
              })),
            }
          : null,
      ].filter(Boolean) as RouteGroup[],
    [searchSettingsRoutesGroup, filterRoutesGroup, relevancyTuningRoutesGroup, shopId, filterSetId]
  );

  const routeComponents = useMemo(
    () =>
      [
        ...synonymsRoutesGroup,
        ...relevancyTuningRoutesGroup,
        ...searchSettingsRoutesGroup,
        ...filterRoutesGroup,
      ].map(({ route, Component, props, exact }) => (
        <AppRoute
          key={route.path}
          route={route}
          Component={Component}
          componentProps={props}
          exact={exact}
        />
      )),
    [searchSettingsRoutesGroup, filterRoutesGroup, relevancyTuningRoutesGroup, synonymsRoutesGroup]
  );

  const onNavigate = useCallback(
    url => {
      dispatch(augmentedSearchContainerActions.navigateTo({ navigateTo: url }));
    },
    [dispatch]
  );

  const navigateToShopperExperiences = useCallback(() => {
    const shopperExperiencesPath = permittedRouteMap.shopperExperiences?.path;

    if (shopperExperiencesPath) {
      const shopperExperiencesUrl = generatePath(shopperExperiencesPath, { shopId });

      onNavigate(shopperExperiencesUrl);
    }
  }, [shopId, permittedRouteMap.shopperExperiences, onNavigate]);

  return (
    <AugmentedSearchContainerPageStyled layout='sidebar' isFooterVisible={!isSynonymsPage}>
      <Page.SideBar>
        <PageSideNav
          pageTitle='Text Search'
          routeGroups={sideNavRoutes}
          handleNavigate={onNavigate}
        />
      </Page.SideBar>
      <Page.Content>
        <AppSwitch>{routeComponents}</AppSwitch>
        {!isSynonymsPage && (
          <FooterStyled>
            <ButtonStyled variant='tertiary' onClick={navigateToShopperExperiences}>
              Cancel
            </ButtonStyled>
            <ButtonStyled variant='primary' disabled={!canSubmit} onClick={onSubmit}>
              Save
            </ButtonStyled>
          </FooterStyled>
        )}
      </Page.Content>
    </AugmentedSearchContainerPageStyled>
  );
}
