import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHasDraft } from '../../../hooks';
import { Modal } from '../../../../components-dummy';
import { visualEditorActions } from '../../state';
import { SideMenuSettings } from '../SideMenu/SideMenuSettings';
import { VisualEditorResultsScreen } from '../VisualEditorResultsScreen/VisualEditorResultsScreen';
import { useFetchExperienceOffers, usePreviewModal } from '../../hooks';
import { VisualEditorProvider } from '../../context';
import { useStateControllerRef } from '../SideMenu/ShopperExperience/useStateControllerRef';
import { ShopFeatureToggles } from '../../../../services/src/service/types/shops';
import { useLocationListener } from '../../hooks/useLocationListener';
import { useSupportedExperience } from '../../hooks/useSupportedExperience';
import { useOpenProductDetailsModal } from '../../hooks/useOpenProductDetailsModal';
import { ProductDetailsModal } from '../ProductDetails/ProductDetailsModal';
import { PermittedRouteMap } from '../../../../app-routes';
import './VisualEditor.scss';

interface PreviewModalProps {
  shopId: number;
  permittedRouteMap: PermittedRouteMap;
  setForInternalUseOnly: boolean;
  featureToggles: ShopFeatureToggles;
}

export function VisualEditor({
  shopId,
  permittedRouteMap,
  setForInternalUseOnly,
  featureToggles,
}: PreviewModalProps): JSX.Element | null {
  const appDispatch = useDispatch();

  useLocationListener(appDispatch);
  useSupportedExperience(appDispatch);

  const { dispatchOffersThunk, fetchOffers, getPayload } = useFetchExperienceOffers(
    shopId,
    appDispatch
  );
  const { closeModal } = usePreviewModal({ shopId, permittedRouteMap });
  const previewDraft = useHasDraft() ? 'preview-draft' : '';

  const stateController = useStateControllerRef<any>();
  const modalRef = useRef<HTMLDivElement>(null);
  const resultsScreenRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [closeModal]);

  const {
    isProductDetailsModalOpen,
    openProductDetailsModal,
    closeProductDetailsModal,
    selectedSku,
  } = useOpenProductDetailsModal();

  useEffect(() => {
    appDispatch(visualEditorActions.getCategoryFilterRule({ shopId }));
  }, [shopId]);

  return (
    <VisualEditorProvider
      appDispatch={appDispatch}
      dispatchOffersThunk={dispatchOffersThunk}
      fetchOffers={fetchOffers}
      getPayload={getPayload}
    >
      <Modal className={`visual-editor-modal ${previewDraft}`} isOpened ref={modalRef}>
        <>
          <SideMenuSettings
            shopId={shopId}
            closeModal={closeModal}
            setForInternalUseOnly={setForInternalUseOnly}
            stateController={stateController}
            permittedRouteMap={permittedRouteMap}
            resultsScreenRef={resultsScreenRef}
            openProductDetailsModal={openProductDetailsModal}
          />
          <VisualEditorResultsScreen
            shopId={shopId}
            permittedRouteMap={permittedRouteMap}
            className={previewDraft}
            featureToggles={featureToggles}
            resultsScreenRef={resultsScreenRef}
            openProductDetailsModal={openProductDetailsModal}
          />
          {isProductDetailsModalOpen && (
            <ProductDetailsModal
              appDispatch={appDispatch}
              shopId={shopId}
              isModalOpen={isProductDetailsModalOpen}
              closeProductDetailsModal={closeProductDetailsModal}
              sku={selectedSku}
            />
          )}
        </>
      </Modal>
    </VisualEditorProvider>
  );
}
