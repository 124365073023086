import styled from '@emotion/styled';
import { SearchInput, Typography } from 'src/components-dummy';

export const SearchInputStyled = styled(SearchInput)`
  max-width: 616px;
  margin: 48px 0;
`;

export const WrapperSection = styled.div`
  width: fit-content;
  gap: 48px;
  max-width: 1200px;
`;

export const SubTitleTypographyStyled = styled(Typography)`
  margin-bottom: 48px;
  color: ${({ theme }) => theme.palette.custom['gray-10']};
`;
