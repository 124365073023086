import React from 'react';
import { lowerCase, startCase } from 'lodash';
import { MerchandisingRuleTypes } from 'src/services';
import { getWeightTranslation } from 'src/components-bl/MerchandisingRules/merchandisingRules.helpers';
import { EntityDiffSchema } from '../../types';
import { diffValueDisplayHelpers } from '../value-helpers';
import {
  convertSubRulesToReadonlyJsx,
  convertRedirectRuleToText,
  convertRegionCodesToNames,
  convertConditionsToJSX,
} from './helpers';

interface MerchandisingRuleDiffEntity
  extends Omit<MerchandisingRuleTypes.MerchandiseRule, 'weight' | 'action'> {
  actionWeight: string;
  'redirectRule.targetUrl': string;
  'redirectRule.displayName': string;
}

export const merchandisingRulesDiffsSchema: EntityDiffSchema<
  MerchandisingRuleDiffEntity,
  MerchandisingRuleTypes.MerchandiseRule
> = {
  actionWeight: {
    displayName: 'Main Action',
    relevantFieldPaths: ['action', 'weight'],
    getValue({ entity }) {
      const actionText = startCase(lowerCase(entity.action));
      let text: string;
      switch (entity.action) {
        case MerchandisingRuleTypes.RuleAction.Promote:
        case MerchandisingRuleTypes.RuleAction.Demote:
          text = `${actionText} by ${getWeightTranslation(entity.weight)}`;
          break;
        default:
          text = `${actionText}`;
      }

      return text;
    },
  },
  subRules: {
    displayName: 'Sub Rules',
    getValue({ entity, dataFields }) {
      return convertSubRulesToReadonlyJsx(entity.subRules, dataFields);
    },
  },
  sourceCondition: {
    displayName: 'Apply When',
    getValue({ entity }) {
      return convertConditionsToJSX(entity.sourceCondition);
    },
  },
  searchCondition: {
    displayName: 'Apply for specific terms',
    getValue({ entity }) {
      const text = entity?.searchCondition?.enabled
        ? diffValueDisplayHelpers.convertArrayToDisplayString(entity.searchCondition.terms)
        : diffValueDisplayHelpers.DEFAULT_EMPTY_VALUE;

      return text;
    },
  },
  filterByCondition: {
    displayName: 'Category Page',
    getValue({ entity }) {
      return convertConditionsToJSX(entity.filterByCondition);
    },
  },
  redirectRule: {
    displayName: 'Redirection rule',
    getValue({ entity }) {
      const text = entity?.redirectRule
        ? convertRedirectRuleToText(entity.redirectRule)
        : diffValueDisplayHelpers.DEFAULT_EMPTY_VALUE;

      return text;
    },
  },
  'redirectRule.targetUrl': {
    displayName: 'Target URL',
  },
  'redirectRule.displayName': {
    displayName: 'Display Name',
  },
  appliedDateRange: {
    displayName: 'Date range',
    relevantFieldPaths: [
      'appliedDateRange',
      'appliedDateRange.startDate',
      'appliedDateRange.endDate',
    ],
    getValue({ entity }) {
      const dateRange = entity?.appliedDateRange;
      const text = dateRange ? (
        <p>
          Start Date : {dateRange.startDate}
          <br />
          End Date : {dateRange.endDate}
          <br />
          Timezone : {dateRange.timeZone}
        </p>
      ) : (
        diffValueDisplayHelpers.DEFAULT_EMPTY_VALUE
      );

      return text;
    },
  },
  regions: {
    displayName: 'Regions',
    getValue({ entity }: { entity: MerchandisingRuleTypes.MerchandiseRule }): string {
      return entity.regions?.length
        ? convertRegionCodesToNames(entity.regions)
        : diffValueDisplayHelpers.DEFAULT_EMPTY_VALUE;
    },
  },
  context: {
    displayName: 'Context',
    getValue({ entity }) {
      return entity.context?.length
        ? diffValueDisplayHelpers.convertArrayToDisplayString(entity.context)
        : diffValueDisplayHelpers.DEFAULT_EMPTY_VALUE;
    },
  },
};
