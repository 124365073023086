import styled from '@emotion/styled';
import { AutoCompleteSingleValue, Icon, TextBox, Typography } from 'src/components-dummy';

export const ConditionRowDataStyled = styled.div<{ isSelected?: boolean }>`
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 4px 8px;
  user-select: none;
  width: 100%;

  ${({ isSelected, theme }) =>
    isSelected && `background-color: ${theme.palette.custom['primary-light']};`}

  > * + * {
    margin-left: 4px !important;
  }
`;

export const ConditionFirstRowStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  > * + * {
    margin-left: 4px !important;
  }
`;

export const ConditionRowStyled = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  width: ${({ width }) => width || '100%'};
`;

export const ConditionText = styled(Typography)<{ minWidth?: number }>`
  min-width: ${({ minWidth }) => minWidth || 5}em;
  color: ${({ theme }) => {
    return theme.palette.custom['gray-20'];
  }};
`;

export const TextBoxStyled = styled(TextBox)`
  min-width: 50%;
`;

export const TextBoxLabel = styled(Typography)`
  color: ${({ theme }) => {
    return theme.palette.custom['gray-20'];
  }};
  min-width: 9em;
`;

export const ConditionRowErrors = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const InformationIconStyled = styled(Icon)`
  margin-right: 15px;

  path {
    fill: ${({ theme }) => {
      return theme.palette.custom['gray-10'];
    }};
  }
`;

export const DragHandleWrapperStyled = styled.div<{ isDragging: boolean }>`
  opacity: ${({ isDragging }) => (isDragging ? 0 : 1)};
  display: flex;
  align-items: center;
  height: 32px;
  width: 32px;

  &:hover {
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
  }
`;

export const AutoCompleteSingleValueStyled = styled(AutoCompleteSingleValue)`
  width: 50%;
`;
