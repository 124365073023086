import React from 'react';
import { FiltersRow, FilterState, FilterVariant } from 'src/components-dummy';
import { FiltersRowWrapperStyled } from '../SynonymsTablePage.styles';
import {
  FiltersArguments,
  SynonymStatus,
} from 'src/services/src/service/augmented-search/synonyms/types';

export type SynonymsFiltersState = Partial<{
  [K in keyof FiltersArguments]: FilterState | undefined;
}>;

interface SynonymsFiltersProps {
  onChange: (partialFilters: SynonymsFiltersState) => void;
  filters: SynonymsFiltersState;
}

export const SynonymsFilters = ({ filters, onChange }: SynonymsFiltersProps): JSX.Element => {
  const filterComponents: JSX.Element[] = [
    <FiltersRow.Filter
      type={FilterVariant.SearchInput}
      key='searchTerm'
      uniqueFilterKey='searchTerm'
      componentConfig={{ placeholder: 'Search...' }}
    />,
  ];
  filterComponents.push(
    <FiltersRow.Filter
      type={FilterVariant.Menu}
      key='status'
      uniqueFilterKey='status'
      menuName='Status'
      tagPrefix='Status is'
      componentConfig={{
        options: [
          { value: SynonymStatus.Indexed, text: 'Indexed' },
          { value: SynonymStatus.PendingIndex, text: 'Pending index' },
        ],
      }}
    />
  );
  filterComponents.push(
    <FiltersRow.Filter
      type={FilterVariant.Menu}
      key='daysSinceLastEdit'
      uniqueFilterKey='daysSinceLastEdit'
      menuName='Last edit'
      tagPrefix='Last edit'
      componentConfig={{
        options: [
          { value: 1, text: 'Today' },
          { value: 7, text: 'Last week' },
          { value: 31, text: 'Last month' },
        ],
      }}
    />
  );

  return (
    <FiltersRowWrapperStyled>
      <FiltersRow onChange={onChange} filters={filters}>
        {filterComponents}
      </FiltersRow>
    </FiltersRowWrapperStyled>
  );
};
