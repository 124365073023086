import { format, differenceInSeconds } from 'date-fns';

export function getLastUpdatedText({
  updatedAt,
  createdAt,
}: {
  updatedAt: Date | undefined;
  createdAt: Date | undefined;
}): string {
  let resultText = '';

  if (!updatedAt) {
    return resultText;
  }
  if (createdAt && differenceInSeconds(updatedAt, createdAt) === 0) {
    return '';
  }
  const now = new Date();

  const timeDiffInSeconds = differenceInSeconds(now, updatedAt);
  const timeDiffInMinutes = timeDiffInSeconds / 60;
  const timeDiffInHours = timeDiffInMinutes / 60;
  const timeDiffInDays = timeDiffInHours / 24;

  if (timeDiffInSeconds < 60) {
    resultText += `${timeDiffInSeconds} seconds ago`;
  } else if (timeDiffInMinutes < 60) {
    resultText += `${timeDiffInMinutes.toFixed()} minutes ago`;
  } else if (timeDiffInHours < 24) {
    resultText += `${timeDiffInHours.toFixed()} hours ago`;
  } else if (timeDiffInDays < 7) {
    resultText += `${timeDiffInDays.toFixed()} days ago`;
  } else {
    resultText += format(updatedAt, "' 'LLLL d 'at' h:mm a");
  }

  return resultText;
}
