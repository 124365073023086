export const getDescription = (
  itemTitle: string,
  optionTitle: string,
  displayOptionTitleOnly?: boolean
): string => {
  if (displayOptionTitleOnly) {
    return optionTitle;
  }

  if (itemTitle) {
    return `${itemTitle}, ${optionTitle}`;
  }

  return optionTitle;
};
