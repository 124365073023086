import React from 'react';
import {
  InfoModal,
  Typography,
  TypographyType,
  TypographyVariant,
} from '../../../../components-dummy';
import alignToLeft from '../../Assets/images/alignToLeft.jpg';
import alignToRight from '../../Assets/images/alignToRight.jpg';
import grid from '../../Assets/images/grid.jpg';
import mixed from '../../Assets/images/mixed.jpg';
import overlay from '../../Assets/images/overlay.jpg';
import {
  InfoCard,
  InfoCardTitle,
  InfoModalAlignmentContainer,
  InfoModalLayoutContainer,
  InfoModalLayoutContent,
} from '../../DiscoveryBannerSettings.styles';

interface BannerSettingsInfoModalProps {
  isOpened: boolean;
  title: string;
  description: string;
  closeBtnTxt: string;
  onClickOutside: VoidFunction;
}

export const BannerSettingsInfoModal = ({
  isOpened,
  title,
  description,
  closeBtnTxt,
  onClickOutside,
}: BannerSettingsInfoModalProps) => {
  return (
    <InfoModal
      isOpened={isOpened}
      title={title}
      description={description}
      closeBtnTxt={closeBtnTxt}
      onClickOutside={onClickOutside}
    >
      <>
        <InfoModalAlignmentContainer>
          <InfoCard>
            <InfoCardTitle variant={TypographyVariant.SmallBold} type={TypographyType.Body}>
              Align to left
            </InfoCardTitle>
            <img src={alignToLeft} alt='Align to left example' />
          </InfoCard>
          <InfoCard>
            <InfoCardTitle variant={TypographyVariant.SmallBold} type={TypographyType.Body}>
              Align to right
            </InfoCardTitle>
            <img src={alignToRight} alt='Align to right example' />
          </InfoCard>
        </InfoModalAlignmentContainer>

        <InfoModalLayoutContainer>
          <Typography variant={TypographyVariant.LargeBold} type={TypographyType.Body}>
            Image layout grid
          </Typography>
          <InfoModalLayoutContent>
            <InfoCard>
              <InfoCardTitle variant={TypographyVariant.SmallBold} type={TypographyType.Body}>
                Grid
              </InfoCardTitle>
              <img src={grid} alt='Grid example' />
            </InfoCard>
            <InfoCard>
              <InfoCardTitle variant={TypographyVariant.SmallBold} type={TypographyType.Body}>
                Mixed
              </InfoCardTitle>
              <img src={mixed} alt='Mixed example' />
            </InfoCard>
            <InfoCard>
              <InfoCardTitle variant={TypographyVariant.SmallBold} type={TypographyType.Body}>
                Overlay
              </InfoCardTitle>
              <img src={overlay} alt='Overlay example' />
            </InfoCard>
          </InfoModalLayoutContent>
        </InfoModalLayoutContainer>
      </>
    </InfoModal>
  );
};
