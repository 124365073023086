export interface HttpConfig {
  withCredentials?: boolean;
  baseURL: string;
}

const domainPattern = /:\/\/(?<domain>.*)/;

export const backendUrl = process.env.REACT_APP_BACKEND_DOMAIN || 'http://localhost:3000';
export const backendDomain = backendUrl.match(domainPattern)?.groups?.domain || 'localhost:3000';

export const configuration: HttpConfig = {
  withCredentials: true,
  baseURL: `${backendUrl}/api/v1/`,
};

export const WS_CONFIG = {
  BACKEND_WS_DOMAIN: backendDomain,
  BACKEND_WS_GATEWAY: process.env.REACT_APP_BACKEND_WS_GATEWAY || '/api/ws',
  IS_SECURE: true,
};

export const SYTE_SUPPORT_MAIL = 'support@syte-vc.com';

export const IS_PRODUCTION = process.env.REACT_APP_ENVIRONMENT === 'production';
