import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { AugmentedSearchReducerState } from 'src/app-state-types';
import {
  augmentedSearchSettingsActions,
  relevancyTuningActions,
  synonymsActions,
} from 'src/components-bl';

const initialState: AugmentedSearchReducerState = {
  generalSettings: undefined,
  relevancyTuning: undefined,
  synonymsData: {
    synonyms: undefined,
    pagination: {
      totalCount: 0,
    },
    selectedSynonym: null,
    shouldFetch: false,
  },
};

export const augmentedSearchReducer = createReducer(initialState, builder => {
  builder
    .addCase(augmentedSearchSettingsActions.resetGeneralSettings, state => {
      return { ...state, generalSettings: undefined };
    })
    .addCase(relevancyTuningActions.resetRelevancyTuning, state => {
      return { ...state, relevancyTuning: undefined };
    })
    .addCase(synonymsActions.resetSynonyms, state => {
      return { ...state, synonymsData: { shouldFetch: false } };
    })
    .addCase(synonymsActions.resetSelectedSynonym, state => {
      if (state.synonymsData) {
        state.synonymsData.selectedSynonym = null;
      }
    })
    .addCase(synonymsActions.createSynonym.fulfilled, state => {
      return {
        ...state,
        synonymsData: {
          ...state.synonymsData,
          shouldFetch: true,
        },
      };
    })
    .addCase(synonymsActions.editSynonym.fulfilled, state => {
      return {
        ...state,
        synonymsData: {
          ...state.synonymsData,
          shouldFetch: true,
        },
      };
    })
    .addMatcher(isAnyOf(synonymsActions.deleteSynonym.fulfilled), state => {
      return {
        ...state,
        synonymsData: {
          ...state.synonymsData,
          shouldFetch: true,
        },
      };
    })
    .addMatcher(isAnyOf(synonymsActions.importSynonyms.fulfilled), state => {
      return {
        ...state,
        synonymsData: {
          ...state.synonymsData,
          shouldFetch: true,
        },
      };
    })
    .addMatcher(isAnyOf(synonymsActions.getSynonyms.fulfilled), (state, action) => {
      return { ...state, synonymsData: { ...action.payload, shouldFetch: false } };
    })
    .addMatcher(isAnyOf(synonymsActions.getSynonymById.fulfilled), (state, action) => {
      const selectedSynonym = action.payload;

      return {
        ...state,
        synonymsData: { ...state.synonymsData, selectedSynonym },
      };
    });

  builder
    .addMatcher(
      isAnyOf(
        augmentedSearchSettingsActions.getGeneralSettings.fulfilled,
        augmentedSearchSettingsActions.updateGeneralSettings.fulfilled
      ),
      (state, action) => {
        return {
          ...state,
          generalSettings: action.payload,
        };
      }
    )
    .addMatcher(
      isAnyOf(
        relevancyTuningActions.getRelevancyTuning.fulfilled,
        relevancyTuningActions.updateRelevancyTuning.fulfilled
      ),
      (state, action) => {
        return { ...state, relevancyTuning: action.payload };
      }
    );
});
