import Joi from 'joi';
import { ShopFeature } from 'src/app-types';
import { SchemaType } from 'src/hooks';
import { ShopTypes } from 'src/services';

export const featureTogglesFormSchema = {
  experiments: Joi.object({
    enabled: Joi.boolean().required(),
  }),
  camera: Joi.object({
    enabled: Joi.boolean().required(),
  }),
  discoveryButton: Joi.object({
    enabled: Joi.boolean().required(),
  }),
  inspirationGallery: Joi.object({
    enabled: Joi.boolean().required(),
  }),
  augmentedSearch: Joi.object({
    enabled: Joi.boolean().required(),
  }),
  similarItems: Joi.object({
    enabled: Joi.boolean().required(),
  }),
  shopTheLook: Joi.object({
    enabled: Joi.boolean().required(),
  }),
  personalization: Joi.object({
    enabled: Joi.boolean().required(),
  }),
  recentlyViewed: Joi.object({
    enabled: Joi.boolean().required(),
  }),
  frequentlyBoughtTogether: Joi.object({
    enabled: Joi.boolean().required(),
  }),
  discoveryStories: Joi.object({
    enabled: Joi.boolean().required(),
  }).required(),
  collections: Joi.object({
    enabled: Joi.boolean().required(),
  }),
  lexicon: Joi.object({
    enabled: Joi.boolean().required(),
  }),
  merchandisingRulesAiTags: Joi.object({
    enabled: Joi.boolean().required(),
  }),
  socialDiscovery: Joi.object({
    enabled: Joi.boolean().required(),
  }),
  discoveryBanner: Joi.object({
    enabled: Joi.boolean().required(),
  }),
  uiTemplates: Joi.object({
    enabled: Joi.boolean().required(),
  }),
  galleries: Joi.object({
    enabled: Joi.boolean().required(),
  }),
  sizeColorVariants: Joi.object({
    enabled: Joi.boolean().required(),
  }),
  emailMarketing: Joi.object({
    enabled: Joi.boolean().required(),
  }),
  fileBasedRecommendations: Joi.object({
    enabled: Joi.boolean().required(),
  }),
} as unknown as SchemaType<Partial<ShopTypes.ShopFeatureToggles>>;

export const allowlistFormSchema = {
  apiDomainsWhitelist: Joi.object({
    enabled: Joi.boolean().required(),
    whitelist: Joi.array().items(Joi.string()).required(),
  }),
  imageDownloadWhitelist: Joi.object({
    enabled: Joi.boolean().required(),
    whitelist: Joi.array().items(Joi.string()).required(),
  }),
} as unknown as SchemaType<Partial<ShopTypes.ShopFeatureToggles>>;

export const apiWhiteListDetails = `A list of allowed domains to make requests to our API for this shop. Ex: myshop.com, myshop.eu. Note - If this is enabled, and the white list is empty, all requests to our API for this shop will be blocked.`;
export const imageDownloadWhiteListDetails = `A list of allowed domains to download images for this shop. Ex: mystorage.com, mystorage.eu. Note - If this is enabled, and the white list is empty, requests to our API which include downloading images will be blocked.`;

export const featureKeyToTitleMap: Record<keyof ShopTypes.ShopFeatureToggles, string> = {
  [ShopFeature.Camera]: 'Camera',
  [ShopFeature.SimilarItems]: 'Similar Items',
  [ShopFeature.DeepTagReports]: 'AI Tag Editor',
  [ShopFeature.DeepTagReportsContentGeneration]: 'AI Tag Editor - Content Generation',
  [ShopFeature.DeepTagUploadCSV]: 'AI Tag Editor Upload',
  [ShopFeature.DiscoveryButton]: 'Discovery Button',
  [ShopFeature.InspirationGallery]: 'Inspiration Gallery',
  [ShopFeature.DiscoveryBanner]: 'Discovery Banner',
  [ShopFeature.Experiments]: 'A/B Test Experiments',
  [ShopFeature.AugmentedSearch]: 'Text Search',
  [ShopFeature.Personalization]: 'You May Also Like',
  [ShopFeature.ShopTheLook]: 'Shop The Look',
  [ShopFeature.RecentlyViewed]: 'Recently Viewed',
  [ShopFeature.FrequentlyBoughtTogether]: 'Frequently Bought Together',
  [ShopFeature.DiscoveryStories]: 'Discovery Stories (JS Layout)',
  [ShopFeature.Lexicon]: 'Lexicon Manager',
  [ShopFeature.MerchandisingRulesAiTags]: 'Merchandising rules with AI Tags',
  [ShopFeature.Collections]: 'Collections',
  [ShopFeature.SocialDiscovery]: 'Social Discovery (Instagram)',
  [ShopFeature.UiTemplates]: 'UI Editor',
  [ShopFeature.apiDomainsWhitelist]: 'API Domains Allowlist',
  [ShopFeature.imageDownloadWhitelist]: 'Image Download Allowlist',
  [ShopFeature.Filters]: 'Filters',
  [ShopFeature.AddToCart]: 'Add To Cart',
  [ShopFeature.Preview]: 'Preview',
  [ShopFeature.CatalogManager]: 'Catalogue Manager',
  [ShopFeature.Galleries]: 'Shoppable Galleries',
  [ShopFeature.SizeColorVariants]: 'Size And Color Variants',
  [ShopFeature.BrowsePLP]: 'Category Pages',
  [ShopFeature.EmailMarketing]: 'Email Marketing Widget',
  [ShopFeature.FileBasedRecommendations]: 'File Based Recommendations',
  [ShopFeature.Insights]: 'Insights',
};

export const featureSections = {
  visualSearch: {
    title: 'Visual Search',
    showPermissionsDropDown: false,
    features: [
      ShopFeature.Camera,
      ShopFeature.DiscoveryButton,
      ShopFeature.InspirationGallery,
      ShopFeature.DiscoveryBanner,
    ],
  },
  augmentedSearch: {
    title: 'Text Search',
    showPermissionsDropDown: false,
    features: [ShopFeature.AugmentedSearch],
  },
  recommendationEngines: {
    title: 'Recommendation Engines',
    showPermissionsDropDown: false,
    features: [
      ShopFeature.SimilarItems,
      ShopFeature.ShopTheLook,
      ShopFeature.Personalization,
      ShopFeature.RecentlyViewed,
      ShopFeature.FrequentlyBoughtTogether,
      ShopFeature.AddToCart,
    ],
  },
  collections: {
    title: 'Collections',
    showPermissionsDropDown: false,
    features: [ShopFeature.Collections, ShopFeature.BrowsePLP],
  },
  socialSuite: {
    title: 'Social Suite',
    showPermissionsDropDown: false,
    features: [ShopFeature.DiscoveryStories, ShopFeature.SocialDiscovery],
  },
  emailMarketing: {
    title: 'Email Marketing Suite',
    showPermissionsDropDown: true,
    features: [ShopFeature.EmailMarketing, ShopFeature.FileBasedRecommendations],
  },
  galleries: {
    title: 'Galleries',
    showPermissionsDropDown: true,
    features: [ShopFeature.Galleries],
  },
  consoleFeatures: {
    title: 'Console Features',
    showPermissionsDropDown: true,
    features: [
      ShopFeature.Experiments,
      ShopFeature.Lexicon,
      ShopFeature.DeepTagReports,
      ShopFeature.DeepTagUploadCSV,
      ShopFeature.DeepTagReportsContentGeneration,
      ShopFeature.UiTemplates,
      ShopFeature.Filters,
      ShopFeature.Preview,
      ShopFeature.CatalogManager,
      ShopFeature.MerchandisingRulesAiTags,
      ShopFeature.Insights,
    ],
  },
  APISettings: {
    title: 'API Settings',
    showPermissionsDropDown: false,
    features: [ShopFeature.SizeColorVariants],
  },
};
