import { useEffect, useState } from 'react';
import { skuAutoSuggestionActions } from '../../components-bl/MerchandisingRules/components/MerchandisingRuleForm/components/RuleFormConditionsSection/PinToPosition/SkuAutoSuggestion/SkuAutoSuggestion.actions';
import type { SkuConfiguration } from '../../components-bl/ProductCard/ProductCard';
import type { Dispatch } from '../../components-dummy/types';

interface UseFetchSkuProps {
  dispatch: Dispatch;
  shopId: number;
}

interface UseFetchSkuReturnType {
  sku: string;
}

export const useFetchSku = ({ dispatch, shopId }: UseFetchSkuProps): UseFetchSkuReturnType => {
  const [sku, setSku] = useState('');

  const fetchSku = async (searchTerm = '') => {
    try {
      const { values } = (await (
        dispatch(
          skuAutoSuggestionActions.getValues({
            shopId,
            searchTerm,
            limit: 1,
            fieldsToReturn: ['sku'],
          })
        ) as any
      ).unwrap()) as {
        values: SkuConfiguration[];
      };

      const [value] = values;
      setSku(value.sku);
    } catch (error) {
      setSku('');
    }
  };

  useEffect(() => {
    fetchSku('');
  }, []);

  return { sku };
};
