import React from 'react';
import { TypographyType, TypographyVariant } from 'src/components-dummy';
import { SectionWithTitleStyled, SubTitleTypographyStyled } from './SectionWithTitle.styles';

export interface SectionWithTitleProps {
  title?: string;
  children: JSX.Element | JSX.Element[];
}

export const SectionWithTitle = ({ title, children }: SectionWithTitleProps): JSX.Element => {
  return (
    <SectionWithTitleStyled>
      <SubTitleTypographyStyled
        variant={TypographyVariant.LargeRegular}
        type={TypographyType.Paragraph}
      >
        {title}
      </SubTitleTypographyStyled>
      {children}
    </SectionWithTitleStyled>
  );
};
