import { ValidationErrorKey } from 'src/containers/ToastManager/ActionsNotifications/validation-error-key';

const separators = /,|\n/;

export function parseTerms(inputTerms: string[]): string[] {
  const parsedTerms = inputTerms.flatMap(inputTerm => inputTerm.trim().split(separators));

  const validTerms = parsedTerms
    .filter(parsedTerm => parsedTerm.trim().length > 0)
    .map(parsedTerm => parsedTerm.trim());

  return validTerms;
}

export function extractDuplicateTermsFromError(
  errorDetails?: { path: string; message: string }[]
): string[] {
  return (errorDetails || [])
    .filter(({ path, message }: { path: string; message: string }) => {
      if (path !== 'phrase') {
        return false;
      }
      return message.startsWith(ValidationErrorKey.SynonymsDuplicateTerm);
    })
    .flatMap(({ message }: { message: string }) => {
      const termsPart = message.split(':')[1];
      return termsPart.split(',') || [];
    });
}

export function constructDuplicateTermsMessage(duplicateTerms: string[]): string {
  const hasMultipleDuplicates = duplicateTerms.length > 1;

  const termText = hasMultipleDuplicates ? 'terms' : 'term';
  const isText = hasMultipleDuplicates ? 'are' : 'is';

  return `The ${termText} "${duplicateTerms.join(', ')}" ${isText} included in another synonym group. Please review the existing group`;
}
