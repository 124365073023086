import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { HttpConfig } from '../common/configuration';
import { ApiError, AxiosRequestArguments } from './httpService-types';

const CANCELED_ERROR_KEY = 'ERR_CANCELED';

export const requestWasCancelledByUser = (error: ApiError) => error.errorKey === CANCELED_ERROR_KEY;

export class HTTPService {
  options: AxiosRequestConfig;

  axiosInstance: AxiosInstance;

  baseUrl: string;

  constructor(options: HttpConfig) {
    this.options = {
      ...options,
    };

    this.baseUrl = options.baseURL;

    this.axiosInstance = axios.create(this.options);
    this.initializeRequestInterceptor();
    this.initializeResponseInterceptor();
  }

  initializeRequestInterceptor(): void {
    this.axiosInstance.interceptors.request.use(HTTPService.handleRequest, HTTPService.handleError);
  }

  initializeResponseInterceptor(): void {
    this.axiosInstance.interceptors.response.use(
      HTTPService.handleResponse,
      HTTPService.handleError
    );
  }

  static handleResponse(response: AxiosResponse): AxiosResponse {
    return response;
  }

  static handleRequest(config: AxiosRequestConfig): AxiosRequestConfig {
    const newConfig = { ...config };
    return newConfig;
  }

  static async handleError(error: AxiosError): Promise<ApiError> {
    const formattedError = await HTTPService.errorFormatter(error);
    return Promise.reject<ApiError>(formattedError);
  }

  static async errorFormatter(error: AxiosError): Promise<ApiError> {
    const {
      response: { statusText = undefined, status = undefined, data: rawData = undefined } = {},
    } = (error as AxiosError<{ error?: ApiError }>) || {};
    const data = rawData && rawData instanceof Blob ? await new Response(rawData).json() : rawData;

    let message;
    if (data?.error?.message || data?.message) {
      message = data?.error?.message ?? data?.message;
    } else if (statusText) {
      message = statusText;
    } else {
      message = '';
    }

    const cancelledErrorKey = error.code === CANCELED_ERROR_KEY ? CANCELED_ERROR_KEY : undefined;

    const errorKey = data?.error?.errorKey || cancelledErrorKey || undefined;
    const additionalData = data?.error?.data || data?.error?.details || undefined;

    const formattedError: ApiError = {
      name: 'ApiError',
      code: status || 500,
      message,
      errorKey,
      stack: error.stack,
      data: additionalData,
    };

    return formattedError;
  }

  getUrlWithPathAppended(path: string): string {
    return new URL(path, this.baseUrl).href;
  }

  patch(args: AxiosRequestArguments): Promise<AxiosResponse> {
    const url = this.getUrlWithPathAppended(args.url);
    return this.axiosInstance.patch(url, args.data, args.requestConfig);
  }

  get(args: AxiosRequestArguments): Promise<AxiosResponse> {
    const url = this.getUrlWithPathAppended(args.url);
    return this.axiosInstance.get(url, args.requestConfig);
  }

  put(args: AxiosRequestArguments): Promise<AxiosResponse> {
    const url = this.getUrlWithPathAppended(args.url);
    return this.axiosInstance.put(url, args.data, args.requestConfig);
  }

  delete(args: AxiosRequestArguments): Promise<AxiosResponse> {
    const url = this.getUrlWithPathAppended(args.url);
    return this.axiosInstance.delete(url, args.requestConfig);
  }

  post(args: AxiosRequestArguments): Promise<AxiosResponse> {
    const url = this.getUrlWithPathAppended(args.url);
    return this.axiosInstance.post(url, args.data, args.requestConfig);
  }
}
