import React, { useCallback } from 'react';
import { generatePath } from 'react-router';
import { RoutedComponentProps } from 'src/app-routes';
import { synonymsActions } from '../SynonymsActions';
import { SynonymsFileUploadModal } from './SynonymsFileUploadModal';
import { SynonymLanguage } from '../../../../services/src/service/augmented-search/synonyms/types';
import { Dispatch } from '../../../types';

const EXAMPLE_FILE_PATH = `${process.env.PUBLIC_URL}/files/synonyms/synonyms_file_example.csv`;

interface SynonymsFileUploadModalContainerProps extends RoutedComponentProps {
  language: SynonymLanguage;
  shopId: number | undefined;
  hasAugmentedSearchCatalog: boolean;
  isReady: boolean;
  dispatch: Dispatch;
}

export const SynonymsFileUploadModalContainer = ({
  permittedRouteMap,
  shopId,
  language,
  hasAugmentedSearchCatalog,
  isReady,
  dispatch,
}: SynonymsFileUploadModalContainerProps): JSX.Element | null => {
  const onModalClose = useCallback(() => {
    if (permittedRouteMap.augmentedSearchSynonymsLanguage) {
      dispatch(
        synonymsActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.augmentedSearchSynonymsLanguage.path, {
            shopId,
            language,
          }),
        })
      );
    }
  }, [shopId, permittedRouteMap, dispatch, language]);
  const shouldRenderModal = shopId && isReady && hasAugmentedSearchCatalog;

  return shouldRenderModal ? (
    <SynonymsFileUploadModal
      shopId={shopId}
      dispatch={dispatch}
      onCancel={onModalClose}
      language={language}
      exampleFilePath={EXAMPLE_FILE_PATH}
    />
  ) : null;
};
