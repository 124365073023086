import React, { RefObject, forwardRef, useCallback } from 'react';
import { generatePath } from 'react-router';
import { ShopFeature } from 'src/app-types';
import { RelevancyTuning, FormApiRef } from 'src/components-bl';
import { AvailableIcons, Icon, Link, Page } from 'src/components-dummy';
import { useAppSelector } from 'src/hooks';
import { ContainerComponentProps } from 'src/containers/AugSearchFilters/augmented-search-filters.types';
import { useAugmentedSearchCatalog } from '../hooks/use-augmented-search-catalog';
import {
  HeaderTitlesWrapperStyled,
  PageHeaderStyled,
  PreviewButtonStyled,
} from '../AugmentedSearchContainer.styles';
import { augmentedSearchContainerActions } from '../Actions';

const RELEVANCY_TUNING_GUIDELINES_URL =
  'https://support.syte.ai/space/ET/3472457732/Augmented+Search+-+Relevancy+Tuning';

interface RelevancyTuningContainerProps extends Omit<ContainerComponentProps, 'filterSetId'> {
  showPreviewButton?: boolean;
}

export const RelevancyTuningContainer = forwardRef<FormApiRef, RelevancyTuningContainerProps>(
  (
    { shopId, showPreviewButton = true, permittedRouteMap, dispatch, onSubmitStatusChange },
    ref
  ) => {
    const relevancyTuning = useAppSelector(state => state.augmentedSearch.relevancyTuning);

    const { hasAugmentedSearchCatalog } = useAugmentedSearchCatalog({ shopId, dispatch });

    const navigateToVisualEditor = useCallback(() => {
      if (shopId && permittedRouteMap.visualEditorEditRelevancyTuning) {
        const path = generatePath(permittedRouteMap.visualEditor.path, {
          shopId,
        });
        const url = `${path}?shopperExperience=${ShopFeature.AugmentedSearch}`;

        dispatch(
          augmentedSearchContainerActions.navigateTo({
            navigateTo: url,
          })
        );
      }
    }, []);

    return (
      <>
        <PageHeaderStyled>
          <HeaderTitlesWrapperStyled>
            <Page.Title>Search Relevancy Tuning</Page.Title>
            {hasAugmentedSearchCatalog && (
              <Page.SubTitle>
                Adjust the importance of each field to refine search results, and use the preview to
                simulate changes in the visual editor.
              </Page.SubTitle>
            )}
            <Link href={RELEVANCY_TUNING_GUIDELINES_URL}>
              Learn more about this
              <Icon name={AvailableIcons.ArrowRec} />
            </Link>
          </HeaderTitlesWrapperStyled>
          {showPreviewButton && hasAugmentedSearchCatalog && (
            <PreviewButtonStyled
              startIcon={AvailableIcons.PreviewButton}
              onClick={navigateToVisualEditor}
            >
              Preview
            </PreviewButtonStyled>
          )}
        </PageHeaderStyled>
        <RelevancyTuning
          formApiRef={ref as RefObject<FormApiRef>}
          shopId={shopId}
          hasAugmentedSearchCatalog={hasAugmentedSearchCatalog}
          relevancyTuning={relevancyTuning}
          dispatch={dispatch}
          onSubmitStatusChange={onSubmitStatusChange}
        />
      </>
    );
  }
);
