import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import {
  MerchandisingRuleTypes,
  ShopDataFieldsSupportedFeatures,
  SyteProductType,
} from 'src/services';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import { FooterActionForm } from '../../../MerchandisingRules/components/MerchandisingRuleForm/RuleFormFooter';
import { useAppSelector, useValidateSchema } from '../../../../hooks';
import { UpdateMerchRuleArgs } from '../../../MerchandisingRules/components/MerchandisingRuleForm';
import { useDataFieldsBySupportedFeature } from '../../../hooks';
import { useDataFieldsLookupTable } from '../../../MerchandisingRules/components/useDataFieldsLookupTable';
import { RuleFormPage } from '../../../MerchandisingRules/components/RuleFormPage/RuleFormPage';
import { Page, TypographyVariant } from '../../../../components-dummy';
import { RuleGuideLink } from '../../../MerchandisingRules/components/RuleGuideLink/RuleGuideLink';
import { RuleFormGeneralSection } from '../../../MerchandisingRules/components/MerchandisingRuleForm/components/RuleFormGeneralSection/RuleFormGeneralSection';
import { RuleFormSyteProductSection } from '../../../MerchandisingRules/components/MerchandisingRuleForm/components/RuleFormSyteProductSection/RuleFormSyteProductSection';
import { RuleFormRegionSection } from '../../../MerchandisingRules/components/MerchandisingRuleForm/components/RuleFormRegionSection/RuleFormRegionSection';
import { RuleFormConditionsSection } from '../../../MerchandisingRules/components/MerchandisingRuleForm/components/RuleFormConditionsSection/RuleFormConditionsSection';
import { RuleFormScheduleDateSection } from '../../../MerchandisingRules/components/MerchandisingRuleForm/components/RuleFormScheduleDateSection/RuleFormScheduleDateSection';
import { ruleFormMappers } from '../../../MerchandisingRules/components/MerchandisingRuleForm/MerchandisingRuleForm.mappers';
import { syteProductToRuleSettingsMap } from '../../../MerchandisingRules/components/constants';
import {
  RuleDraft,
  rulesFormInitialData,
} from '../../../MerchandisingRules/components/MerchandisingRuleForm/MerchandisingRuleForm.config';
import { formValidationSchema } from '../../../MerchandisingRules/components/MerchandisingRuleForm/validation-schema';
import { visualEditorActions } from '../../state';
import { Mode } from '../../types/mode';
import { CategoryFilterRule } from '../../types/category-filter-rule';
import { ShopFeatureToggles } from '../../../../services/src/service/types/shops';

interface Props {
  shopId: number;
  onClose: VoidFunction;
  onSave: (payload: UpdateMerchRuleArgs) => void;
  rule?: MerchandisingRuleTypes.MerchandiseRule;
  mode: Mode;
  entityId?: string;
  predefinedProduct?: MerchandisingRuleTypes.MerchandisingRuleProduct;
  isReadOnly?: boolean;
  variantId?: string;
  isAiTagsEnabled: boolean;
  isSubmitting: boolean;
  categoryFilterRule?: CategoryFilterRule;
  featureToggles: ShopFeatureToggles;
}

export const EditMerchandisingRuleContainer = memo(
  ({
    shopId,
    rule,
    onClose,
    onSave,
    mode,
    entityId,
    predefinedProduct,
    isReadOnly,
    variantId,
    isAiTagsEnabled,
    isSubmitting,
    categoryFilterRule,
    featureToggles,
  }: Props): JSX.Element | null => {
    const dispatch = useDispatch();
    const dataFields = useAppSelector(state => state.dataFields.dataFields);
    const availableRegions = useAppSelector(state => state.shop.availableRegions);
    const overview = useAppSelector(state => state.merchandisingRules.overview);
    const [formStatus, setFormStatus] = useState({ canSubmit: false });

    const { supportedDataFields } = useDataFieldsBySupportedFeature({
      dataFields,
      feature: ShopDataFieldsSupportedFeatures.MerchandisingRules,
    });

    const { dataFieldsLookupTable, getDataFieldByName } = useDataFieldsLookupTable({
      dataFields: supportedDataFields,
    });

    const mappedRuleInitial = useMemo(() => {
      return ruleFormMappers.mapApiRuleToRuleDraft({
        rule,
        predefinedProduct,
        entityId,
        availableRegions,
      });
    }, [rule, predefinedProduct]);

    // rule draft state, based on original mapped rule
    const [ruleDraft, setRuleDraft] = useState(mappedRuleInitial);

    const isEditingDuplicatedRule = mode === Mode.create && !!rule;

    const [isDirty, setIsDirty] = useState(isEditingDuplicatedRule);

    // fetch rule settings by product, determines options for conditions, which apply when (different for augmented search)
    const ruleSettings = ruleDraft.product
      ? syteProductToRuleSettingsMap[ruleDraft.product]
      : undefined;

    const isShopMultiLocale = Boolean(availableRegions?.length);

    const { errors, validate, isValid } = useValidateSchema<
      Omit<RuleDraft, 'id' | 'entityId' | 'createdAt' | 'updatedAt'>
    >({
      schema: formValidationSchema,
      validateOnStart: false,
      initialData: mappedRuleInitial,
    });

    const canSubmit = isDirty && isValid;

    const validateRuleForm = useCallback(
      (ruleToValidate: RuleDraft) => {
        const skipValidation = mode === Mode.create && !isDirty;

        if (skipValidation) return;

        validate({
          dataToValidate: ruleToValidate,
          context: {
            dataFieldsLookupTable,
            ruleSettings,
            availableRegions,
          },
        });
      },
      [dataFieldsLookupTable, ruleSettings, mode, isDirty, availableRegions]
    );

    // reset to the initial mapped rule
    useEffect(() => {
      setRuleDraft(mappedRuleInitial);

      validateRuleForm(mappedRuleInitial);
    }, [shopId, mappedRuleInitial, setRuleDraft, dataFieldsLookupTable]);

    useEffect(() => {
      setFormStatus({ canSubmit });
    }, [canSubmit]);

    const updateIsDirty = (dirty: boolean) => {
      setIsDirty(dirty);
    };

    const onFieldChange = useCallback(
      (partialData: Partial<RuleDraft>) => {
        const newDraftState = { ...ruleDraft, ...partialData };

        if (!isEqual(newDraftState, ruleDraft)) {
          setRuleDraft(newDraftState);

          updateIsDirty(true);

          validate({
            dataToValidate: newDraftState,
            context: {
              dataFieldsLookupTable,
              ruleSettings,
              availableRegions,
            },
          });
        }
      },
      [
        ruleDraft,
        dataFieldsLookupTable,
        ruleSettings,
        setRuleDraft,
        updateIsDirty,
        validate,
        availableRegions,
      ]
    );

    const onProductFieldChange = useCallback(
      ({ product }: Pick<RuleDraft, 'product'>) => {
        const { weight, appliedDateRange } = { ...ruleDraft };

        onFieldChange({
          subRules: [ruleFormMappers.createEmptyCondition()],
          searchCondition: rulesFormInitialData.searchCondition,
          sourceCondition: rulesFormInitialData.sourceCondition,
          weight,
          appliedDateRange,
          action: MerchandisingRuleTypes.RuleAction.Promote,
          product,
          redirectRule: rulesFormInitialData.redirectRule,
          context: rulesFormInitialData.context,
        });
      },
      [ruleDraft, onFieldChange]
    );

    const onLocaleFieldChange = useCallback(
      ({ regions }) => {
        onFieldChange({
          regions,
        });
      },
      [onFieldChange]
    );

    const isReadOnlyExperiment = Boolean(variantId) && Boolean(isReadOnly);

    const shouldShowRegionForm = isReadOnlyExperiment
      ? ruleDraft.regions.length !== 0
      : isShopMultiLocale && Boolean(ruleDraft.product);

    const isCategoryPage = ruleDraft.product === SyteProductType.Collections && !entityId;

    useEffect(() => {
      dispatch(visualEditorActions.notifyIsDirty({ isDirty }));
    }, [dispatch, isDirty]);

    return (
      <>
        <RuleFormPage layout='default' className='merchandising-rules-form-page'>
          <Page.Header>
            <Page.Title
              variant={TypographyVariant.SmallBold}
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              {mode === Mode.edit ? 'Edit rule' : 'Create new rule'}
            </Page.Title>
            <RuleGuideLink />
          </Page.Header>
          <Page.Content>
            <form>
              <RuleFormGeneralSection
                errors={errors}
                name={ruleDraft.name}
                kpi={ruleDraft.kpi}
                onFieldChange={onFieldChange}
                isReadOnly={isReadOnly}
                rulesKpiOptions={overview?.kpis}
              />
              {!predefinedProduct && (
                <RuleFormSyteProductSection
                  product={ruleDraft.product}
                  onFieldChange={onProductFieldChange}
                  isReadOnly={mode === Mode.edit || isReadOnly}
                  errors={errors}
                  entityId={ruleDraft?.entityId}
                  featureToggles={featureToggles}
                />
              )}
              {shouldShowRegionForm && (
                <RuleFormRegionSection
                  availableRegions={availableRegions || []}
                  regions={ruleDraft.regions}
                  isReadOnly={isReadOnly}
                  onFieldChange={onLocaleFieldChange}
                  errors={errors}
                />
              )}

              {!!ruleSettings && (
                <RuleFormConditionsSection
                  searchCondition={ruleDraft.searchCondition}
                  sourceCondition={ruleDraft.sourceCondition}
                  filterByCondition={ruleDraft.filterByCondition}
                  subRules={ruleDraft.subRules}
                  redirectRule={ruleDraft.redirectRule}
                  action={ruleDraft.action}
                  weight={ruleDraft.weight}
                  subRulesConditionTypes={ruleSettings?.subRulesConditionTypes || []}
                  conditionTypesOptions={ruleSettings?.conditionTypesOptions || {}}
                  conditionTypes={ruleSettings.conditionTypes}
                  dispatch={dispatch}
                  dataFieldsLookupTable={dataFieldsLookupTable}
                  getDataFieldByName={getDataFieldByName}
                  shopId={shopId}
                  onFieldChange={onFieldChange}
                  errors={errors}
                  isReadOnly={isReadOnly}
                  actions={ruleSettings.actions}
                  isAiTagsEnabled={isAiTagsEnabled}
                  entityId={ruleDraft?.entityId}
                  isCategoryPage={isCategoryPage}
                  categoryFilterRule={categoryFilterRule}
                  context={ruleDraft?.context}
                  rulesContextOptions={overview?.context}
                />
              )}
              <RuleFormScheduleDateSection
                appliedDateRange={ruleDraft.appliedDateRange}
                onChange={onFieldChange}
                isReadOnly={isReadOnly}
              />
            </form>
          </Page.Content>
        </RuleFormPage>
        <FooterActionForm
          mode={mode}
          disabled={!formStatus.canSubmit}
          onCancel={onClose}
          onSave={() => onSave({ shopId, ruleDraft: ruleDraft as Required<RuleDraft> })}
          isSubmitting={isSubmitting}
        />
      </>
    );
  }
);
