import { useCallback, useMemo } from 'react';
import { Variant } from 'src/services';
import { UpdateDataArgs } from 'src/components-dummy';
import { PRODUCTION_FIELD_NAME } from '../../ExperimentVariantNameCell';
import {
  UseVariantsTableArguments,
  UseVariantsTableReturnType,
  VariantTableRecord,
  GetCellErrorArguments,
} from './types';

const alphabet = [...'abcdefghijklmnopqrstuvwxyz'];

function getProductionAllocation(variants: Variant[]): number {
  return variants.reduce((result, current) => {
    const actualProdAllocation = result - current.trafficAllocation;
    return Math.max(0, actualProdAllocation);
  }, 100);
}

function convertVariantsToTableRecord(variants: Variant[]): VariantTableRecord[] {
  const productionTableRecord = {
    id: PRODUCTION_FIELD_NAME,
    name: '-',
    type: PRODUCTION_FIELD_NAME,
    trafficAllocation: getProductionAllocation(variants),
    source: PRODUCTION_FIELD_NAME,
    title: 'Unallocated',
  };
  const variantsConverted = variants.map((variant, index) => {
    return {
      id: variant.id,
      title: `Variant ${alphabet[index].toUpperCase()}`,
      name: variant.name,
      type: variant.type,
      trafficAllocation: variant.trafficAllocation,
      source: undefined,
    };
  });
  return [productionTableRecord, ...variantsConverted];
}

export const useVariantsTable = ({
  experiment,
  variantsDraft,
  errors,
  updateVariantsState,
  isExperimentEditable,
  isExperimentEnded,
  dispatch,
}: UseVariantsTableArguments): UseVariantsTableReturnType => {
  const convertedVariantTableRecords = useMemo(
    () => convertVariantsToTableRecord(variantsDraft),
    [variantsDraft, experiment]
  );

  const updateVariantAllocationData = (
    changedVariantRecord: VariantTableRecord,
    columnId: string,
    value: any
  ) => {
    if (changedVariantRecord.id === PRODUCTION_FIELD_NAME && columnId === 'trafficAllocation') {
      const trafficAllocationValue = (100 - value) / 2;
      const variantDraftChanges = variantsDraft.map(variant => {
        return {
          ...variant,
          [columnId]: trafficAllocationValue,
        };
      });

      updateVariantsState({ variants: variantDraftChanges });
    }
  };

  const updateData = ({ rowIndex, columnId, value }: UpdateDataArgs<unknown>) => {
    const changedVariantRecord = convertedVariantTableRecords[rowIndex];
    const variantDraftChanges = variantsDraft.map(variant => {
      if (variant.id === changedVariantRecord?.id) {
        return {
          ...variant,
          [columnId]: value,
        };
      }
      return variant;
    });

    updateVariantsState({ variants: variantDraftChanges });
    updateVariantAllocationData(changedVariantRecord, columnId, value);
  };

  const getCellError = useCallback(
    ({ rowIndex, propertyName }: GetCellErrorArguments) => {
      const cellKey = `variants.${rowIndex - 1}.${propertyName}`;
      const hasError = !!errors[cellKey];
      return hasError;
    },
    [errors]
  );

  const getCellProps = useCallback(
    (cell: any) => {
      const { index: rowIndex } = cell.row;
      const variantRecord = cell.row.original;
      const propertyName = cell.column.id;

      const error = getCellError({ rowIndex, propertyName });
      const isProduction = variantRecord.id === PRODUCTION_FIELD_NAME;
      const disabledAllocation = !isProduction || !isExperimentEditable;
      const disabled = isProduction || !isExperimentEditable;
      const decimals = isProduction ? 0 : 2;

      switch (propertyName) {
        case 'trafficAllocation':
          return {
            error,
            disabled: disabledAllocation,
            staticSymbol: '%',
            className: 'variant-cell-allocation',
            decimals,
            maxNumericValue: 100,
          };

        case 'name':
          return { error, disabled, className: 'variant-cell-custom-name' };

        case 'source':
          return { isExperimentEnded, isExperimentEditable, dispatch };

        case 'controlGroupDiff':
          return { dispatch };

        default:
          return { error, disabled };
      }
    },
    [getCellError, isExperimentEditable, isExperimentEnded]
  );

  return {
    tableState: convertedVariantTableRecords,
    updateData,
    getCellProps,
  };
};
