import React from 'react';
import {
  MenuItem,
  SelectOnChangeEvent,
  SelectType,
  Typography,
  TypographyType,
  TypographyVariant,
} from '../../../../components-dummy';
import { BannerSetting, Dropdown } from '../../DiscoveryBannerSettings.styles';

const gridConfigDropDownOptions = [
  { value: 1, text: '1' },
  { value: 2, text: '2' },
  { value: 3, text: '3' },
  { value: 4, text: '4' },
];

interface GridSettingProps {
  title: string;
  value: number | undefined;
  onChange: SelectOnChangeEvent;
  disabled?: boolean;
}

export const GridSetting: React.FC<GridSettingProps> = ({ title, value, onChange, disabled }) => (
  <BannerSetting>
    <Typography variant={TypographyVariant.MediumRegular} type={TypographyType.Body}>
      {title}
    </Typography>
    <Dropdown type={SelectType.Menu} value={value} onChange={onChange} disabled={disabled}>
      {gridConfigDropDownOptions.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.text}
        </MenuItem>
      ))}
    </Dropdown>
  </BannerSetting>
);
