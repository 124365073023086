import { useCallback, useState } from 'react';
import { Dispatch as DispatchRedux } from 'src/components-dummy/types';
import { insightsActions } from './Insights.actions';
import {
  DataUsageModeEnum,
  HttpStatusCode,
  IInsightsShopMetadataAPIModel,
  KPITypesEnum,
} from 'src/services';
import { KPIS_ORDERED_LIST } from './insights.config';

export const ALL_PRODUCTS_OPTION = {
  value: undefined,
  text: 'All Syte Products',
};

/**
 * Main
 */
interface UseInsights {
  shopId: number;
  dispatch: DispatchRedux;
}

interface UseInsightsReturn {
  shopMetdata: IInsightsShopMetadataAPIModel;
  productsNames: string[];
  kpisTypesList: KPITypesEnum[];
  isWaitingForProducts: boolean;
  manageInitialData: () => Promise<void>;
}

export const useInsights = ({ shopId, dispatch }: UseInsights): UseInsightsReturn => {
  const [shopMetdata, setShopMetdata] = useState<IInsightsShopMetadataAPIModel>(undefined);
  const [kpisTypesList, setKpiTypesList] = useState<KPITypesEnum[]>(undefined);
  const [productsNames, setProductsNames] = useState<string[]>();
  const [isWaitingForProducts, setIsWaitingForProducts] = useState<boolean>(false); // For live mode

  const getShopMetada = useCallback(async (): Promise<IInsightsShopMetadataAPIModel | null> => {
    try {
      const insightsShopMetdata = await (
        dispatch(insightsActions.getShopInsightsMetdata({ shopId })) as any
      ).unwrap();

      return insightsShopMetdata?.insightsShopMetadata;
    } catch (error) {
      if (error.code === HttpStatusCode.NotFound) {
        console.info(`There's no shop insights metada`);
      }

      console.error(error);

      return null;
    }
  }, []);

  const fetchLiveProductsNames = useCallback(async (): Promise<string[]> => {
    setIsWaitingForProducts(true);

    try {
      const productsListResult = await (
        dispatch(insightsActions.getLiveProductsNames({ shopId })) as any
      ).unwrap();

      return productsListResult?.insightsProductsNamesResult;
    } catch (error) {
      console.error(error);
      return [];
    } finally {
      setIsWaitingForProducts(false);
    }
  }, [shopId]);

  /**
   * Manages data
   * - Fetches shopMetdata -
   *   - not exists => we wouldn't want to show dashboard
   *   - exists => we'll set values according to lates batch update (product names / kpis / timeIntervals, etc.)
   */
  const manageInitialData = useCallback(async () => {
    try {
      const shopMetdataResult = await getShopMetada();
      setShopMetdata(
        shopMetdataResult?.isEnabled &&
          !!shopMetdataResult.currentBatchId &&
          !!shopMetdataResult.hasSessionsInTheLastWeek
          ? shopMetdataResult
          : undefined
      );

      if (
        [DataUsageModeEnum.Cached, DataUsageModeEnum.Dynamic, DataUsageModeEnum.Hybrid].includes(
          shopMetdataResult.dataUsageMode
        )
      ) {
        const sortedProductNames = [...shopMetdataResult.productsNames].sort();

        setProductsNames(sortedProductNames);
        setKpiTypesList(shopMetdataResult.kpiTypes);
      }

      if (shopMetdataResult.dataUsageMode === DataUsageModeEnum.Live) {
        const productNamesResult = await fetchLiveProductsNames();
        const sortedProductNames = [...productNamesResult].sort();

        setProductsNames(sortedProductNames);
        setKpiTypesList(KPIS_ORDERED_LIST);
      }
    } catch (error) {
      console.error('manage initial data: ', error);
    }
  }, [shopId, getShopMetada, fetchLiveProductsNames]);

  return {
    manageInitialData,
    shopMetdata,
    productsNames,
    kpisTypesList,
    isWaitingForProducts,
  };
};
