import { createAsyncThunk } from '@reduxjs/toolkit';
import { InsightsService } from 'src/services/src/service/insights';

const insightsService = new InsightsService();

export const insightsActions = {
  getLiveProductsNames: createAsyncThunk(
    'catalogExplorer/GetProductsNames',
    async (
      {
        shopId,
        cancellationSignal,
      }: {
        shopId: number;
        cancellationSignal?: AbortSignal;
      },
      { rejectWithValue }
    ) => {
      try {
        const insightsProductsNamesResult = await insightsService.getInsightsProductsNames({
          shopId,
          cancellationSignal,
        });

        return { insightsProductsNamesResult };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  getShopInsightsMetdata: createAsyncThunk(
    'catalogExplorer/GetShopInsightsMetadata',
    async (
      {
        shopId,
        cancellationSignal,
      }: {
        shopId: number;
        cancellationSignal?: AbortSignal;
      },
      { rejectWithValue }
    ) => {
      try {
        const insightsShopMetadata = await insightsService.getInsightsShopMetdata({
          shopId,
          cancellationSignal,
        });

        return { insightsShopMetadata };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
