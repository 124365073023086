import React from 'react';
import { AvailableIcons } from 'src/components-dummy';
import {
  IconStyled,
  LanguageCardContainerStyled,
  LanguageCardContentStyled,
  LanguageCardFooterStyled,
} from './LanguageCard.styles';

export interface LanguageCardProps {
  displayName: string;
  iconName?: string;
  color: string;
  onClick: () => void;
  coloredCard?: boolean;
}

export const LanguageCard = ({
  displayName,
  iconName,
  color,
  onClick,
  coloredCard,
}: LanguageCardProps): JSX.Element => {
  return (
    <LanguageCardContainerStyled onClick={onClick} coloredCard={coloredCard}>
      <LanguageCardContentStyled color={color} coloredCard={coloredCard}>
        {iconName && <IconStyled name={iconName as AvailableIcons} />}
      </LanguageCardContentStyled>
      <LanguageCardFooterStyled coloredCard={coloredCard}>
        {displayName || 'Not supported locale'}
      </LanguageCardFooterStyled>
    </LanguageCardContainerStyled>
  );
};
