import React, { useRef, useState } from 'react';
import { ParseJoiValidateResponse } from 'src/utils';
import {
  Typography,
  TypographyType,
  TypographyVariant,
  Icon,
  SelectType,
  SelectOnChangeEvent,
  MenuItem,
} from 'src/components-dummy';
import { BannerSettingsInfoModal } from './BannerSettingsInfoModal';
import { GridLayoutSettings } from './GridLayoutSettings';
import { useOnClickOutside } from 'src/hooks/useOnClickOutside';
import { handleUpdateColor } from '../../colorToHex';
import { AvailableIcons } from 'src/components-dummy/Icon/AvailableIcons';
import { DiscoveryBannerGeneralSettings } from 'src/services/src/service/types/syte-products/discovery-banner';
import {
  ImageLayout,
  BannerLayout,
} from 'src/app-state-types/reducer-state-types/discovery-banner';
import { DiscoveryBannerSettingsRecord } from '../../types';
import {
  BannerSetting,
  ChromePickerStyled,
  ColorPickerButton,
  Container,
  Content,
  ContentContainer,
  Dropdown,
  Header,
  InfoButton,
  InfoButtonTitle,
  TextBoxStyled,
} from '../../DiscoveryBannerSettings.styles';

const bannerLayoutDropDownOptions = [
  { value: BannerLayout.LEFT_TO_RIGHT, text: 'Left To Right' },
  { value: BannerLayout.RIGHT_TO_LEFT, text: 'Right To Left' },
  { value: BannerLayout.TOP_TO_BOTTOM, text: 'Top To Bottom' },
  { value: BannerLayout.BOTTOM_TO_TOP, text: 'Bottom To Top' },
];
const imageLayoutDropDownOptions = [
  { value: ImageLayout.MIXED_SIZES, text: 'Mixed Sizes' },
  { value: ImageLayout.GRID, text: 'Grid' },
  { value: ImageLayout.OVERLAP, text: 'Overlapping' },
  { value: ImageLayout.NONE, text: 'None' },
];

export interface BannerSettingsProps {
  discoveryBannerSettings: DiscoveryBannerGeneralSettings['generalSettings'];
  onChange: (args: Partial<DiscoveryBannerGeneralSettings['generalSettings']>) => void;
  disabled?: boolean;
  errors: ParseJoiValidateResponse<DiscoveryBannerSettingsRecord>;
}

export const BannerSettings = ({
  discoveryBannerSettings,
  onChange,
  disabled = false,
  errors,
}: BannerSettingsProps): JSX.Element | null => {
  const [showBannerBackgroundColorPicker, setShowBannerBackgroundColorPicker] = useState(false);
  const [showGeneralSettings, setGeneralSettingsModalIsOpened] = useState(false);

  const handleConfigChange =
    (section: string, field: string): SelectOnChangeEvent =>
    event =>
      onChange({
        [section]: {
          ...discoveryBannerSettings[section],
          [field]: event.target.value,
        },
      });

  const onToggle = () => {
    onChange({ isSameGrid: !discoveryBannerSettings.isSameGrid });
  };

  const onGeneralSettingsModalClose = () => {
    setGeneralSettingsModalIsOpened(false);
  };
  const bannerBackgroundColorRef = useRef(null);
  useOnClickOutside(bannerBackgroundColorRef, () => {
    setShowBannerBackgroundColorPicker(false);
  });

  return (
    <Container>
      <Header>
        <Typography variant={TypographyVariant.MediumBold} type={TypographyType.Body}>
          Banner settings
        </Typography>

        <InfoButton onClick={() => setGeneralSettingsModalIsOpened(true)}>
          <InfoButtonTitle variant={TypographyVariant.SmallRegular}>
            What is it general settings
          </InfoButtonTitle>
          <Icon name={AvailableIcons.TooltipQuestionmarkBlue} />
        </InfoButton>

        <BannerSettingsInfoModal
          isOpened={showGeneralSettings}
          title='General settings'
          description='The Dialog component is used to show content on top of an overlay that requires user interaction.'
          closeBtnTxt='Close'
          onClickOutside={onGeneralSettingsModalClose}
        ></BannerSettingsInfoModal>
      </Header>
      <ContentContainer>
        <Content>
          <BannerSetting>
            <Typography variant={TypographyVariant.MediumRegular} type={TypographyType.Body}>
              Banner Alignment
            </Typography>
            <Dropdown
              type={SelectType.Menu}
              onChange={handleConfigChange('general', 'bannerLayout')}
              disabled={disabled}
              value={discoveryBannerSettings.general.bannerLayout}
            >
              {bannerLayoutDropDownOptions.map((option: { value: string; text: string }) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </Dropdown>
          </BannerSetting>
          <BannerSetting>
            <Typography variant={TypographyVariant.MediumRegular} type={TypographyType.Body}>
              Images Display Layout
            </Typography>
            <Dropdown
              type={SelectType.Menu}
              value={discoveryBannerSettings.general.imageLayout}
              onChange={handleConfigChange('general', 'imageLayout')}
              disabled={disabled}
            >
              {imageLayoutDropDownOptions.map((option: { value: string; text: string }) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </Dropdown>
          </BannerSetting>
          <BannerSetting ref={bannerBackgroundColorRef}>
            <TextBoxStyled
              label='Banner Background Color'
              placeholder={discoveryBannerSettings.general.bannerBackgroundColor}
              error={!!errors.name}
              value={discoveryBannerSettings.general.bannerBackgroundColor}
              fullWidth
              onChange={backgroundColor => {
                onChange({
                  general: {
                    ...discoveryBannerSettings.general,
                    bannerBackgroundColor: backgroundColor,
                  },
                });
              }}
              disabled={disabled}
            >
              <ColorPickerButton
                type='button'
                aria-label='background-color-mock'
                onClick={() => setShowBannerBackgroundColorPicker(!showBannerBackgroundColorPicker)}
                style={{
                  backgroundColor: discoveryBannerSettings.general.bannerBackgroundColor,
                }}
                disabled={disabled}
              />
              {showBannerBackgroundColorPicker && (
                <ChromePickerStyled
                  color={discoveryBannerSettings.general.bannerBackgroundColor}
                  onChange={updatedColor => {
                    handleUpdateColor(updatedColor, newColor => {
                      onChange({
                        general: {
                          ...discoveryBannerSettings.general,
                          bannerBackgroundColor: newColor,
                        },
                      });
                    });
                  }}
                />
              )}
            </TextBoxStyled>
          </BannerSetting>
        </Content>
        {discoveryBannerSettings.general.imageLayout === ImageLayout.GRID && (
          <GridLayoutSettings
            discoveryBannerSettings={discoveryBannerSettings}
            handleConfigChange={handleConfigChange}
            onToggle={onToggle}
            disabled={disabled}
          />
        )}
      </ContentContainer>
    </Container>
  );
};
