import React, { useRef, useState } from 'react';
import { Switch, TypographyType, TypographyVariant } from '../../../components-dummy';
import { handleUpdateColor } from '../colorToHex';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import { ParseJoiValidateResponse } from '../../../utils';
import { DiscoveryBannerSettingsRecord } from '../types';
import {
  BannerSetting,
  CameraIconContainer,
  CameraIconTitle,
  ChromePickerStyled,
  ColorPickerButton,
  TextBoxStyled,
} from '../DiscoveryBannerSettings.styles';

interface IconSettingsProps {
  title: string;
  showIcon: boolean;
  iconColor: string;
  onShowIconToggle: () => void;
  onIconColorChange: (color: string) => void;
  errors: ParseJoiValidateResponse<DiscoveryBannerSettingsRecord>;
  disabled?: boolean;
}

export const IconSettings = ({
  title,
  showIcon,
  iconColor,
  onShowIconToggle,
  onIconColorChange,
  errors,
  disabled,
}: IconSettingsProps) => {
  const iconColorRef = useRef(null);

  const [showIconColorPicker, setShowIconColorPicker] = useState(false);

  useOnClickOutside(iconColorRef, () => {
    setShowIconColorPicker(false);
  });

  return (
    <>
      <CameraIconContainer>
        <Switch.TitleTemplate>
          <CameraIconTitle variant={TypographyVariant.MediumRegular} type={TypographyType.Body}>
            {title}
          </CameraIconTitle>
        </Switch.TitleTemplate>
        <Switch checked={showIcon} onChange={onShowIconToggle} disabled={disabled}></Switch>
      </CameraIconContainer>
      <BannerSetting ref={iconColorRef}>
        <TextBoxStyled
          label='Icon Color'
          placeholder={iconColor}
          error={!!errors.name}
          value={iconColor}
          fullWidth
          onChange={color => {
            onIconColorChange(color);
          }}
          disabled={showIcon ? disabled : !showIcon}
        >
          <ColorPickerButton
            type='button'
            aria-label='icon-color-mock'
            onClick={() => setShowIconColorPicker(!showIconColorPicker)}
            style={{ backgroundColor: iconColor }}
            disabled={showIcon ? disabled : !showIcon}
          />
          {showIconColorPicker && (
            <ChromePickerStyled
              color={iconColor}
              onChange={updatedColor => {
                handleUpdateColor(updatedColor, color => {
                  onIconColorChange(color);
                });
              }}
            />
          )}
        </TextBoxStyled>
      </BannerSetting>
    </>
  );
};
