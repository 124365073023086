import { DiscoveryBannerSettingsTypes } from 'src/services';
import { DomainForRendering } from '../../types';
import { discoveryBannerDiffSchema } from './discovery-banner-diff-schema';

export const discoveryBannerSubDomainGroup: DomainForRendering<DiscoveryBannerSettingsTypes.DiscoveryBannerSettings> =
  {
    getCardTitle: ({ entityPath }) => {
      const titleResult = [];
      switch (entityPath) {
        case 'general': {
          titleResult.push('Banner Settings');
          break;
        }
        case 'banner': {
          titleResult.push('Heading');
          break;
        }
        case 'button': {
          titleResult.push('CTA Button');
          break;
        }
        case 'icon': {
          titleResult.push('Camera Icon');
          break;
        }
        case 'mobileGrid': {
          titleResult.push('Mobile Grid');
          break;
        }
        case 'desktopGrid': {
          titleResult.push('Desktop Grid');
          break;
        }
        case 'isSameGrid': {
          titleResult.push('Grid settings');
          break;
        }
        default:
          break;
      }
      return titleResult.join(' > ');
    },
    fieldsSchema: discoveryBannerDiffSchema,
  };
