import Joi from 'joi';
import {
  ImageLayout,
  BannerLayout,
  BannerStrategy,
} from '../../app-state-types/reducer-state-types/discovery-banner';

export const generalValidationSchema = {
  bannerLayout: Joi.string().valid(...Object.values(BannerLayout)),
  imageLayout: Joi.string().valid(...Object.values(ImageLayout)),
  bannerBackgroundColor: Joi.string().required(),
};
export const bannerValidationSchema = {
  bannerColor: Joi.string().required(),
  bannerFontFamily: Joi.string().required(),
  bannerFontSize: Joi.number().required(),
};
export const iconValidationSchema = {
  showIcon: Joi.boolean(),
  iconColor: Joi.string().required(),
};
export const buttonValidationSchema = {
  buttonBackgroundColor: Joi.string().required(),
  buttonColor: Joi.string().required(),
  buttonFontFamily: Joi.string().required(),
  buttonFontSize: Joi.number().required(),
  icon: Joi.object().keys(iconValidationSchema),
};
export const gridConfigValidationSchema = {
  columns: Joi.number(),
  rows: Joi.number(),
};

export const discoveryBannerSettingsValidationSchema = {
  general: Joi.object().keys(generalValidationSchema),
  banner: Joi.object().keys(bannerValidationSchema),
  button: Joi.object().keys(buttonValidationSchema),
  icon: Joi.object().keys(iconValidationSchema),
  mobileGrid: Joi.object().keys(gridConfigValidationSchema),
  desktopGrid: Joi.object().keys(gridConfigValidationSchema),
  isSameGrid: Joi.boolean(),
  strategy: Joi.string().valid(...Object.values(BannerStrategy)),
};
