import { ShopFeature } from '../app-types';

type FeaturesRoutesMap = Partial<Record<ShopFeature, string>>;
export const featuresRoutesMap: FeaturesRoutesMap = {
  [ShopFeature.DiscoveryStories]: 'stories',
  [ShopFeature.Collections]: 'collections',
  [ShopFeature.DiscoveryBanner]: 'visual-search/discovery-banner',
  [ShopFeature.FrequentlyBoughtTogether]: 'recommendation-engines/bought-together',
  [ShopFeature.Personalization]: 'recommendation-engines/personalization',
  [ShopFeature.RecentlyViewed]: 'recommendation-engines/recently-viewed',
  [ShopFeature.ShopTheLook]: 'recommendation-engines/shop-the-look',
  [ShopFeature.SimilarItems]: 'recommendation-engines/similar-items',
  [ShopFeature.SocialDiscovery]: 'social-discovery',
  [ShopFeature.AugmentedSearch]: 'text-search/relevancy-tuning', // TODO: rename to synonyms
  [ShopFeature.Galleries]: 'galleries',
};
