/* eslint-disable react/jsx-curly-brace-presence */
import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { isEqual } from 'lodash';
import { AxiosProgressEvent } from 'axios';
import { downloadFile } from 'src/utils/download-file';
import {
  AvailableIcons,
  Button,
  ConfirmationDialog,
  Icon,
  NotificationType,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { Dispatch } from '../../../types';
import { SynonymLanguage } from '../../../../services/src/service/augmented-search/synonyms/types';
import { synonymsActions } from '../SynonymsActions';
import {
  ButtonContentStyled,
  DownloadExampleLinkButtonStyled,
  FileUploadButtonStyled,
  UploadConfirmationDialogStyled,
  UploadProgressStyled,
} from './SynonymsFileUploadModal.styles';
import { fileAcceptTypes } from '../../../../components-dummy/FileUploader';
import { getReportStatusTypeAndMessage } from './getReportStatusTypeAndMessage';

const MAX_FILE_SIZE_MB = 100;

interface UploadFormState {
  synonymsFiles: File[];
}

export interface SynonymsFileUploadModalProps {
  shopId: number;
  language: SynonymLanguage;
  dispatch: Dispatch;
  exampleFilePath: string;
  onCancel: () => void;
}

const IMPORT_REPORT_STATUS_HEADER = 'x-import-synonyms-report-status';

export const SynonymsFileUploadModal = ({
  onCancel,
  shopId,
  dispatch,
  language,
  exampleFilePath,
}: SynonymsFileUploadModalProps): JSX.Element => {
  const initialFormState = useMemo(() => ({ synonymsFiles: [] }) as UploadFormState, []);

  const [formState, setFormState] = useState(initialFormState);

  const uploadCancellationRef = useRef(null as AbortController | null);

  const [uploadProgress, setUploadProgress] = useState(undefined as number | undefined);

  const isInProgress = uploadProgress !== undefined;

  const disableInputs = isInProgress;

  const synonymsFiles = formState.synonymsFiles;

  const isDirty = useMemo(() => {
    return !isEqual(formState, initialFormState) || !!synonymsFiles?.length;
  }, [formState]);

  const isValid = isDirty && !!synonymsFiles;

  const onFormStateChange = (newFiles: UploadFormState) => {
    setFormState(newFiles);
  };

  const cancelUploadProgress = () => {
    if (!uploadCancellationRef.current) {
      return;
    }

    try {
      uploadCancellationRef.current.abort();
    } catch (error) {
      console.error(error);
    } finally {
      uploadCancellationRef.current = null;
    }
  };

  const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
    if (
      !progressEvent.upload ||
      progressEvent.progress === undefined ||
      !uploadCancellationRef.current || // component might be  unmounting
      uploadCancellationRef.current.signal.aborted
    ) {
      return;
    }

    const PERCENT_MULTIPLIER = 100;

    const progressRatio = Math.ceil(progressEvent.progress * PERCENT_MULTIPLIER);

    setUploadProgress(progressRatio);
  };

  const onUpload = useCallback(async () => {
    if (isValid) {
      setUploadProgress(0);

      // in case somehow previous cancellation didn't clear properly the ref.
      cancelUploadProgress();

      uploadCancellationRef.current = new AbortController();

      const result = (await dispatch(
        synonymsActions.importSynonyms({
          data: {
            language,
            shopId,
            synonymsFile: synonymsFiles?.[0],
          },
          cancellationSignal: uploadCancellationRef.current.signal,
          onUploadProgress,
        })
      )) as any;

      if (result?.error) {
        setUploadProgress(undefined);
        dispatch(
          synonymsActions.issueToastNotification({
            type: NotificationType.Error,
            customMessage: result.payload?.error?.message ?? 'Upload failed',
          })
        );
        return;
      }

      const reportStatus = result?.payload?.headers[IMPORT_REPORT_STATUS_HEADER];
      const { type, customMessage } = getReportStatusTypeAndMessage(reportStatus);

      dispatch(synonymsActions.notifyIsDirty({ isDirty: false }));
      if (type && customMessage) {
        dispatch(
          synonymsActions.issueToastNotification({
            type,
            customMessage,
          })
        );
      }
      onCancel();
    }
  }, [isValid, formState, shopId]);

  const onExampleClick = () => {
    downloadFile({
      filePath: exampleFilePath,
      fileDisplayName: 'synonyms_file_example.csv',
    });
  };

  useEffect(() => {
    dispatch(synonymsActions.notifyIsDirty({ isDirty }));
  }, [isDirty]);

  useEffect(() => {
    return () => {
      cancelUploadProgress();
    };
  }, []);

  return (
    <UploadConfirmationDialogStyled onCancel={onCancel}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>Upload Synonyms File</ConfirmationDialog.Title>
      </ConfirmationDialog.Header>
      <ConfirmationDialog.Content>
        <ConfirmationDialog.ContentMainText>
          Use a CSV file to create your Synonyms file
        </ConfirmationDialog.ContentMainText>
        <FileUploadButtonStyled
          selectedFiles={formState.synonymsFiles}
          onChange={files => onFormStateChange({ synonymsFiles: files })}
          accept={fileAcceptTypes.CSV}
          fileCardIconName={AvailableIcons.CsvFile}
          maxSizeMb={MAX_FILE_SIZE_MB}
          disabled={disableInputs}
        >
          <ButtonContentStyled>
            <Icon name={AvailableIcons.Page} />{' '}
            <Typography variant={TypographyVariant.MediumMedium} type={TypographyType.Button}>
              Upload file
            </Typography>
          </ButtonContentStyled>
        </FileUploadButtonStyled>
        <DownloadExampleLinkButtonStyled onClick={onExampleClick}>
          <Icon name={AvailableIcons.Download} />
          <Typography variant={TypographyVariant.MediumMedium} type={TypographyType.Body}>
            Download CSV example
          </Typography>
        </DownloadExampleLinkButtonStyled>
      </ConfirmationDialog.Content>
      <ConfirmationDialog.Footer>
        <Button variant='primary' onClick={onUpload} disabled={!isValid} loading={isInProgress}>
          Upload
        </Button>
        <Button variant='tertiary' onClick={onCancel}>
          Cancel
        </Button>
        {uploadProgress !== undefined && (
          <UploadProgressStyled
            type={TypographyType.Body}
            variant={TypographyVariant.MediumRegular}
          >
            {`Uploading ${uploadProgress}%...`}
          </UploadProgressStyled>
        )}
      </ConfirmationDialog.Footer>
    </UploadConfirmationDialogStyled>
  );
};
