import React, { memo, useEffect, useMemo } from 'react';
import { DiffActionType } from 'src/app-types/enums/versioning';
import { Checkbox, Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import { useMultiSelect } from 'src/components-dummy/MultiSelectMenu/use-multi-select';
import { ChangesPreviewProps, DomainGroup, DomainGroupWithDiffCardProps } from './types';
import { DiffsCardProps } from '../DiffsCard';
import { FeatureGroup } from '../FeatureGroup';
import './ChangesPreview.scss';
import {
  convertDomainGroupChangesToDiffCardProps,
  convertEntityChangesToDomainGroups,
  combineRelatedDomainKeysToString,
} from './domain-helpers';

/**
 * Calculate total changes - changed & created & deleted
 */
const getTotalChanges = (changedEntityCards: Partial<DiffsCardProps>[]): number => {
  return changedEntityCards.reduce((prev, next: Partial<DiffsCardProps>) => {
    const total = next.actionType === DiffActionType.Changed ? next.totalChangesCount : 1;
    return prev + (total as number);
  }, 0);
};

export const ChangesPreview = memo(
  ({
    changedEntities: entitiesChanges,
    domainGroupsSelection,
    onDomainGroupSelected,
    previewType,
    readOnly,
    dataFields,
    newValueCustomName,
  }: ChangesPreviewProps): JSX.Element => {
    const domainGroups = useMemo(
      () => convertEntityChangesToDomainGroups(entitiesChanges),
      [entitiesChanges]
    );

    const featureGroupsWithDiffCardProps: DomainGroupWithDiffCardProps[] = useMemo(() => {
      return domainGroups
        .map(domainGroup => {
          const changedEntityCards = convertDomainGroupChangesToDiffCardProps(
            domainGroup,
            dataFields
          );
          const totalChanges = getTotalChanges(changedEntityCards);

          return changedEntityCards.length > 0
            ? { domainGroup, changedEntityCards, totalChanges }
            : null;
        })
        .filter(Boolean) as DomainGroupWithDiffCardProps[];
    }, [domainGroups]);

    useEffect(() => {
      onDomainGroupSelected?.({
        combinedSelectedDomainGroups: [
          ...new Set(
            featureGroupsWithDiffCardProps.map(({ domainGroup }) =>
              combineRelatedDomainKeysToString(domainGroup)
            )
          ),
        ],
        allGroupsWithChangesSelected: true,
      });
    }, [onDomainGroupSelected]);

    const { selectAllOptionState, handleToggleAllClick, handleItemClick } = useMultiSelect({
      options: featureGroupsWithDiffCardProps.map(({ domainGroup }) => ({
        text: (domainGroup as DomainGroup).title,
        value: combineRelatedDomainKeysToString(domainGroup),
      })),
      selectedOptions: [...(domainGroupsSelection?.combinedSelectedDomainGroups || [])],
      onChange: (currentSelected: string[]) =>
        onDomainGroupSelected?.({
          combinedSelectedDomainGroups: [...new Set(currentSelected)],
        }),
      enableSelectAll: true,
    });

    useEffect(() => {
      onDomainGroupSelected?.({ allGroupsWithChangesSelected: selectAllOptionState });
    }, [selectAllOptionState, onDomainGroupSelected]);

    return (
      <div className='syte-changes-preview'>
        {!readOnly && (
          <div className='syte-changes-preview-select-all'>
            <Typography variant={TypographyVariant.MediumRegular} type={TypographyType.Paragraph}>
              Please select the changes you would like to publish; the remaining changes will be
              retained in the shop&apos;s draft
            </Typography>
            <Checkbox
              className='syte-changes-preview-select-all-checkbox'
              checked={selectAllOptionState}
              label='Select all'
              onChange={handleToggleAllClick}
            />
          </div>
        )}
        {featureGroupsWithDiffCardProps.map(({ changedEntityCards, domainGroup, totalChanges }) => {
          const { title, icon, domainKey: groupKey } = domainGroup as DomainGroup;
          const selectionKey = combineRelatedDomainKeysToString(domainGroup);

          return (
            <FeatureGroup
              key={groupKey}
              title={title}
              selectionKey={selectionKey}
              iconName={icon}
              totalChanges={totalChanges || undefined}
              changedEntities={changedEntityCards}
              previewType={previewType}
              isFeatureGroupSelected={Boolean(
                domainGroupsSelection?.combinedSelectedDomainGroups.includes(selectionKey)
              )}
              onSelectFeatureGroup={handleItemClick}
              readOnly={readOnly}
              newValueCustomName={newValueCustomName}
            />
          );
        })}
      </div>
    );
  }
);
