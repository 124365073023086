import React from 'react';
import {
  ParagraphStyled,
  ProductTileStyled,
  LinkButtonStyled,
  IconStyled,
  HeaderStyled,
  TitleStyled,
} from './ProductTile.styles';
import { AvailableIcons, Tooltip, TypographyType, TypographyVariant } from 'src/components-dummy';

export interface ProductTileProps {
  title: string;
  onClick: () => void;
  iconName: AvailableIcons;
  paragraphText: string;
  cta: string;
  isDisabled: boolean;
}

export const ProductTile = ({
  title,
  onClick,
  iconName,
  paragraphText,
  cta,
  isDisabled,
}: ProductTileProps): JSX.Element => {
  const disabledTooltipText = 'Reach out to your account manager to enable this feature';
  const productTileContent = (
    <ProductTileStyled disabled={isDisabled}>
      <HeaderStyled>
        <IconStyled name={iconName} disabled={isDisabled} />
        <TitleStyled variant={TypographyVariant.LargeBold} type={TypographyType.Body}>
          {title}
        </TitleStyled>
      </HeaderStyled>
      <ParagraphStyled>{paragraphText}</ParagraphStyled>
      <LinkButtonStyled size='small' variant='outlined' onClick={onClick} disabled={isDisabled}>
        {cta}
      </LinkButtonStyled>
    </ProductTileStyled>
  );
  if (isDisabled) {
    return <Tooltip value={disabledTooltipText}>{productTileContent}</Tooltip>;
  }
  return productTileContent;
};
