import { AxiosResponse } from 'axios';
import { flatMap, isArray } from 'lodash';
import { downloadFile } from 'src/utils/download-file';

const BASE = 36;
const DEFAULT_LENGTH = 6;

export const generateRandomString = (length = DEFAULT_LENGTH): string => {
  return Math.random().toString(BASE).substr(0, length);
};

export const parseDate = (str: string): Date => new Date(str);

export const buildQueryParams = (
  queryStringParameters: Record<string, string | number | boolean | undefined | unknown[]>
): string => {
  const qs = flatMap(
    Object.entries(queryStringParameters)
      .filter(([parameterName]) => Boolean(queryStringParameters[parameterName]))
      .map(([parameterName, parameterValue]) => {
        if (isArray(parameterValue)) {
          return parameterValue.map(value => `${parameterName}[]=${value}`);
        }
        return [`${parameterName}=${parameterValue}`];
      })
  ).join('&');

  return qs ? `?${qs}` : '';
};

export const getDateNow = (): Date => new Date();
export const getDateTomorrow = (): Date => {
  const date = new Date();
  date.setDate(date.getDate() + 1);
  return date;
};

export function randomInt(min = 0, max = 10): number {
  const minimum = Math.ceil(min);
  const maximum = Math.floor(max);
  return Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;
}

export function downloadResponseFile({
  response,
  defaultName,
}: {
  response: AxiosResponse;
  defaultName: string;
}): void {
  const blob = new Blob([response.data]);

  const contentDispositionHeader = response.headers['content-disposition'];
  const fileName = contentDispositionHeader?.split('filename=')[1].replace(/"/g, '') || defaultName;

  const url = window.URL.createObjectURL(blob);

  downloadFile({ filePath: url, fileDisplayName: fileName });

  window.URL.revokeObjectURL(url);
}

export function addSeparatorsToNumber(number) {
  return new Intl.NumberFormat('en-US').format(number);
}
