import { Dispatch, SetStateAction, useEffect, useMemo, useRef } from 'react';
import { debounce } from 'lodash';
import { isAbortError } from 'src/utils/error-helpers';
import { InsightsService } from 'src/services/src/service/insights';
import { DataUsageModeEnum, IKpiDataReponse, InsightsFilters, KPITypesEnum } from 'src/services';
import { KpiDataState } from './kpiItem.types';

const insightsService = new InsightsService();
const DEBOUNCE_TIME = 350;

interface UseFetchKpisData {
  shopId: number;
  kpiType: KPITypesEnum;
  filters: InsightsFilters;
  dataUsageMode: DataUsageModeEnum;
  setKpiData: Dispatch<SetStateAction<KpiDataState>>;
}

export const useFetchKpiData = ({
  shopId,
  kpiType,
  filters,
  dataUsageMode,
  setKpiData,
}: UseFetchKpisData) => {
  const fetchValuesCancellationRef = useRef(null as AbortController | null);

  const cancelFetchValues = () => {
    if (!fetchValuesCancellationRef.current) {
      return;
    }

    try {
      fetchValuesCancellationRef.current.abort();
    } catch (error) {
      console.error(error);
    } finally {
      fetchValuesCancellationRef.current = null;
    }
  };

  const debouncedFetchData = useMemo(() => {
    return debounce(async () => {
      cancelFetchValues();
      const abortController = new AbortController();
      fetchValuesCancellationRef.current = abortController;

      try {
        const data: IKpiDataReponse = await insightsService.getInsightsKPIData({
          shopId,
          kpiType,
          filters,
          dataUsageMode,
          cancellationSignal: abortController.signal,
        });

        setKpiData({
          data,
          isLoading: false,
          isError: false,
        });
      } catch (error) {
        if (!isAbortError(error)) {
          console.error(error);

          setKpiData(prev => ({
            ...prev,
            data: null,
            isError: true,
            isLoading: false,
          }));
        }
      }
    }, DEBOUNCE_TIME);
  }, [shopId, kpiType, filters]);

  useEffect(() => {
    return () => {
      debouncedFetchData.cancel();
      cancelFetchValues();
    };
  }, [filters]);

  return {
    debouncedFetchData,
    cancelFetchValues,
  };
};
