import React, { useEffect } from 'react';
import { Page, TypographyVariant } from 'src/components-dummy';
import { merchandisingRulesActions } from '../../merchandisingRules.actions';
import {
  MerchandisingRuleForm,
  MerchandisingRuleFormProps,
  RuleApiRef,
  ruleFormSkeleton,
} from '../MerchandisingRuleForm';
import { CategoryFilterRule } from '../../../VisualEditor/types/category-filter-rule';
import { ShopFeatureToggles } from '../../../../services/src/service/types/shops';

export interface CreateMerchandisingRulePageProps extends Omit<MerchandisingRuleFormProps, 'mode'> {
  formApiRef: React.Ref<RuleApiRef>;
  categoryFilterRule?: CategoryFilterRule;
  featureToggles: ShopFeatureToggles;
}

export const CreateMerchandisingRulePage = ({
  shopId,
  variantId,
  entityId,
  formApiRef,
  isReadOnly,
  dataFieldsLookupTable,
  predefinedProduct,
  getDataFieldByName,
  onFormStatusChange,
  dispatch,
  rule,
  kpiOptions,
  availableRegions,
  isAiTagsEnabled,
  categoryFilterRule,
  featureToggles,
  rulesContextOptions,
}: CreateMerchandisingRulePageProps): JSX.Element => {
  useEffect(() => {
    if (shopId) {
      dispatch(
        merchandisingRulesActions.getShopLocales({
          shopId,
        })
      );
    }
  }, [shopId]);

  return (
    <Page layout='default' className='merchandising-rules-form-page'>
      <Page.Header>
        <Page.Title variant={TypographyVariant.SmallBold}>Create new rule</Page.Title>
      </Page.Header>
      <Page.Content>
        {shopId && dataFieldsLookupTable ? (
          <MerchandisingRuleForm
            mode='create'
            shopId={shopId}
            dispatch={dispatch}
            dataFieldsLookupTable={dataFieldsLookupTable}
            getDataFieldByName={getDataFieldByName}
            entityId={entityId}
            variantId={variantId}
            ref={formApiRef}
            onFormStatusChange={onFormStatusChange}
            isReadOnly={isReadOnly}
            rule={rule}
            kpiOptions={kpiOptions}
            predefinedProduct={predefinedProduct}
            availableRegions={availableRegions}
            isAiTagsEnabled={isAiTagsEnabled}
            categoryFilterRule={categoryFilterRule}
            featureToggles={featureToggles}
            rulesContextOptions={rulesContextOptions}
          />
        ) : (
          ruleFormSkeleton
        )}
      </Page.Content>
    </Page>
  );
};
