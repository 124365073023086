import React from 'react';
import { ShopFeature } from 'src/app-types';
import { EditorTitle } from '../EditorTitle';
import { SettingsForm } from '../Form';
import { ShopperExperience } from './ShopperExperience';
import { SideBarLink as EditRelevancyTuningSideBarLink } from '../EditRelevancyTuning/SideBarLink';
import { MerchandisingRules } from '../MerchRules';
import { RankingStrategy } from '../RankingStrategy/RankingStrategy';
import { CategoryFilterRule } from '../../types/category-filter-rule';
import { StateController } from './ShopperExperience/useStateControllerRef';
import { PreviewMerchRule } from '../../types';
import { PermittedRouteMap } from '../../../../app-routes';
import { Line } from './SideMenu.styles';

interface Props {
  shopId: number;
  setForInternalUseOnly: boolean;
  onClose: VoidFunction;
  stateController: StateController<any>;
  selectedRule: PreviewMerchRule | null;
  editRankingStrategy: VoidFunction;
  navigateToEditRelevancyTuning: VoidFunction;
  permittedRouteMap: PermittedRouteMap;
  resultsScreenRef: React.MutableRefObject<HTMLDivElement>;
  selectedExperience: ShopFeature;
  openProductDetailsModal: (sku?: string) => void;
  categoryFilterRule: CategoryFilterRule;
}

export const SideMenuPreview = ({
  shopId,
  setForInternalUseOnly,
  onClose,
  stateController,
  selectedRule,
  editRankingStrategy,
  navigateToEditRelevancyTuning,
  permittedRouteMap,
  resultsScreenRef,
  selectedExperience,
  openProductDetailsModal,
  categoryFilterRule,
}: Props): JSX.Element => {
  return (
    <>
      <EditorTitle
        onClose={onClose}
        setForInternalUseOnly={setForInternalUseOnly}
        title='Preview'
        backButtonText='Back'
      />
      <Line />
      <SettingsForm>
        <ShopperExperience
          shopId={shopId}
          stateController={stateController}
          permittedRouteMap={permittedRouteMap}
          resultsScreenRef={resultsScreenRef}
          openProductDetailsModal={openProductDetailsModal}
          categoryFilterRule={categoryFilterRule}
        />
        <MerchandisingRules
          shopId={shopId}
          selectedRule={selectedRule}
          permittedRouteMap={permittedRouteMap}
        />
        <RankingStrategy editRankingStrategy={editRankingStrategy} />
        {selectedExperience === ShopFeature.AugmentedSearch && (
          <EditRelevancyTuningSideBarLink
            navigateToEditRelevancyTuning={navigateToEditRelevancyTuning}
          />
        )}
      </SettingsForm>
    </>
  );
};
