import { FieldType, MerchandisingRuleTypes } from 'src/services';
import { MAX_MODERATE } from '../../merchandisingRules.config';

type RuleDraftOptionalFields = Partial<
  Pick<MerchandisingRuleTypes.MerchandiseRule, 'id' | 'product' | 'redirectRule' | 'context'>
>;

export interface RuleDraftCondition {
  field?: string;
  fieldType?: FieldType;
  subType?: MerchandisingRuleTypes.MerchandisingRuleSubType;
  values: MerchandisingRuleTypes.SubRule['values'];
  tempId?: number | string;
  position?: number;
}

interface RuleDraftMandatoryFields
  extends Omit<
    MerchandisingRuleTypes.MerchandiseRule,
    'id' | 'product' | 'subRules' | 'sourceCondition'
  > {
  sourceCondition: RuleDraftCondition[];
  subRules: RuleDraftCondition[];
}

export type RuleDraft = RuleDraftOptionalFields & RuleDraftMandatoryFields;

export const rulesFormInitialData: RuleDraft = {
  id: undefined,
  name: '',
  kpi: undefined,
  action: MerchandisingRuleTypes.RuleAction.Promote,
  weight: MAX_MODERATE,
  active: true,
  redirectRule: null,
  regions: [],
  product: undefined,
  appliedDateRange: null,
  searchCondition: {
    enabled: false,
    terms: [],
  },
  sourceCondition: [],
  subRules: [],
  entityId: undefined,
  context: [],
};
