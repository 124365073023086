import { AxiosResponse } from 'axios';
import { RelevancyTuningItem, SynonymsData, Synonym, SynonymAPIResponse } from '../types';
import { parseISO } from 'date-fns';

export class AugmentedSearchMapper {
  static mapRelevancyTuning(
    response: AxiosResponse<{ tunings: RelevancyTuningItem[] }>
  ): RelevancyTuningItem[] {
    return response.data?.tunings || [];
  }

  static mapSynonyms(response: AxiosResponse<SynonymsData>): SynonymsData {
    return response?.data || undefined;
  }

  static mapSynonym(synonym: SynonymAPIResponse): Synonym {
    const { createdAt, updatedAt, ...restSynonym } = synonym;
    return {
      ...restSynonym,
      createdAt: parseISO(createdAt),
      updatedAt: parseISO(updatedAt),
    };
  }
}
