import { NotificationType } from '../../../../components-dummy';

export enum ImportReportStatus {
  failed = 'failed',
  successful = 'successful',
  partiallySuccessful = 'partiallySuccessful',
}

export const getReportStatusTypeAndMessage = (
  reportStatus: ImportReportStatus
): { type: NotificationType; customMessage: string } => {
  switch (reportStatus) {
    case ImportReportStatus.failed:
      return {
        type: NotificationType.Error,
        customMessage: 'Upload failed. A status report was downloaded.',
      };
    case ImportReportStatus.partiallySuccessful:
      return {
        type: NotificationType.Warning,
        customMessage:
          'Some synonyms uploaded, some failed. Check the downloaded report for details.',
      };
    case ImportReportStatus.successful:
      return {
        type: NotificationType.Success,
        customMessage: 'Your synonyms file was updated successfully',
      };
  }
};
