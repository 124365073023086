import React, { useState } from 'react';
import { Button, TypographyType, TypographyVariant, Icon } from 'src/components-dummy';
import { AvailableIcons } from 'src/components-dummy/Icon/AvailableIcons';
import bannerExample from './Assets/images/bannerExample.jpg';
import { BannerSettings } from './components/BannerSettings';
import { CameraIcon } from './components/CameraIcon';
import { Heading } from './components/Heading';
import { CtaButton } from './components/CtaButton';
import { Strategy } from './components/Strategy';

import { DiscoveryBannerSettingsProps } from './types';
import { useDiscoveryBannerSettingsDraft } from './useDiscoveryBannerSettingsDraft';
import { createOptionalPortal } from '../helpers';
import {
  DiscoverBannerSettingsContainer,
  DiscoverBannerSettingsDescription,
  InfoButton,
  InfoButtonTitle,
  InfoModalStyled,
} from './DiscoveryBannerSettings.styles';

export const DiscoveryBannerSettings = ({
  shopId,
  discoveryBannerSettings,
  selectedVariantId,
  formHeaderElementRef,
  onIsDirty,
  dispatch,
  disabled = false,
  shouldRefetch,
}: DiscoveryBannerSettingsProps): JSX.Element | null => {
  const discoveryBanner = useDiscoveryBannerSettingsDraft({
    discoveryBannerSettings,
    dispatch,
    shopId,
    selectedVariantId,
    onIsDirty,
    shouldRefetch,
  });

  const {
    draftDiscoveryBannerSettings,
    isValid,
    isDirty,
    onSubmit,
    onDiscard,
    onChange,
    onChangeHeading,
    onChangeButton,
    onChangeIcon,
    errors,
  } = discoveryBanner;

  const formButtons = disabled ? (
    <></>
  ) : (
    <>
      <Button onClick={onDiscard} variant='tertiary' disabled={!isDirty}>
        Discard
      </Button>
      <Button onClick={onSubmit} disabled={!(isValid && isDirty)} variant='primary'>
        {selectedVariantId ? 'Apply' : 'Save'}
      </Button>
    </>
  );

  const formButtonsSection = createOptionalPortal({
    portalContent: disabled ? <></> : formButtons,
    targetContainerRef: formHeaderElementRef,
    fallback: <div className='ranking-button-wrapper'>{formButtons}</div>,
  });

  const [showSettingsExample, setSettingsExampleModalIsOpened] = useState(false);

  const onSettingsExampleModalClose = () => {
    setSettingsExampleModalIsOpened(false);
  };

  return (
    <DiscoverBannerSettingsContainer>
      <DiscoverBannerSettingsDescription
        variant={TypographyVariant.MediumRegular}
        type={TypographyType.Paragraph}
      >
        Add Discovery Banners to make it easier for your shoppers to discover similar items.
        <br />
        (Our discovery banner uses Syte&apos;s visual AI technology)
      </DiscoverBannerSettingsDescription>

      <InfoButton onClick={() => setSettingsExampleModalIsOpened(true)}>
        <InfoButtonTitle variant={TypographyVariant.MediumRegular} type={TypographyType.Button}>
          See example
        </InfoButtonTitle>
        <Icon name={AvailableIcons.ExternalLink} />
      </InfoButton>

      <InfoModalStyled
        isOpened={showSettingsExample}
        closeBtnTxt='Close'
        onClickOutside={onSettingsExampleModalClose}
        maxWidth={75}
      >
        <img src={bannerExample} alt='Banner example' />
      </InfoModalStyled>

      <Strategy
        strategy={draftDiscoveryBannerSettings.strategy}
        onChange={onChange}
        disabled={disabled}
      />

      <BannerSettings
        discoveryBannerSettings={draftDiscoveryBannerSettings}
        onChange={onChange}
        disabled={disabled}
        errors={errors}
      />

      <CameraIcon
        discoveryBannerSettings={draftDiscoveryBannerSettings}
        onChange={onChangeIcon}
        disabled={disabled}
        errors={errors}
      />

      <Heading
        discoveryBannerSettings={draftDiscoveryBannerSettings}
        onChange={onChangeHeading}
        disabled={disabled}
        errors={errors}
      />

      <CtaButton
        discoveryBannerSettings={draftDiscoveryBannerSettings}
        onChange={onChangeButton}
        disabled={disabled}
        errors={errors}
      />

      {formButtonsSection}
    </DiscoverBannerSettingsContainer>
  );
};
