import { useEffect, useState } from 'react';
import { intersection } from 'lodash';
import { Dispatch } from 'src/components-bl';
import { IShopCatalog } from 'src/services';
import { supportedLanguageCodes } from 'src/components-bl/AugmentedSearch/helpers';
import { augmentedSearchContainerActions } from '../Actions';

export function useAugmentedSearchCatalog({
  shopId,
  dispatch,
}: {
  shopId?: number;
  dispatch: Dispatch;
}): { hasAugmentedSearchCatalog: boolean | null; availableLanguageCodes: string[] } {
  const [hasAugmentedSearchCatalog, setHasAugmentedSearchCatalog] = useState<boolean | null>(null);
  const [availableLanguageCodes, setAvailableLanguageCodes] = useState<string[]>([]);

  useEffect(() => {
    const validateAugmentedSearchCatalogExists = async () => {
      if (shopId) {
        try {
          const shopCatalogs: IShopCatalog[] = await (
            dispatch(augmentedSearchContainerActions.getShopCatalogs({ shopId })) as any
          ).unwrap();

          const augmentedSearchCatalogs = shopCatalogs.filter(
            catalog => catalog.isAugmentedSearchCatalog
          );

          setHasAugmentedSearchCatalog(augmentedSearchCatalogs.length > 0);

          const languages = augmentedSearchCatalogs.flatMap(catalog => {
            const { availableLocales, defaultLocale } = catalog;
            if (availableLocales?.languages?.length) {
              return availableLocales.languages;
            }
            return defaultLocale.split('_')[0];
          });

          const supportedLanguages = intersection(languages, supportedLanguageCodes);
          setAvailableLanguageCodes([...new Set(supportedLanguages)]);
        } catch (error) {
          console.error(error);
        }
      }
    };

    validateAugmentedSearchCatalogExists();
  }, [shopId, dispatch, setHasAugmentedSearchCatalog, setAvailableLanguageCodes]);

  return { hasAugmentedSearchCatalog, availableLanguageCodes };
}
