import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath } from 'react-router';
import { isEqual } from 'lodash';
import { Button, NotificationType, TypographyType, TypographyVariant } from 'src/components-dummy';
import { RoutedComponentProps } from 'src/app-routes';
import { useValidateSchema } from 'src/hooks';
import { Keys } from 'src/types';
import { SynonymLanguage } from 'src/services/src/service/augmented-search/synonyms/types';
import { Dispatch } from 'src/components-bl/types';
import { ConfirmationDialog } from 'src/components-dummy';
import { Synonym } from 'src/services';
import { synonymsActions } from '../SynonymsActions';
import {
  constructDuplicateTermsMessage,
  extractDuplicateTermsFromError,
  parseTerms,
} from './helpers';
import {
  ButtonStyled,
  InfoTextContainerStyled,
  InfoTextStyled,
  InputLabelStyled,
  TagsInputStyled,
  ErrorLabelStyled,
  ErrorContainer,
} from './SynonymFormModal.styles';
import { synonymValidationSchema } from './synonym-validation-schema';
import { reduceErrorsByPrefix } from 'src/utils';

export interface SynonymFormModalProps extends RoutedComponentProps {
  shopId: number;
  language: SynonymLanguage;
  hasAugmentedSearchCatalog: boolean;
  isReady: boolean;
  dispatch: Dispatch;
  synonymId: string;
  selectedSynonym: Synonym;
}

export function SynonymFormModal({
  shopId,
  language,
  permittedRouteMap,
  hasAugmentedSearchCatalog,
  isReady,
  dispatch,
  synonymId,
  selectedSynonym,
}: SynonymFormModalProps): JSX.Element {
  const mappedInitialPhrase = selectedSynonym?.phrase || [];
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [phraseDraft, setPhraseDraft] = useState(mappedInitialPhrase || []);
  const [isDirty, setIsDirty] = useState(false);
  const isCreateMode = !selectedSynonym;

  const { errors, validate, isValid } = useValidateSchema<Pick<Synonym, 'phrase'>>({
    schema: synonymValidationSchema,
    validateOnStart: false,
    initialData: { phrase: mappedInitialPhrase },
  });

  const canSave = isValid && isDirty;

  const phraseErrors = reduceErrorsByPrefix({
    errors,
    prefix: 'phrase',
  });
  const errorMessages = useMemo(
    () => [...new Set(Object.values(phraseErrors).map(error => error.message))],
    [phraseErrors]
  );
  const hasError = errorMessages.length > 0;

  const onChange = useCallback(
    (terms: string[]) => {
      const parsedTerms = parseTerms(terms);
      setPhraseDraft(parsedTerms);
      validate({ dataToValidate: { phrase: parsedTerms } });
    },
    [setPhraseDraft]
  );

  useEffect(() => {
    const newIsDirty = !isEqual(mappedInitialPhrase, phraseDraft);
    setIsDirty(newIsDirty);
  }, [mappedInitialPhrase, phraseDraft]);

  useEffect(() => {
    dispatch(synonymsActions.notifyIsDirty({ isDirty }));
  }, [isDirty]);

  const onModalClose = useCallback(() => {
    if (!permittedRouteMap.augmentedSearchSynonymsLanguage) {
      return;
    }
    if (!isSaveInProgress && isDirty) {
      setPhraseDraft([]);
      setIsDirty(false);
      dispatch(synonymsActions.resetSelectedSynonym());
    }

    dispatch(
      synonymsActions.navigateTo({
        navigateTo: generatePath(permittedRouteMap.augmentedSearchSynonymsLanguage.path, {
          shopId,
          language,
        }),
      })
    );
  }, [shopId, language]);

  const onCancelClick = useCallback(() => {
    if (!isSaveInProgress) {
      onModalClose();
    }
  }, [isSaveInProgress, onModalClose]);

  const onSave = useCallback(async () => {
    setIsSaveInProgress(true);
    try {
      if (isCreateMode) {
        await (
          dispatch(
            synonymsActions.createSynonym({
              shopId,
              language,
              phrase: phraseDraft,
            })
          ) as any
        ).unwrap();
      } else {
        await (
          dispatch(
            synonymsActions.editSynonym({
              shopId,
              language,
              synonymId,
              phrase: phraseDraft,
            })
          ) as any
        ).unwrap();
      }
      dispatch(synonymsActions.notifyIsDirty({ isDirty: false }));

      setIsSaveInProgress(false);
      onModalClose();
    } catch (error) {
      setIsSaveInProgress(false);
      console.error(error);

      let customMessage = 'Failed to update your synonyms list';

      const duplicateTerms = extractDuplicateTermsFromError(error.error?.data);

      if (duplicateTerms.length > 0) {
        customMessage = constructDuplicateTermsMessage(duplicateTerms);
      }

      dispatch(
        synonymsActions.issueToastNotification({ customMessage, type: NotificationType.Error })
      );
    }
  }, [isCreateMode, shopId, language, phraseDraft, dispatch, setIsSaveInProgress]);

  if (!isReady || !hasAugmentedSearchCatalog) {
    return null;
  }

  return (
    <ConfirmationDialog onCancel={onCancelClick}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>
          {isCreateMode ? 'Add Synonyms' : 'Edit Synonyms'}
        </ConfirmationDialog.Title>
      </ConfirmationDialog.Header>
      <ConfirmationDialog.Content>
        <InfoTextContainerStyled>
          <InfoTextStyled type={TypographyType.Body} variant={TypographyVariant.MediumBold}>
            Group words together as synonyms to make them equivalent in search.
          </InfoTextStyled>
          <InfoTextStyled type={TypographyType.Paragraph} variant={TypographyVariant.MediumRegular}>
            List each synonym on a new line, or separate them with commas.
          </InfoTextStyled>
        </InfoTextContainerStyled>

        <InputLabelStyled type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
          Synonyms
        </InputLabelStyled>
        <TagsInputStyled
          tags={phraseDraft}
          placeholder='Example: t-shirt, tee, tshirt'
          onChange={onChange}
          delimiters={[Keys.Enter]}
          error={hasError}
        />
        <ErrorContainer>
          {errorMessages.map((message, index) => (
            <ErrorLabelStyled
              key={`${index}-${message}`}
              type={TypographyType.Body}
              variant={TypographyVariant.SmallRegular}
            >
              {message}
            </ErrorLabelStyled>
          ))}
        </ErrorContainer>
      </ConfirmationDialog.Content>
      <ConfirmationDialog.Footer>
        <ButtonStyled
          variant='primary'
          onClick={onSave}
          disabled={!canSave}
          loading={isSaveInProgress}
        >
          {isCreateMode ? 'Add' : 'Save'}
        </ButtonStyled>
        <Button variant='tertiary' onClick={onModalClose}>
          Cancel
        </Button>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
}
