import { SynonymLanguageCode } from 'src/services/src/service/augmented-search/synonyms/types';

export const languagePropsMap = {
  en: {
    iconName: 'flags/US',
    displayName: 'English - United States',
  },
  fr: {
    iconName: 'flags/FR',
    displayName: 'French - France',
  },
  de: {
    iconName: 'flags/DE',
    displayName: 'German - Germany',
  },
  it: {
    iconName: 'flags/IT',
    displayName: 'Italian - Italy',
  },
  ko: {
    iconName: 'flags/KR',
    displayName: 'Korean - South Korea',
  },
  pl: {
    iconName: 'flags/PL',
    displayName: 'Polish - Poland',
  },
  pt: {
    iconName: 'flags/PT',
    displayName: 'Portuguese - Portugal',
  },
  es: {
    iconName: 'flags/ES',
    displayName: 'Spanish - Spain',
  },
};

export const supportedLanguageCodes = Object.keys(SynonymLanguageCode);
