import React, { useRef, useState } from 'react';
import { ParseJoiValidateResponse } from 'src/utils';
import { Typography, TypographyType, TypographyVariant, Icon } from 'src/components-dummy';
import { AvailableIcons } from 'src/components-dummy/Icon/AvailableIcons';
import { useOnClickOutside } from 'src/hooks/useOnClickOutside';
import { DiscoveryBannerGeneralSettings } from 'src/services/src/service/types/syte-products/discovery-banner';
import heading from '../../Assets/images/heading.jpg';
import { DiscoveryBannerSettingsRecord } from '../../types';
import { handleUpdateColor } from '../../colorToHex';
import {
  BannerSetting,
  ChromePickerStyled,
  ColorPickerButton,
  Container,
  Content,
  Header,
  InfoButton,
  InfoButtonTitle,
  InfoModalStyled,
  NumericInputStyled,
  TextBoxStyled,
} from '../../DiscoveryBannerSettings.styles';

export interface HeadingProps {
  discoveryBannerSettings: DiscoveryBannerGeneralSettings['generalSettings'];
  onChange: (args: Partial<DiscoveryBannerGeneralSettings['generalSettings']['banner']>) => void;
  disabled?: boolean;
  errors: ParseJoiValidateResponse<DiscoveryBannerSettingsRecord>;
}

export const Heading = ({
  discoveryBannerSettings,
  onChange,
  disabled = false,
  errors,
}: HeadingProps): JSX.Element | null => {
  const [showBannerFontColorPicker, setShowBannerFontColorPicker] = useState(false);
  const [showHeading, setHeadingModalIsOpened] = useState(false);
  const onHeadingModalClose = () => {
    setHeadingModalIsOpened(false);
  };
  const headingFontColorRef = useRef(null);
  useOnClickOutside(headingFontColorRef, () => {
    setShowBannerFontColorPicker(false);
  });
  return (
    <Container>
      <Header>
        <Typography variant={TypographyVariant.MediumBold} type={TypographyType.Body}>
          Heading
        </Typography>
        <InfoButton onClick={() => setHeadingModalIsOpened(true)}>
          <InfoButtonTitle variant={TypographyVariant.SmallRegular}>
            What is heading
          </InfoButtonTitle>
          <Icon name={AvailableIcons.TooltipQuestionmarkBlue} />
        </InfoButton>
        <InfoModalStyled
          isOpened={showHeading}
          closeBtnTxt='Close'
          onClickOutside={onHeadingModalClose}
          title='What is heading?'
          maxWidth={50}
        >
          <img src={heading} alt='Heading example' />
        </InfoModalStyled>
      </Header>
      <Content>
        <BannerSetting ref={headingFontColorRef}>
          <TextBoxStyled
            label='Heading Text Color'
            placeholder={discoveryBannerSettings.banner.bannerColor}
            error={!!errors.name}
            value={discoveryBannerSettings.banner.bannerColor}
            fullWidth
            onChange={fontColor => {
              onChange({ bannerColor: fontColor });
            }}
            disabled={disabled}
          >
            <ColorPickerButton
              type='button'
              aria-label='font-color-mock'
              onClick={() => setShowBannerFontColorPicker(!showBannerFontColorPicker)}
              style={{
                backgroundColor: discoveryBannerSettings.banner.bannerColor,
              }}
              disabled={disabled}
            />

            {showBannerFontColorPicker && (
              <ChromePickerStyled
                color={discoveryBannerSettings.banner.bannerColor}
                onChange={updatedColor => {
                  handleUpdateColor(updatedColor, newColor => {
                    onChange({ bannerColor: newColor });
                  });
                }}
              />
            )}
          </TextBoxStyled>
        </BannerSetting>
        <BannerSetting>
          <TextBoxStyled
            label='Heading Font Family'
            placeholder='sans-serif'
            error={!!errors.name}
            value={discoveryBannerSettings.banner.bannerFontFamily}
            fullWidth
            onChange={fontFamily => {
              onChange({ bannerFontFamily: fontFamily });
            }}
            disabled={disabled}
          />
        </BannerSetting>
        <BannerSetting>
          <Typography variant={TypographyVariant.MediumRegular} type={TypographyType.Body}>
            Heading Font Size
          </Typography>
          <NumericInputStyled
            placeholder='24'
            error={!!errors.numberOfResultsToDisplay}
            value={discoveryBannerSettings.banner.bannerFontSize}
            onChange={value => {
              onChange({ bannerFontSize: value });
            }}
            disabled={disabled}
          />
        </BannerSetting>
      </Content>
    </Container>
  );
};
