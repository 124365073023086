import styled from '@emotion/styled';

export const SynonymsPageHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
  height: 50px;
  margin-top: 15px;
  margin-bottom: 40px;
`;

export const SynonymsPageHeaderTitleContainerStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const SynonymsButtonsWrapperStyled = styled.div`
  display: flex;
  gap: 8px;
`;

export const SynonymsPageHeaderTitleRowStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
