import React from 'react';
import { Environment } from '../../../hooks/useEnvironment';
import {
  AvailableIcons,
  Typography,
  TypographyType,
  TypographyVariant,
  Tooltip,
} from '../../../components-dummy';
import { EnvironmentIconStyled, EnvironmentCard } from './EnvironmentBadge.style';
import { useEnvironment } from 'src/hooks/useEnvironment';

export const EnvironmentBadge = () => {
  const { env, ticket } = useEnvironment();

  if (env === Environment.Production) {
    return null;
  }

  return (
    <EnvironmentCard>
      <EnvironmentIconStyled name={AvailableIcons.WarningOutline} />
      <Tooltip value={ticket || ''} disabled={!ticket} position='top center'>
        <Typography variant={TypographyVariant.LargeMedium} type={TypographyType.Body}>
          {`${env} Environment`}
        </Typography>
      </Tooltip>
    </EnvironmentCard>
  );
};
