import React from 'react';
import { AppRoute, AppSwitch } from 'src/app-routes';
import { generatePath, Redirect } from 'react-router';
import { Page } from 'src/components-dummy';
import { ContainerComponentProps } from 'src/containers/AugSearchFilters/augmented-search-filters.types';
import { SynonymsLanguagesListContainer } from './components/SynonymsLanguagesListContainer';
import { SynonymsTableContainer } from './components/SynonymsTableContainer';
import { useAugmentedSearchCatalog } from '../../hooks/use-augmented-search-catalog';
import { SynonymsContainerStyled } from '../SynonymsContainer.styles';

type SynonymsContainerProps = ContainerComponentProps;

export const SynonymsContainer = ({
  shopId,
  permittedRouteMap,
  dispatch,
}: SynonymsContainerProps): JSX.Element | null => {
  const { hasAugmentedSearchCatalog, availableLanguageCodes } = useAugmentedSearchCatalog({
    shopId,
    dispatch,
  });

  return shopId ? (
    <SynonymsContainerStyled>
      <Page.Title>Synonyms</Page.Title>
      <AppSwitch>
        <AppRoute
          route={permittedRouteMap.augmentedSearchSynonymsLanguagesList}
          Component={SynonymsLanguagesListContainer}
          componentProps={{
            permittedRouteMap,
            shopId,
            dispatch,
            hasAugmentedSearchCatalog,
            availableLanguageCodes,
          }}
        />
        <AppRoute
          route={permittedRouteMap.augmentedSearchSynonymsLanguage}
          Component={SynonymsTableContainer}
          componentProps={{
            permittedRouteMap,
            shopId,
            dispatch,
            hasAugmentedSearchCatalog,
            availableLanguageCodes,
          }}
          exact={false}
        />
        {permittedRouteMap.augmentedSearchSynonymsLanguagesList && shopId && (
          <Redirect
            to={generatePath(permittedRouteMap.augmentedSearchSynonymsLanguagesList.path, {
              shopId,
            })}
          />
        )}
      </AppSwitch>
    </SynonymsContainerStyled>
  ) : null;
};
