import React from 'react';
import {
  Typography,
  TypographyType,
  TypographyVariant,
  AutoCompleteSingleValue,
  AutoCompleteSingleValueProps,
  OptionProps,
  EllipsisWithTooltip,
} from '../../../../components-dummy';
import { getDescription } from '../../helpers/get-description';
import { SuggestedOffer } from '../../types';
import { FormGroup } from '../Form';
import { Image, MenuItemStyled } from './AutoComplete.styles';

export interface AutoCompleteFormProps extends AutoCompleteSingleValueProps {
  title: string;
  displayOptionTitleOnly?: boolean;
}

export function Option({
  option,
  displayOptionTitleOnly = false,
  ...rest
}: OptionProps<string, SuggestedOffer> & { displayOptionTitleOnly?: boolean }): JSX.Element {
  const { imageUrl = '', title = '' } = option.data || {};
  const description = getDescription(title, option.title, displayOptionTitleOnly);

  return (
    <MenuItemStyled {...rest}>
      {imageUrl && <Image src={imageUrl} alt={title} />}
      <EllipsisWithTooltip tooltipText={description}>{description}</EllipsisWithTooltip>
    </MenuItemStyled>
  );
}

export function AutoCompleteForm({
  title,
  placeholder,
  selectedValue,
  onChange,
  options,
  displayOptionTitleOnly,
  ...rest
}: AutoCompleteFormProps): JSX.Element {
  return (
    <FormGroup>
      <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
        {title}
      </Typography>
      <AutoCompleteSingleValue
        closeOnSingleMatch
        placeholder={placeholder}
        selectedValue={selectedValue}
        onChange={onChange}
        options={options}
        freeSolo={false}
        filterOptions={o => o}
        {...rest}
      >
        <AutoCompleteSingleValue.Option>{Option}</AutoCompleteSingleValue.Option>
      </AutoCompleteSingleValue>
    </FormGroup>
  );
}
