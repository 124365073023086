import styled from '@emotion/styled';
import { Button, Icon, Typography } from 'src/components-dummy';

interface ProductTileProps {
  disabled?: boolean;
}

export const ProductTileStyled = styled.div<ProductTileProps>`
  padding: 16px 24px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 12px;
  max-width: 285px;
  min-width: 120px;
  background-color: ${({ theme }) => theme.palette.common.white};
  color: ${({ disabled, theme }) =>
    disabled ? theme.palette.custom['gray-50'] : theme.palette.custom['gray-10']};
  box-shadow: 0px 4px 6px -4px #0000001f;
`;

export const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
  color: inherit;
`;

export const TitleStyled = styled(Typography)`
  flex-grow: 1;
  position: relative;
  left: -10px;
`;

export const ParagraphStyled = styled.p`
  font-size: 12px;
  line-height: 20px;
  color: inherit;
`;

export const LinkButtonStyled = styled(Button)`
  width: fit-content;
  padding: 3px 5px;
  height: fit-content;
  margin-top: auto;
`;

export const IconStyled = styled(Icon)<ProductTileProps>`
  position: relative;
  flex-shrink: 0;
  left: -10px;
  & rect {
    fill: ${({ disabled, theme }) => (disabled ? theme.palette.custom['gray-50'] : 'current')};
  }
  & g {
    filter: ${({ disabled }) => (disabled ? 'none' : 'current')};
  }
`;
