import { flatten } from 'lodash';
import { IShopCatalog, ShopCatalogVertical } from 'src/services';
import { getStringEnumValues } from 'src/utils';
import { IShopCatalogDraft } from './types';

const allCatalogVerticals = getStringEnumValues(ShopCatalogVertical);

export function mapCatalogToDraft(catalog: IShopCatalog): IShopCatalogDraft {
  return {
    verticals: new Set(catalog.verticals),
    name: catalog.name,
    isPrimary: catalog.isPrimary,
    isAugmentedSearchCatalog: catalog.isAugmentedSearchCatalog,
  };
}

export function mapDraftToConnectCatalogPayload(
  draft: IShopCatalogDraft
): Pick<IShopCatalog, 'isPrimary' | 'verticals' | 'name' | 'isAugmentedSearchCatalog'> {
  return {
    verticals: [...draft.verticals],
    name: draft.name,
    isPrimary: draft.isPrimary,
    isAugmentedSearchCatalog: draft.isAugmentedSearchCatalog,
  };
}

export function mapDraftToConnectUpdatePayload(
  draft: IShopCatalogDraft
): Pick<IShopCatalog, 'isPrimary' | 'verticals' | 'isAugmentedSearchCatalog'> {
  return {
    verticals: [...draft.verticals],
    isPrimary: draft.isPrimary,
    isAugmentedSearchCatalog: draft.isAugmentedSearchCatalog,
  };
}

export function getCatalogsContext({
  catalogs,
  currentCatalogId,
}: {
  catalogs: IShopCatalog[] | undefined;
  currentCatalogId: string | undefined;
}): {
  nonPrimaryVerticals: Set<ShopCatalogVertical>;
  areVerticalsAvailable: boolean;
  canSetIsAugmentedSearch: boolean;
} {
  const catalogsWithoutCurrent = catalogs?.filter(catalog => catalog.id !== currentCatalogId) || [];
  const primaryCatalogs = catalogsWithoutCurrent.filter(catalog => catalog.isPrimary);

  const augmentedSearchCatalogs = catalogsWithoutCurrent.filter(
    catalog => catalog.isAugmentedSearchCatalog
  );

  const takenVerticals = new Set(flatten(primaryCatalogs.map(catalog => catalog.verticals)));

  const nonPrimaryVerticals = new Set(
    allCatalogVerticals.filter(vertical => !takenVerticals.has(vertical))
  );

  const areVerticalsAvailable = nonPrimaryVerticals.size !== 0;
  const canSetIsAugmentedSearch = augmentedSearchCatalogs.length === 0;

  return { nonPrimaryVerticals, areVerticalsAvailable, canSetIsAugmentedSearch };
}
