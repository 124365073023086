import React, { FocusEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
  ActionIcon,
  AutoComplete,
  AutoCompleteOption,
  AvailableIcons,
  Link,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { ConditionsGroup } from '../ConditionsGroup/ConditionsGroup';
import { RuleConditionsAddGroup } from '../RuleConditionsAddGroup/RuleConditionsAddGroup';
import { getStringEnumValues, reduceErrorsByPrefix } from 'src/utils';
import { mapErrorMessages } from '../../../../mapErrorMessages';
import { MerchandisingRuleTypes } from '../../../../../../services';
import { RuleDraft } from '../../MerchandisingRuleForm.config';
import {
  ConditionRowDataStyled,
  ConditionRowErrors,
  ConditionRowStyled,
  ConditionText,
} from './RuleFormConditionsSection.styles';

const TITLE_TEXT = 'Condition (Context)';
const SUB_TITLE_TEXT = 'Triggers the rule only when a specific context is passed (Advanced)';

const enumContextOptions = getStringEnumValues(
  MerchandisingRuleTypes.MerchandisingRuleContextEnum
).map(value => {
  return {
    value,
    title: value,
  };
});
const setOfEnumContext = new Set<string>(
  getStringEnumValues(MerchandisingRuleTypes.MerchandisingRuleContextEnum)
);

export interface ContextConditionProps {
  context: string[];
  rulesContextOptions: string[];
  onFieldChange: (data: Partial<Pick<RuleDraft, 'context'>>) => void;
  errors: any;
}

export const ContextCondition = React.memo(
  ({
    context,
    rulesContextOptions = [],
    onFieldChange,
    errors,
  }: ContextConditionProps): JSX.Element => {
    const [contextOptions, setContextOptions] = useState<AutoCompleteOption[]>([]);
    const [isConditionOpen, setIsConditionOpen] = useState(Boolean(context?.length));

    const allContextOptions = useMemo(() => {
      const options = rulesContextOptions
        .filter(contextValue => !setOfEnumContext.has(contextValue))
        .map(contextValue => {
          return {
            value: contextValue,
            title: contextValue,
          };
        });

      return [...enumContextOptions, ...options];
    }, [rulesContextOptions]);

    const onContextChange = useCallback(
      (newContext: string[]) => {
        onFieldChange({ context: newContext });
      },
      [onFieldChange]
    );

    const onContextFocus = useCallback(() => {
      if (context.length === 0) {
        setContextOptions(allContextOptions);
      }
    }, [context, allContextOptions]);

    const onContextBlur = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        setContextOptions([]);

        const newValue = event.target.value;
        if (!newValue) {
          return;
        }

        onFieldChange({ context: [...context, newValue] });
      },
      [context, setContextOptions, onFieldChange]
    );

    useEffect(() => {
      if (!context.length) {
        setContextOptions(allContextOptions);
        return;
      }

      const newOptions = allContextOptions.filter(contextOption => {
        return !context.includes(contextOption.value);
      });

      setContextOptions(newOptions);
    }, [context]);

    const onAddNewCondition = () => {
      onFieldChange({ context: [] });
      setIsConditionOpen(true);
    };

    const onDeleteCondition = () => {
      onFieldChange({ context: [] });
      setIsConditionOpen(false);
    };

    const localErrors = reduceErrorsByPrefix({
      errors,
      prefix: 'context',
    });

    const rowErrorLabels = mapErrorMessages(localErrors);
    const isError = rowErrorLabels.length > 0;

    return !isConditionOpen ? (
      <RuleConditionsAddGroup
        title={TITLE_TEXT}
        subTitle={SUB_TITLE_TEXT}
        onClick={onAddNewCondition}
      />
    ) : (
      <ConditionsGroup>
        <ConditionsGroup.Title>{TITLE_TEXT}</ConditionsGroup.Title>
        <ConditionsGroup.SubTitle>
          {SUB_TITLE_TEXT}{' '}
          <Link href='https://support.syte.ai/space/ET/2697953550/Console+Merchandising+Rules#Trigger-by-context'>
            Learn more
          </Link>{' '}
        </ConditionsGroup.SubTitle>
        <ConditionsGroup.Content>
          <ConditionRowStyled>
            <ConditionRowDataStyled>
              <ConditionText type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
                Context is
              </ConditionText>
              <AutoComplete
                placeholder='Select or add new...'
                freeSolo
                multiple
                selectedValues={context}
                options={contextOptions}
                onChange={onContextChange}
                onFocus={onContextFocus}
                onBlur={onContextBlur}
                errored={isError}
                openPopUp
              />
              <ActionIcon iconName={AvailableIcons.TrashCan} onClick={onDeleteCondition} />
            </ConditionRowDataStyled>
            {isError ? <ConditionRowErrors>{rowErrorLabels}</ConditionRowErrors> : null}
          </ConditionRowStyled>
        </ConditionsGroup.Content>
      </ConditionsGroup>
    );
  }
);
