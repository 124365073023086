import React, { useCallback, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { RoutedComponentProps } from 'src/app-routes';
import { useLocation } from 'react-router-dom';
import { AdminSearchModal } from 'src/components-bl/AdminSearchModal';
import { UserRoles } from 'src/services/src/service/types/users';
import { UserTypes } from 'src/services';
import { InternalFeatureIndication } from 'src/components-bl/InternalFeatureIndication';
import { Dispatch } from 'src/components-bl/types';
import { UserAvatarList } from 'src/components-bl/UserAvatarList';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  PublishNotificationBanner,
  PublishStatusIndicationMode,
  ShopSelector,
  VisualEditorButton,
} from '../../components-bl';
import * as Actions from '../../components-dummy/SideNav/Actions/SideNavActions';
import {
  AvailableIcons,
  Header,
  Icon,
  Indicator,
  IndicatorColor,
  Skeleton,
  Typography,
  TypographyType,
  TypographyVariant,
} from '../../components-dummy';
import syteIcon from '../../images/syteIcon.svg';
import { NotificationsContainer } from '../NotificationsContainer';
import { AppHeaderItem } from './components/AppHeaderItem';
import { AppHeaderComponentName } from './constants';
import { useExperimentsWS } from './useExperimentsWS';
import {
  AdminSearchTriggerStyled,
  ContactSupportIconStyled,
  TooltipSupportStyled,
  TooltipSelectedShopStyled,
  UserProfileAndSupportDivider,
  TooltipAccountListStyled,
  TooltipBackToAccountListStyled,
} from './AppHeader.styles';
import './AppHeader.scss';
import { useShopVersionsWS } from './useShopVersionsWS';
import { EnvironmentBadge } from './components/EnvironmentBadge';

type AppHeaderProps = RoutedComponentProps & {
  role?: UserTypes.UserRoles;
};

const ConnectedShopPublishStatusIndication = (): JSX.Element => {
  const { draft, shopId, runningExperiment, dataFields } = useAppSelector(state => {
    return {
      shopId: state.shop.current?.shopId,
      draft: state.versioning.draft,
      runningExperiment: state.experiments.runningExperiment,
      dataFields: state.dataFields?.dataFields,
    };
  }, shallowEqual);
  const dispatch = useAppDispatch();
  return (
    <>
      {shopId && draft && (
        <PublishNotificationBanner
          dispatch={dispatch}
          shopId={shopId}
          dataFields={dataFields}
          draft={draft}
          mode={PublishStatusIndicationMode.CanPublish}
          runningExperiment={runningExperiment}
        />
      )}
    </>
  );
};

const ConnectedInternalFeatureIndication = (): JSX.Element => {
  const featureToggles = useAppSelector(state => state.shop.featureToggles);

  return <InternalFeatureIndication featureToggles={featureToggles} />;
};

export const AppHeader = ({ permittedRouteMap, role }: AppHeaderProps): JSX.Element => {
  const dispatch: Dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const logoRoute =
    (role === UserTypes.UserRoles.SyteAdmin && permittedRouteMap.accountList) || null;
  const {
    loggedInUser,
    runningExperimentId,
    shopId,
    isShopLoading,
    selectedShop,
    shops,
    users,
    isOnline,
    accountName,
  } = useAppSelector(
    state => ({
      loggedInUser: state.global.loggedInUser,
      runningExperimentId: state.experiments.runningExperiment?.id,
      shopId: state.shop.current?.shopId,
      isShopLoading: state.shop.isShopLoading,
      selectedShop: state.shop.current,
      shops: state.shop.shops,
      users: state.userLocation.users,
      isOnline: state.global.domainEventsWSConnected,
      accountName: state.account.current?.accountName,
    }),
    shallowEqual
  );
  const [showAdminModal, setShowAdminModal] = useState(false);
  const isSyteAdmin = loggedInUser?.role === UserRoles.SyteAdmin;
  const handleNavigate = (path: string | undefined) => {
    if (path) {
      dispatch(Actions.navigateTo(path));
    }
  };

  const onClose = useCallback(() => {
    setShowAdminModal(false);
  }, [setShowAdminModal]);

  const shopPublishStatusIndicator = (
    <AppHeaderItem
      componentName={AppHeaderComponentName.ShopPublishStatusIndication}
      currentUrl={pathname}
    >
      <ConnectedShopPublishStatusIndication />
    </AppHeaderItem>
  );

  const runningExperimentsIndicator = (
    <AppHeaderItem
      componentName={AppHeaderComponentName.RunningExperimentsIndicator}
      currentUrl={pathname}
    >
      {runningExperimentId && (
        <Indicator color={IndicatorColor.Green} className='syte-app-header-section-indicator'>
          Running experiment
        </Indicator>
      )}
    </AppHeaderItem>
  );

  const featureToggles = useAppSelector(state => state.shop.featureToggles);
  const shouldDisplayPreviewButton =
    permittedRouteMap.shopList?.path !== pathname &&
    featureToggles?.preview.enabled &&
    loggedInUser &&
    featureToggles.preview.permittedRoles?.includes(loggedInUser.role);

  const visualEditor = (
    <AppHeaderItem componentName={AppHeaderComponentName.VisualEditor} currentUrl={pathname}>
      {shouldDisplayPreviewButton && (
        <VisualEditorButton shopId={shopId} permittedRouteMap={permittedRouteMap} />
      )}
    </AppHeaderItem>
  );

  const internalFeatureIndicator = (
    <AppHeaderItem
      componentName={AppHeaderComponentName.InternalFeatureIndication}
      currentUrl={pathname}
    >
      <ConnectedInternalFeatureIndication />
    </AppHeaderItem>
  );

  const userAvatarList = loggedInUser && (
    <AppHeaderItem componentName={AppHeaderComponentName.UserAvatarList} currentUrl={pathname}>
      <UserAvatarList
        dispatch={dispatch}
        myProfilePath={permittedRouteMap.myProfile?.path}
        users={
          !users.length && loggedInUser
            ? [
                {
                  email: loggedInUser.email,
                  firstName: loggedInUser.firstName,
                  lastName: loggedInUser.lastName,
                  isEditing: true,
                  userId: loggedInUser.userId,
                  isMe: true,
                },
              ]
            : users
        }
        isOnline={isOnline}
      />
    </AppHeaderItem>
  );

  const shopSelector = (
    <>
      {selectedShop ? (
        <TooltipSelectedShopStyled
          value={`${selectedShop?.shopName} (ID${selectedShop?.shopId})`}
          position='bottom center'
        >
          <ShopSelector selectedShop={selectedShop} shops={shops} dispatch={dispatch} />
        </TooltipSelectedShopStyled>
      ) : (
        <ShopSelector selectedShop={selectedShop} shops={shops} dispatch={dispatch} />
      )}
    </>
  );

  useExperimentsWS({ dispatch, shopId });
  useShopVersionsWS({ dispatch, shopId });

  return (
    <Header className='syte-app-header'>
      {shopPublishStatusIndicator}
      {isSyteAdmin && (
        <AdminSearchModal
          show={showAdminModal}
          dispatch={dispatch}
          onClose={onClose}
          shopDefaultRoute={permittedRouteMap.ranking?.path || ''}
          accountDefaultRoute={permittedRouteMap.shopList?.path || ''}
          userDefaultRoute={permittedRouteMap.team?.path || ''}
          catalogsDefaultRoute={permittedRouteMap.catalogManager?.path || ''}
        />
      )}
      <div className='syte-app-header-section-left'>
        <div className='syte-app-header-section-left-logo'>
          {![
            permittedRouteMap.shopList?.path,
            permittedRouteMap.team?.path,
            permittedRouteMap.accountSettings?.path,
          ].some(path => pathname.startsWith(path)) && (
            <div className='arrow-button'>
              <TooltipBackToAccountListStyled value='Back To My Account' position='bottom right'>
                <Icon
                  name={AvailableIcons.BackSmall}
                  className='arrow-button-icon'
                  onClick={() => handleNavigate(permittedRouteMap.shopList?.path)}
                />
              </TooltipBackToAccountListStyled>
              <span className='arrow-button-divider' />
            </div>
          )}
          {(logoRoute && (
            <TooltipAccountListStyled value='Account List' position='bottom center'>
              <div
                onClick={() => handleNavigate(logoRoute.path)}
                className='syte-side-navigation-logo'
              >
                <img src={syteIcon} alt='SyteIcon' />
              </div>
            </TooltipAccountListStyled>
          )) || <img src={syteIcon} alt='SyteIcon' />}

          <div className='account-info'>
            <Typography
              variant={TypographyVariant.LargeMedium}
              type={TypographyType.Body}
              className='account-info-name'
            >
              {accountName || 'Please, select account'}
            </Typography>
          </div>
          <EnvironmentBadge />
        </div>
      </div>

      <div className='syte-app-header-section-right'>
        {internalFeatureIndicator}
        {runningExperimentsIndicator}
        {visualEditor}
        {isShopLoading ? (
          <div style={{ padding: '0 16px 16px 16px' }}>
            <Skeleton variant='rounded' height='40px' width='100%' />
          </div>
        ) : (
          shopSelector
        )}
        <span className='syte-app-header-divider' />
        {isSyteAdmin && (
          <AdminSearchTriggerStyled
            name={AvailableIcons.Search}
            onClick={() => setShowAdminModal(true)}
          />
        )}
        <TooltipSupportStyled value='Support' position='bottom center'>
          <ContactSupportIconStyled
            name={AvailableIcons.TooltipQuestionmarkBlue}
            onClick={() => window.open('https://support.syte.ai', '_blank')}
          />
        </TooltipSupportStyled>

        <AppHeaderItem
          componentName={AppHeaderComponentName.NotificationsIndication}
          currentUrl={pathname}
        >
          <NotificationsContainer
            shopId={selectedShop?.shopId}
            permittedRouteMap={permittedRouteMap}
          />
        </AppHeaderItem>

        <UserProfileAndSupportDivider />

        {userAvatarList}
      </div>
    </Header>
  );
};
