import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { SynonymLanguage } from 'src/services/src/service/augmented-search/synonyms/types';
import { RoutedComponentProps } from 'src/app-routes';
import { Dispatch, synonymsActions } from 'src/components-bl';
import { useAppSelector } from 'src/hooks';
import { SynonymFormModal } from 'src/components-bl';

interface EditSynonymContainerProps extends RoutedComponentProps {
  shopId: number;
  language: SynonymLanguage;
  isReady: boolean;
  hasAugmentedSearchCatalog: boolean | null;
  dispatch: Dispatch;
}

export const EditSynonymContainer = ({
  shopId,
  language,
  permittedRouteMap,
  hasAugmentedSearchCatalog,
  isReady,
  dispatch,
}: EditSynonymContainerProps): JSX.Element => {
  const { synonymId } = useParams<{ synonymId: string }>();
  const selectedSynonym = useAppSelector(
    state => state.augmentedSearch.synonymsData?.selectedSynonym
  );

  useEffect(() => {
    if (synonymId) {
      dispatch(synonymsActions.getSynonymById({ shopId, synonymId, language }));
    }
  }, [dispatch, shopId, synonymId, language]);

  return (
    <>
      {selectedSynonym ? (
        <SynonymFormModal
          shopId={shopId}
          language={language}
          hasAugmentedSearchCatalog={hasAugmentedSearchCatalog}
          isReady={isReady}
          dispatch={dispatch}
          synonymId={synonymId}
          selectedSynonym={selectedSynonym}
          permittedRouteMap={permittedRouteMap}
        />
      ) : null}
    </>
  );
};
