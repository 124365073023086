import React from 'react';
import { RoutedComponentProps } from 'src/app-routes';
import { ShopFeature } from 'src/app-types';
import { ProductsSection } from './components/ProductsSection/ProductsSection';
import { Dispatch } from 'src/components-dummy/types';
import {
  LastUserLocation,
  LastUserLocationProps,
} from './components/LastUserLocation/LastUserLocation';
import { LinkedinSection } from './components/LinkedinSection/LinkedinSection';
import { WrapperSection } from './Home.styles';
import { Insights } from '../Insights/Insights';
import { SectionWithTitle } from './components/SectionWithTitle/SectionWithTitle';
import { IInsightsShopMetadataAPIModel } from 'src/services';

export interface HomeContentProps extends RoutedComponentProps, LastUserLocationProps {
  shopId: number;
  enabledShopFeatures: Set<ShopFeature>;
  insightsShopMetadata: IInsightsShopMetadataAPIModel;
  isUserPermittedForInsights: boolean;
  dispatch: Dispatch;
}

export const HomeContent = ({
  shopId,
  permittedRouteMap,
  dispatch,
  lastVisitedURL,
  isUserPermittedForInsights,
}: HomeContentProps): JSX.Element => {
  return (
    <>
      <LastUserLocation
        lastVisitedURL={lastVisitedURL}
        dispatch={dispatch}
        permittedRouteMap={permittedRouteMap}
      />
      <WrapperSection>
        {isUserPermittedForInsights && (
          <Insights key={shopId} shopId={shopId} dispatch={dispatch} />
        )}
        <SectionWithTitle title='Quick Links'>
          <ProductsSection
            shopId={shopId}
            permittedRouteMap={permittedRouteMap}
            dispatch={dispatch}
          />
        </SectionWithTitle>
        <LinkedinSection />
      </WrapperSection>
    </>
  );
};
