import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Collection } from '../../../Collections';
import {
  AvailableIcons,
  Icon,
  MenuItem,
  Select,
  SelectType,
  Typography,
  TypographyType,
  TypographyVariant,
} from '../../../../components-dummy';
import { AutoCompleteForm, useAutoCompleteFetchState } from '../AutoComplete';
import { ProductCard } from '../ProductCard';

import { SuggestedOffer } from '../../types';
import { FormGroup } from '../Form';
import { CollectionLink } from './CollectionLink';
import { useCollectionSettings } from '../../hooks';
import { StateController } from '../SideMenu/ShopperExperience/useStateControllerRef';
import { ExternalLink } from '../ExternalLink';
import { useNavigateToVisualEditorRoute } from '../../hooks/useNavigateToVisualEditorRoute';
import { PermittedRouteMap } from '../../../../app-routes';
import { PreviewFeature } from '../../../../services';

export interface CollectionSettingsProps {
  shopId: number;
  collection?: Collection | null;
  offer: SuggestedOffer | null;
  onOfferChange: (offer: SuggestedOffer | null) => void;
  stateController: StateController<string>;
  selectedExperience?: PreviewFeature;
  permittedRouteMap: PermittedRouteMap;
  openProductDetailsModal: (sku?: string) => void;
}

export const CollectionsSelectContainer = styled.div`
  position: relative;
`;

export const CollectionsSelectHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

function NavigateToCollection({ onClick }: { onClick: VoidFunction }) {
  return (
    <CollectionLink onClick={onClick}>
      <span>Go to collection</span>
      <Icon name={AvailableIcons.ExternalLink} />
    </CollectionLink>
  );
}

export function CollectionSettings({
  shopId,
  onOfferChange,
  offer,
  stateController: { value, setValue },
  selectedExperience,
  permittedRouteMap,
  openProductDetailsModal,
}: CollectionSettingsProps): JSX.Element {
  const {
    collection,
    collections,
    changeCollection,
    navigateToCollection,
    selectedCollectionName,
  } = useCollectionSettings(shopId, selectedExperience, permittedRouteMap);

  const { reset, ...autoCompleteProps } = useAutoCompleteFetchState({
    shopId,
    onOfferChange,
    selectedExperience,
    initialValue: value,
    permittedRouteMap,
    selectedCollectionName,
  });

  const { navigateToVisualEditorRoute: navigateToEditCollection } = useNavigateToVisualEditorRoute({
    route: permittedRouteMap?.visualEditorEditCollectionRules,
  });

  const { navigateToVisualEditorRoute: navigateToCreateCollection } =
    useNavigateToVisualEditorRoute({
      route: permittedRouteMap?.visualEditorCreateCollectionRules,
    });

  useEffect(() => {
    setValue(autoCompleteProps.selectedValue);
  }, [autoCompleteProps.selectedValue]);

  return (
    <>
      <FormGroup>
        <CollectionsSelectHeader>
          <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
            Select Collection
          </Typography>
          {selectedCollectionName && collection ? (
            <ExternalLink
              onClick={() => navigateToEditCollection({ shopId, collectionId: collection.id })}
            >
              Edit Collection
            </ExternalLink>
          ) : (
            <ExternalLink onClick={() => navigateToCreateCollection({ shopId })}>
              Create New Collection
            </ExternalLink>
          )}
        </CollectionsSelectHeader>
        <CollectionsSelectContainer>
          <Select
            type={SelectType.Menu}
            value={selectedCollectionName}
            disabled={!collections.length}
            placeholder={collections.length ? 'Select...' : 'No active collection'}
            onChange={e => {
              changeCollection(e.target.value);
            }}
          >
            {collections.map(item => (
              <MenuItem key={item.id} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
          {!!selectedCollectionName && <NavigateToCollection onClick={navigateToCollection} />}
        </CollectionsSelectContainer>
      </FormGroup>
      {!!selectedCollectionName && (
        <AutoCompleteForm
          title='(Optional) When shopper is viewing product...'
          placeholder='Search by description, brand, sku...'
          openPopUp
          {...autoCompleteProps}
        />
      )}
      {!!offer && (
        <ProductCard
          key={offer.sku}
          offer={offer}
          onClose={reset}
          openProductDetailsModal={openProductDetailsModal}
        />
      )}
    </>
  );
}
