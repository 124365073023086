import styled from '@emotion/styled';
import { Icon } from 'src/components-dummy';
import { colors } from 'src/styles';

export const LanguageCardContentStyled = styled.div<{ coloredCard?: boolean; color?: string }>`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 24px;
  font-size: 48px;
  width: ${({ coloredCard }) => (coloredCard ? '271px' : 'auto')};
  height: ${({ coloredCard }) => (coloredCard ? '110px' : 'auto')};
  line-height: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ coloredCard, color }) => (coloredCard ? color || 'transparent' : 'transparent')};
`;

export const IconStyled = styled(Icon)`
  width: 40px;
  transition-duration: 200ms;
`;

// TODO: box-shadow variables from tailwind
export const LanguageCardContainerStyled = styled.div<{ coloredCard?: boolean }>`
  transition-property: text-decoration-color, box-shadow;
  transition-duration: 200ms;
  border-radius: 8px;
  width: 271px;
  height: ${({ coloredCard }) => (coloredCard ? '166px' : '56px')};
  background-color: white;

  border: solid 1px ${colors['gray-60']};
  flex-grow: 0;
  flex-direction: ${({ coloredCard }) => (coloredCard ? 'column' : 'row')};
  padding: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex: none;
  align-items: center;
  text-decoration-color: transparent;

  &:hover {
    text-decoration-color: unset;
    text-decoration-line: underline;
    box-shadow:
      0px 4px 8px 3px rgba(0, 0, 0, 0.08),
      0px 1px 3px rgba(0, 0, 0, 0.12);
    svg {
      width: 55px;
    }
  }
`;

export const LanguageCardFooterStyled = styled.div<{ coloredCard?: boolean }>`
  font-weight: bold;
  font-size: 14px;
  line-height: 28px;
  width: 100%;
  height: ${({ coloredCard }) => (coloredCard ? '16px' : 'auto')};
  padding-left: ${({ coloredCard }) => (coloredCard ? '12px' : 'auto')};
  padding-top: ${({ coloredCard }) => (coloredCard ? '18px' : 'auto')};
  color: black;
`;

export const LanguageListLayoutStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 16px;
  width: fit-content;
  padding: 0;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

export const cardColors = [
  '#F58F29',
  '#B1EDE8',
  '#23D160',
  '#5438DC',
  '#F3B61F',
  '#E84855',
  '#2C80FF',
];
