import { Action, Dispatch, Store } from 'redux';
import { Auth } from 'src/services';
import {
  UserListActionTypes,
  ProfileMenuActionTypes,
  RoleBasedSideNavActionTypes,
  ExperimentCardActionTypes,
  VariantsTableActionTypes,
  VariantPanelActionTypes,
  BoughtTogetherActionTypes,
  PersonalizationActionTypes,
  RecEnginesActionTypes,
  SimilarItemsActionTypes,
  ShopTheLookActionTypes,
  RecentlyViewedActionTypes,
  DiscoveryBannerActionTypes,
  CollectionNavigationActionTypes,
  LexiconCategoryEditableTextActionTypes,
  deepTagReportsListItemActions,
  deepTagReportHowToUploadActions,
  deepTagsReportProductPageHeaderActions,
  lexiconPageHeaderActions,
  shopSelectorActions,
  RankingStrategyActionTypes,
  lexiconTableActions,
  catalogsListPageActions,
  notificationCardActions,
  versionsTableActions,
  shopCreationFormActions,
} from 'src/components-bl';
import {
  ShopSettingsContainerActionTypes,
  AppHeaderActionTypes,
  EditExperimentContainerActionTypes,
  FiltersSetsNavigationActionTypes,
  StoriesContainerActionTypes,
  UseValidateLocaleActionTypes,
  deepTagReportsContainerActions,
  deepTagReportSFTPSettingsModalContainerActions,
  deepTagReportProductsContainerActions,
  shopRoutesActions,
  augmentedSearchContainerActions,
} from 'src/containers';
import { navigationActions } from 'src/middleware-actions';
import { DeepTagReportProductListActions } from 'src/components-bl/DeepTagReports/components/DeepTagReportProductsList/Actions/DeepTagReportProductListActions';
import { deepTagsProductContainerActions } from 'src/containers/DeepTagReports/components/DeepTagsProductContainer/Actions';
import { MerchandisingRulesListActions } from 'src/components-bl/MerchandisingRules/components/MerchandisingRulesListPage/components/MerchandisingRulesList/Actions/MerchandisingRulesList.actions';
import { deepTagReportFileUploadModalContainerActions } from 'src/containers/DeepTagReports/components/DeepTagReportFileUploadModalContainer/DeepTagReportFileUploadModalContainer.actions';
import { rootContainerActions } from 'src/root-container/Actions';
import { adminSearchModalActions } from 'src/components-bl/AdminSearchModal/AdminSearchModal.actions';
import { createMerchandisingRuleContainerActions } from 'src/containers/MerchandisingRules/CreateMerchandisingRuleContainer';
import { editMerchandisingRuleContainerActions } from 'src/containers/MerchandisingRules/EditMerchandisingRuleContainer';
import { connectExistingCatalogModalContainerActions } from 'src/containers/CatalogManager/components/ConnectExistingCatalogModalContainer/ConnectExistingCatalogModalContainer.actions';
import { merchandisingRulesListContainerActions } from 'src/containers/MerchandisingRules/MerchandisingRulesListContainer';
import { merchandisingRulesManagementActions } from 'src/components-bl/MerchandisingRules/components/MerchandisingRulesManagement/MerchandisingRulesManagement.actions';
import { editExistingCatalogModalContainerActions } from 'src/containers/CatalogManager/components/EditExistingCatalogModalContainer/EditExistingCatalogModalContainer.actions';
import { isAnyOf } from '@reduxjs/toolkit';
import { shopListActions } from 'src/components-bl/ShopList/Actions';
import { shopPageNotFoundContainerActions } from 'src/containers/ShopPageNotFoundContainer/ShopPageNotFoundContainer.actions';
import { GlobalReducerState } from 'src/app-state-types';
import { catalogFormContainerActions } from 'src/containers/CatalogManager/CatalogFormContainer';
import { lexiconListActions } from 'src/components-bl/Lexicon/components/LexiconList/Actions/LexiconListActions';
import { lexiconsContainerActions } from 'src/containers/Lexicon/components/LexiconsContainer/LexiconsContainer.actions';
import {
  createThematicTagContainerActions,
  editThematicTagContainerActions,
  createLexiconRuleContainerActions,
  importLexiconContainerActions,
  exportLexiconContainerActions,
} from 'src/containers/Lexicon';
import { apiKeysSettingsActions } from 'src/components-bl/ApiKeysSettings/Actions/ApiKeysSettingsActions';
import { shopApiKeysSettingsModalContainerActions } from 'src/containers/ShopApiKeysSettings/Actions/ShopApiKeysSettingsModalContainerActions';
import { shopperExperiencesHeaderActions } from 'src/components-bl/ShopperExperiences';
import { editFilterContainerActions, filtersContainerActions } from 'src/containers/Filters';
import { filtersListPageActions, createFilterPageActions } from 'src/components-bl/Filters';
import {
  galleryBreadCrumbsActions,
  galleryFormActions,
  galleriesTablePageActions,
  editCustomInspirationsGalleryPageActions,
  addImagesModalFormActions,
  tagImageModalActions,
} from 'src/components-bl/Galleries';
import { collectionBreadCrumbsActions } from 'src/components-bl/Collections/CollectionBreadCrumbs';
import { createGalleryFormActions } from 'src/components-bl/Galleries/CreateGalleryModalForm/Actions';
import { lexiconTablePageActions } from 'src/components-bl/Lexicon/components/LexiconsTablePage/LexiconTablePage.actions';
import { editLexiconRuleContainerActions } from 'src/containers/Lexicon/components/EditLexiconRuleContainer/EditLexiconRuleContainer.actions';
import { navigateToShopHandler } from './navigate-to-shop-handler';
import { UseAppNavigationActionTypes } from '../../hooks/useAppNavigation';
import { editFilterPageActions } from '../../components-bl/Filters/components/EditFilterPage/Actions';
import { shopListContainerActions } from 'src/containers/ShopListContainer/ShopListContainer.actions';
import { productsSectionActions } from 'src/components-bl/Home/components/ProductsSection/ProductsSection.actions';
import { lastUserLocationActions } from 'src/components-bl/Home/components/LastUserLocation/LastUserLocation.actions';
import { synonymsActions } from 'src/components-bl/AugmentedSearch/Synonyms/SynonymsActions';

const actionsForConfirmOnNavigation: Set<string> = new Set([
  'SideNav/NavigateTo',
  navigationActions.navigateToShop.type,
  RankingStrategyActionTypes.SetSelectedSyteProduct,
  ShopSettingsContainerActionTypes.NavigateTo,
  AppHeaderActionTypes.NavigateTo,
  ProfileMenuActionTypes.NavigateTo,
  RoleBasedSideNavActionTypes.NavigateTo,
  ExperimentCardActionTypes.NavigateTo,
  EditExperimentContainerActionTypes.NavigateTo,
  EditExperimentContainerActionTypes.CloseVariantPanel,
  FiltersSetsNavigationActionTypes.NavigateTo,
  BoughtTogetherActionTypes.NavigateTo,
  PersonalizationActionTypes.NavigateTo,
  RecEnginesActionTypes.NavigateTo,
  SimilarItemsActionTypes.NavigateTo,
  ShopTheLookActionTypes.NavigateTo,
  DiscoveryBannerActionTypes.NavigateTo,
  StoriesContainerActionTypes.NavigateTo,
  RecentlyViewedActionTypes.NavigateTo,
  CollectionNavigationActionTypes.NavigateTo,
  synonymsActions.navigateTo.type,
  lexiconListActions.navigateTo.type,
  lexiconsContainerActions.navigateTo.type,
  createThematicTagContainerActions.navigateTo.type,
  createLexiconRuleContainerActions.navigateTo.type,
  editLexiconRuleContainerActions.navigateTo.type,
  editThematicTagContainerActions.navigateTo.type,
  importLexiconContainerActions.navigateTo.type,
  exportLexiconContainerActions.navigateTo.type,
  lexiconTableActions.navigateTo.type,
  UseValidateLocaleActionTypes.NavigateTo,
  LexiconCategoryEditableTextActionTypes.NavigateTo,
  deepTagReportsListItemActions.navigateTo.type,
  DeepTagReportProductListActions.navigateTo.type,
  deepTagsProductContainerActions.navigateTo.type,
  deepTagReportsContainerActions.navigateTo.type,
  deepTagReportSFTPSettingsModalContainerActions.navigateTo.type,
  deepTagReportFileUploadModalContainerActions.navigateTo.type,
  deepTagReportHowToUploadActions.navigateTo.type,
  deepTagReportProductsContainerActions.navigateTo.type,
  deepTagsReportProductPageHeaderActions.navigateTo.type,
  shopRoutesActions.navigateTo.type,
  rootContainerActions.navigateTo.type,
  adminSearchModalActions.navigateToAccount.fulfilled.type,
  adminSearchModalActions.navigateToUser.fulfilled.type,
  adminSearchModalActions.navigateTo.type,
  merchandisingRulesListContainerActions.navigateTo.type,
  createMerchandisingRuleContainerActions.navigateTo.type,
  editMerchandisingRuleContainerActions.navigateTo.type,
  MerchandisingRulesListActions.navigateToRule.type,
  lexiconPageHeaderActions.navigateTo.type,
  merchandisingRulesManagementActions.changePage.type,
  VariantsTableActionTypes.SelectVariant,
  VariantPanelActionTypes.SetSelectedVariantFeature,
  UserListActionTypes.UserSelected,
  shopPageNotFoundContainerActions.navigateTo.type,
  catalogFormContainerActions.navigateTo.type,
  catalogsListPageActions.navigateTo.type,
  connectExistingCatalogModalContainerActions.navigateTo.type,
  editExistingCatalogModalContainerActions.navigateTo.type,
  UseAppNavigationActionTypes.NavigateTo,
  apiKeysSettingsActions.navigateTo.type,
  shopApiKeysSettingsModalContainerActions.navigateTo.type,
  shopperExperiencesHeaderActions.navigateTo.type,
  filtersContainerActions.navigateTo.type,
  filtersListPageActions.navigateTo.type,
  createFilterPageActions.navigateTo.type,
  editFilterContainerActions.navigateTo.type,
  editFilterPageActions.navigateTo.type,
  galleriesTablePageActions.navigateTo.type,
  galleryFormActions.navigateTo.type,
  galleryBreadCrumbsActions.navigateTo.type,
  createGalleryFormActions.navigateTo.type,
  editCustomInspirationsGalleryPageActions.navigateTo.type,
  addImagesModalFormActions.navigateTo.type,
  tagImageModalActions.navigateTo.type,
  collectionBreadCrumbsActions.navigateTo.type,
  notificationCardActions.navigateTo.type,
  versionsTableActions.navigateTo.type,
  lexiconTablePageActions.navigateTo.type,
  augmentedSearchContainerActions.navigateTo.type,
  shopListContainerActions.navigateTo.type,
  shopCreationFormActions.navigateTo.type,
  productsSectionActions.navigateTo.type,
  lastUserLocationActions.navigateTo.type,
]);

const authService = new Auth();

export const navigateMiddleware =
  (history: any) =>
  (store: Store) =>
  (next: Dispatch) =>
  async (action: { type: string; payload?: any }): Promise<void> => {
    const { isDirty } = store.getState().global as GlobalReducerState;

    // when pressing "leave" on confirm modal, continue dispatch original action (one of the navigateTo)
    // otherwise do nothing
    if (action.type === rootContainerActions.confirmNav.type) {
      next(action as Action);
      const { leave } = action.payload.confirm;
      if (leave) {
        store.dispatch(action.payload.confirm.action);
      } else {
        store.dispatch(navigationActions.confirmNav(undefined));
      }
      return;
    }

    if (action.type === ProfileMenuActionTypes.LogoutRequest) {
      if (isDirty) {
        store.dispatch(navigationActions.confirmNav(action as Action));
      } else {
        try {
          store.dispatch(navigationActions.logoutRequest());
          await authService.logout();
          store.dispatch(navigationActions.logoutSuccess());
        } catch (ex) {
          store.dispatch(navigationActions.logoutError(ex));
        }
      }
    }

    // show confirm navigation modal if globally a form is dirty.
    // otherwise just push to route
    if (actionsForConfirmOnNavigation.has(action.type)) {
      if (isDirty) {
        store.dispatch(navigationActions.confirmNav(action as Action));
      } else {
        if (action.payload?.navigateTo) {
          history.push(action.payload.navigateTo);
        }
        next(action as any);
      }
    } else {
      next(action as any);
    }

    // For actions that navigate to shops.
    // Handles replacing current url with new shopId params. also uses fallback (ranking)
    if (
      isAnyOf(
        adminSearchModalActions.navigateToShop,
        shopSelectorActions.navigateToShop,
        shopListActions.navigateToShop,
        shopCreationFormActions.navigateToShop
      )(action)
    ) {
      navigateToShopHandler({ store, action });
    }
  };
