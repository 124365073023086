import styled from '@emotion/styled';
import { Button, TagsInput, Typography } from 'src/components-dummy';

export const ButtonStyled = styled(Button)`
  margin-left: 5px;
`;

export const InfoTextContainerStyled = styled.div`
  margin-bottom: 16px;
`;

export const InfoTextStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
`;

export const InputLabelStyled = styled(Typography)`
  margin: 6px 0;
`;

export const TagsInputStyled = styled(TagsInput)`
  min-height: 50px;
  height: unset;
  max-height: 200px;
  padding: 10px;

  &:focus,
  &:focus-within,
  &:hover {
    border-color: ${({ theme }) => theme.palette.custom['primary-main']} !important;
  }
`;

export const ErrorContainer = styled.div`
  min-height: 60px;
  display: flex;
  flex-direction: column;
`;

export const ErrorLabelStyled = styled(Typography)`
  margin-top: 6px;
  height: 14px;
  color: ${({ theme }) => theme.palette.custom.red};
`;
