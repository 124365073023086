export function formatDateToRelative(date: string | Date): string {
  const inputDate = new Date(date);
  if (isNaN(inputDate.getTime())) {
    return 'Invalid date';
  }

  const now = new Date();
  const diffInMs = now.getTime() - inputDate.getTime();
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

  if (diffInDays < 1) {
    return 'Today';
  } else if (diffInDays === 1) {
    return 'Yesterday';
  } else if (diffInDays < 7) {
    return `${diffInDays} days ago`;
  } else {
    const options: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric' };
    return inputDate.toLocaleDateString(undefined, options);
  }
}
