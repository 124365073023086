import React, { useRef, useState } from 'react';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  SearchableDropdownWithTriggerStyled,
  EllipsisWithTooltipWrapperStyled,
  ExpandMoreIconWrapperStyled,
  PlaceholderStyled,
  DropdownStyled,
} from './SearchableDropdownWithTrigger.styles';
import { useOnClickOutside } from 'src/hooks/useOnClickOutside';
import { SearchableDropdownMenu } from './SearchableDropdownMenu';
import { EllipsisWithTooltip, TypographyType, TypographyVariant } from 'src/components-dummy';

interface DropDownMenuWithSearchProps {
  value: string;
  options: { value: string; text: string }[];
  placeholder?: string;
  staticWidthInPixel?: number;
  disabled?: boolean;
  onChange: (selectedValue: string) => void;
}

export const SearchableDropdownWithTrigger = ({
  options,
  value,
  staticWidthInPixel,
  placeholder = 'Select...',
  disabled,
  onChange,
}: DropDownMenuWithSearchProps) => {
  const [isOpened, setIsOpened] = useState(false);
  const [containerRef, setContainerRef] = React.useState<HTMLDivElement | null>(null);
  const innerRef = useRef(null);

  useOnClickOutside(innerRef, () => {
    setIsOpened(false);
  });

  return (
    <>
      <SearchableDropdownWithTriggerStyled
        ref={setContainerRef}
        className={`dropdown-with-search ${isOpened ? 'opened' : 'closed'}`}
      >
        <SearchableDropdownMenu
          options={options}
          innerRef={innerRef}
          currentValue={value}
          staticWidthInPixels={staticWidthInPixel}
          setTriggerComponent={(
            onAnchorElementSet: (element: HTMLDivElement) => void
          ): JSX.Element => {
            const setAnchorRef = () => onAnchorElementSet(containerRef as HTMLDivElement);

            const onClick = () => {
              setAnchorRef();
              setIsOpened(prevState => !prevState);
            };

            const selectedValue = options.find(option => option.value === value)?.text;

            return (
              <DropdownStyled
                onClick={disabled ? undefined : onClick}
                isOpened={isOpened}
                className='drop-down-select'
              >
                {selectedValue ? (
                  <EllipsisWithTooltipWrapperStyled>
                    <EllipsisWithTooltip tooltipPosition='top center' tooltipText={selectedValue}>
                      {selectedValue}
                    </EllipsisWithTooltip>
                  </EllipsisWithTooltipWrapperStyled>
                ) : (
                  <PlaceholderStyled
                    variant={TypographyVariant.MediumRegular}
                    type={TypographyType.Body}
                  >
                    {placeholder}
                  </PlaceholderStyled>
                )}
                <ExpandMoreIconWrapperStyled isDown={isOpened}>
                  <ExpandMoreIcon className='select-caret' />
                </ExpandMoreIconWrapperStyled>
              </DropdownStyled>
            );
          }}
          onChange={onChange}
          isMenuSelectEnabled={true}
        ></SearchableDropdownMenu>
      </SearchableDropdownWithTriggerStyled>
    </>
  );
};
