export interface DiscoveryBannerSettingsReducerState {
  general: {
    bannerLayout: BannerLayout;
    imageLayout: ImageLayout;
    bannerBackgroundColor: string;
  };
  banner: {
    bannerColor: string;
    bannerFontFamily: string;
    bannerFontSize: number;
  };
  button: {
    buttonBackgroundColor: string;
    buttonColor: string;
    buttonFontFamily: string;
    buttonFontSize: number;
    icon: IconConfig;
  };
  icon: IconConfig;
  mobileGrid: GridConfig;
  desktopGrid: GridConfig;
  isSameGrid: boolean;
  strategy: BannerStrategy;
  shouldRefetch: boolean;
}

export enum ImageLayout {
  MIXED_SIZES = 'Mixed Sizes',
  GRID = 'Grid',
  OVERLAP = 'Overlapping',
  NONE = 'None',
}

export enum BannerLayout {
  RIGHT_TO_LEFT = 'Right To Left',
  LEFT_TO_RIGHT = 'Left To Right',
  TOP_TO_BOTTOM = 'Top To Bottom',
  BOTTOM_TO_TOP = 'Bottom To Top',
}

interface IconConfig {
  showIcon: boolean;
  iconColor: string;
}

export const defaultIconConfig: IconConfig = {
  showIcon: true,
  iconColor: '#000',
};

interface GridConfig {
  columns: number;
  rows: number;
}

export const defaultGridConfig: GridConfig = {
  columns: 2,
  rows: 2,
};

export enum BannerStrategy {
  Similars = 'Similars',
  CTL = 'CTL',
}

export const StrategyOptions = {
  [BannerStrategy.Similars]: 'Similar items',
  [BannerStrategy.CTL]: 'Complete the look',
};
