import React, { useCallback, useMemo } from 'react';
import { isEqual } from 'lodash';
import {
  ActionIcon,
  AutoCompleteSingleValue,
  AvailableIcons,
  TypographyType,
  TypographyVariant,
} from '../../../../../../components-dummy';
import {
  dataFieldsToPartialSubRule,
  partialSubRuleToDataFields,
} from '../../../DataFieldsCondition/DataFieldsCondition.helpers';
import { Option } from '../../../../../VisualEditor/components/AutoComplete';
import { useFetchAutoSuggestResults } from '../../../../../VisualEditor/components/BrowsePLP/useFetchAutoSuggestResults';
import {
  DataFieldConditionDataInnerType,
  DataFieldConditionDataType,
} from '../../../DataFieldsCondition/DataFieldsCondition.types';
import { CategoryFilterRule } from '../../../../../VisualEditor/types/category-filter-rule';
import { MerchandisingRuleTypes } from '../../../../../../services';
import { RuleDraftCondition } from '../../MerchandisingRuleForm.config';
import {
  AutoCompleteSingleValueStyled,
  ConditionRowDataStyled,
  TextBoxLabel,
} from './RuleFormConditionsSection.styles';

interface ConditionProps {
  shopId: number;
  condition: RuleDraftCondition;
  isError: boolean;
  onConditionChange: (changedCondition: DataFieldConditionDataType) => void;
  onDeleteCondition: (conditionToDelete: RuleDraftCondition) => void;
  isReadOnly?: boolean;
  categoryFilterRule?: CategoryFilterRule;
}

export const CategoryPageCondition = ({
  shopId,
  condition,
  isError,
  onConditionChange,
  onDeleteCondition,
  isReadOnly,
  categoryFilterRule,
}: ConditionProps): JSX.Element => {
  const conditionData = useMemo(() => partialSubRuleToDataFields(condition), [condition]);

  const onStateChange = useCallback(
    (partialData: Partial<DataFieldConditionDataInnerType>) => {
      const updatedState = { ...conditionData, ...partialData };
      if (!isEqual(conditionData, updatedState)) {
        onConditionChange?.(dataFieldsToPartialSubRule(updatedState));
      }
    },
    [conditionData, onConditionChange]
  );

  const onCategoryChange = useCallback(
    value => {
      onStateChange({
        conditionValue: [value],
        dataField: categoryFilterRule?.field || 'categories',
        conditionType:
          categoryFilterRule?.operator || MerchandisingRuleTypes.MerchandisingRuleSubType.Equals,
      });
    },
    [categoryFilterRule, onStateChange]
  );

  const [categoryPageValue] = conditionData.conditionValue as string[];

  const { options, onChange, onSelectOption, loading } = useFetchAutoSuggestResults({
    shopId,
    categoryFilterRule,
    changeCategory: onCategoryChange,
  });

  return (
    <ConditionRowDataStyled>
      <TextBoxLabel type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
        Category Page is
      </TextBoxLabel>
      <AutoCompleteSingleValueStyled
        placeholder='Select category page'
        selectedValue={categoryPageValue}
        onChange={onChange}
        options={options}
        onSelectOption={onSelectOption}
        openPopUp
        loading={loading}
        errored={isError}
      >
        <AutoCompleteSingleValue.Option>
          {props => <Option {...props} displayOptionTitleOnly />}
        </AutoCompleteSingleValue.Option>
      </AutoCompleteSingleValueStyled>
      {!isReadOnly && (
        <ActionIcon
          iconName={AvailableIcons.TrashCan}
          onClick={() => onDeleteCondition(condition)}
        />
      )}
    </ConditionRowDataStyled>
  );
};
