import React, { useCallback } from 'react';
import classNames from 'classnames';
import { startCase } from 'lodash';
import {
  Icon,
  AvailableIcons,
  Checkbox,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import './FeatureGroup.scss';
import { PreviewTypeEnum, DiffActionType } from 'src/app-types/enums/versioning';
import { DiffsCard, DiffsCardProps, DiffsCardChanges } from '../DiffsCard';

export interface FeatureGroupProps {
  title: string;
  totalChanges: number | undefined;
  iconName: AvailableIcons;
  className?: string;
  changedEntities: Array<Partial<DiffsCardProps>>;
  isFeatureGroupSelected: boolean;
  onSelectFeatureGroup: (selectionKey: string) => void;
  previewType: PreviewTypeEnum;
  readOnly: boolean;
  selectionKey: string;
  newValueCustomName?: string;
}

export const FeatureGroup = ({
  title,
  iconName,
  className,
  changedEntities,
  isFeatureGroupSelected,
  onSelectFeatureGroup,
  previewType,
  readOnly,
  selectionKey,
  newValueCustomName,
}: FeatureGroupProps): JSX.Element => {
  /* Temporary Removed! */
  // const changesText = Number.isInteger(totalChanges) ? ` (${totalChanges})` : '';
  const titleValue = `${startCase(title)}`;

  const diffCards = changedEntities.map(
    ({ title: cardTitle, actionType, changes, changedAt, changedBy }) => {
      const key = `${cardTitle}-${actionType}-${changedBy}`;

      return (
        <DiffsCard
          key={key}
          title={cardTitle as string}
          actionType={actionType as DiffActionType}
          changes={changes as DiffsCardChanges[]}
          changedAt={changedAt}
          changedBy={changedBy}
          previewType={previewType}
          newValueCustomName={newValueCustomName}
        />
      );
    }
  );

  const onCheckboxChanged = useCallback(() => {
    onSelectFeatureGroup(selectionKey);
  }, [selectionKey, onSelectFeatureGroup]);

  return (
    <div className={classNames('syte-feature-group', className)}>
      <div className='syte-feature-group-header'>
        {readOnly ? (
          <Icon name={iconName} className='syte-feature-group-header-icon' />
        ) : (
          <Checkbox
            className='syte-feature-group-header-checkbox'
            checked={isFeatureGroupSelected}
            onChange={onCheckboxChanged}
          />
        )}
        <Typography
          type={TypographyType.Body}
          variant={TypographyVariant.SmallRegular}
          className='syte-feature-group-header-title'
        >
          {titleValue}
        </Typography>
      </div>
      <div className='syte-feature-group-content'>{diffCards}</div>
    </div>
  );
};
