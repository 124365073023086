import Joi, { AnySchema } from 'joi';

const phraseWordValidationSchema = Joi.string().min(2).max(256).messages({
  'string.min': 'A word must contain at least 2 characters',
  'string.max': 'A word must contain at most 256 characters',
});

export const synonymValidationSchema: { phrase: AnySchema } = {
  phrase: Joi.array().items(phraseWordValidationSchema).min(2).max(32).required().messages({
    'array.min': 'At least two words are required',
    'array.max': 'The maximum number of words is 32',
  }),
};
