import { AxiosProgressEvent } from 'axios';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { augmentedSearchService } from 'src/services';
import {
  GetSynonymsArguments,
  SynonymLanguage,
  GetSynonymByIdArguments,
} from 'src/services/src/service/augmented-search/synonyms/types';
import { downloadResponseFile } from '../../../services/src/common/utils';
import { AppNotification } from '../../../app-types';

export const synonymsActions = {
  navigateTo: createAction<{ navigateTo: string }>('Synonyms/NavigateTo'),
  notifyIsDirty: createAction<{ isDirty: boolean }>('Synonyms/NotifyIsDirty'),
  resetSynonyms: createAction('Synonyms/ResetSynonyms'),
  resetSelectedSynonym: createAction('Synonyms/ResetSelectedSynonym'),
  getSynonyms: createAsyncThunk(
    'Synonyms/GetSynonyms',
    async (params: GetSynonymsArguments, { rejectWithValue }) => {
      try {
        const response = await augmentedSearchService.getSynonyms(params);
        return response;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  issueToastNotification:
    createAction<Pick<AppNotification, 'customMessage' | 'type'>>('Synonyms/CreateToast'),
  createSynonym: createAsyncThunk(
    'Synonyms/CreateSynonym',
    async (
      { shopId, language, phrase }: { shopId: number; language: SynonymLanguage; phrase: string[] },
      { rejectWithValue }
    ) => {
      try {
        const createdSynonym = await augmentedSearchService.createSynonym({
          shopId,
          language,
          phrase,
        });

        return createdSynonym;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  getSynonymById: createAsyncThunk(
    'Synonyms/GetSynonymById',
    async (params: GetSynonymByIdArguments, { rejectWithValue }) => {
      try {
        const response = await augmentedSearchService.getSynonymById(params);
        return response;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  deleteSynonym: createAsyncThunk(
    'Synonyms/DeleteSynonym',
    async (
      {
        shopId,
        language,
        synonymId,
      }: { shopId: number; language: SynonymLanguage; synonymId: string },
      { rejectWithValue }
    ) => {
      try {
        const deletedSynonym = await augmentedSearchService.deleteSynonym({
          shopId,
          language,
          synonymId,
        });

        return deletedSynonym;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  importSynonyms: createAsyncThunk(
    'Synonyms/ImportSynonyms',
    async (
      {
        data,
        cancellationSignal,
        onUploadProgress,
      }: {
        data: {
          shopId: number;
          language: SynonymLanguage;
          synonymsFile: File;
        };
        cancellationSignal?: AbortSignal;
        onUploadProgress: (progressEvent: AxiosProgressEvent) => void;
      },
      { rejectWithValue }
    ) => {
      try {
        const response = await augmentedSearchService.importSynonyms({
          data,
          cancellationSignal,
          onUploadProgress,
        });
        const defaultName = `synonyms_results_${data.shopId}-${data.language}-${new Date().toDateString()}.csv`;

        downloadResponseFile({ response, defaultName });
        return response;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  exportSynonyms: createAsyncThunk(
    'Synonyms/ExportSynonyms',
    async (
      {
        shopId,
        language,
      }: {
        shopId: number;
        language: SynonymLanguage;
      },
      { rejectWithValue }
    ) => {
      try {
        await augmentedSearchService.exportSynonyms({
          shopId,
          language,
        });
        return {};
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  editSynonym: createAsyncThunk(
    'Synonyms/EditSynonym',
    async (
      {
        shopId,
        language,
        synonymId,
        phrase,
      }: { shopId: number; language: SynonymLanguage; synonymId: string; phrase: string[] },
      { rejectWithValue }
    ) => {
      try {
        const editSynonym = await augmentedSearchService.editSynonym({
          shopId,
          synonymId,
          language,
          phrase,
        });

        return editSynonym;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
