import styled from '@emotion/styled';
import { Skeleton, TableV2 } from 'src/components-dummy';
import {
  SynonymStatus,
  SynonymType,
} from 'src/services/src/service/augmented-search/synonyms/types';

const getStatusColor = ({
  deletedColor,
  isDeleted,
  statusColor,
}: {
  deletedColor: string;
  isDeleted: boolean;
  statusColor: string;
}) => (isDeleted ? deletedColor : statusColor);

export const StyledCellText = styled.span<{ backgroundColorToValue: string; isDeleted: boolean }>`
  background-color: ${({ theme, isDeleted, backgroundColorToValue }) => {
    const deletedColor = theme.palette.custom['red-90'];
    switch (backgroundColorToValue) {
      case SynonymStatus.Indexed:
        return getStatusColor({
          deletedColor,
          isDeleted,
          statusColor: theme.palette.custom.lightGreen,
        });
      case SynonymStatus.PendingIndex:
        return getStatusColor({
          deletedColor,
          isDeleted,
          statusColor: theme.palette.custom.lightYellow,
        });
      case SynonymType.Custom:
      case SynonymType.Global:
        return theme.palette.custom.lightPurple;
      default:
        return 'transparent';
    }
  }};
  padding: 2px 8px;
  gap: 10px;
  border-radius: 4px;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;

  @media (max-height: 900px) {
    margin-bottom: 20px;
  }
`;

export const StyledSkeleton = styled(Skeleton)`
  margin: 10px;
`;

export const FiltersRowWrapperStyled = styled.div`
  margin-bottom: 16px;
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
  border-radius: 8px;
`;

export const SkeletonsWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 57px;
  gap: 15px;
  padding: 0 6px;
`;

export const StyledTooltipWrapper = styled.div`
  .syte-popup-trigger {
    display: inline-block;
  }
  width: 100%;
`;

export const BodyRowStyled = styled(TableV2.BodyRow)`
  position: relative;
`;

export const StrikeThroughStyled = styled.div`
  border-bottom: ${({ theme }) => theme.palette.custom['gray-20']} solid 1px;
  position: absolute;
  width: 100%;
`;

export const MissingAugmentedSearchCatalogCardWrapperStyled = styled.div`
  margin-top: 42px;
`;

export const TableWrapperStyled = styled.div`
  flex: 1;
  overflow-y: auto;
`;
