import { AnyAction, combineReducers, Reducer } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { pick } from 'lodash';
import { NavMidlTypes, NavMidlAction, toastMiddlewareActions } from './middleware-actions';
import {
  rankingStrategyReducer,
  feedsMetadataReducer,
  metaReducer,
  shopReducer,
  accountReducer,
  globalReducer,
  merchandisingRulesReducer,
  dataFieldsReducer,
  toastReducer,
  userListReducer,
  versioningReducer,
  experimentsReducer,
  boughtTogetherSettingsReducer,
  recentlyViewedSettingsReducer,
  personalizationSettingsReducer,
  filtersSetsReducer,
  shopTheLookSettingsReducer,
  recEnginesGeneralSettingsReducer,
  similarItemsSettingsReducer,
  enableUiTestReducer,
  discoveryBannerSettingsReducer,
  collectionsReducer,
  uiTemplatesReducer,
  lexiconReducer,
  deepTagReportsReducer,
  shopSFTPSettingsReducer,
  catalogManagerReducer,
  userLocationReducer,
  shopperExperiencesReducer,
  apiKeysReducer,
  aiModelsReducer,
  storiesSettingsReducer,
  resultsModalReducer,
  filtersReducer,
  visualEditorReducer,
  galleriesReducer,
  augmentedSearchReducer,
  notificationsReducer,
  useKnnReducer,
  insightsReducer,
} from './reducers';

export const prepareReducers = (history: History) => ({
  router: connectRouter(history),
  global: globalReducer,
  rankingStrategy: rankingStrategyReducer,
  feedsMetadata: feedsMetadataReducer,
  meta: metaReducer,
  shop: shopReducer,
  account: accountReducer,
  merchandisingRules: merchandisingRulesReducer,
  dataFields: dataFieldsReducer,
  toast: toastReducer,
  userList: userListReducer,
  versioning: versioningReducer,
  experiments: experimentsReducer,
  boughtTogetherSettings: boughtTogetherSettingsReducer,
  recentlyViewedSettings: recentlyViewedSettingsReducer,
  personalizationSettings: personalizationSettingsReducer,
  filtersSets: filtersSetsReducer,
  shopTheLookSettings: shopTheLookSettingsReducer,
  recEnginesGeneralSettings: recEnginesGeneralSettingsReducer,
  similarItemsSettings: similarItemsSettingsReducer,
  enableUiTest: enableUiTestReducer,
  discoveryBannerSettings: discoveryBannerSettingsReducer,
  collections: collectionsReducer,
  uiTemplates: uiTemplatesReducer,
  useKnn: useKnnReducer,
  lexicon: lexiconReducer,
  deepTagReports: deepTagReportsReducer,
  shopSFTPSettings: shopSFTPSettingsReducer,
  aiModel: aiModelsReducer,
  catalogManager: catalogManagerReducer,
  userLocation: userLocationReducer,
  shopperExperiences: shopperExperiencesReducer,
  storiesSettings: storiesSettingsReducer,
  apiKeys: apiKeysReducer,
  filters: filtersReducer,
  resultsModal: resultsModalReducer,
  visualEditor: visualEditorReducer,
  galleries: galleriesReducer,
  augmentedSearch: augmentedSearchReducer,
  notifications: notificationsReducer,
  insights: insightsReducer,
});

function resetReducer(
  reducer: Reducer,
  action: AnyAction,
  initialState: Record<string, unknown> | undefined
) {
  return reducer(initialState, action);
}

const NavMidlActions = new Set([
  NavMidlTypes.LogoutSuccess,
  toastMiddlewareActions.forceLogout.fulfilled.type,
]);

export const createRootReducer = (history: History): Reducer => {
  const appReducer = combineReducers(prepareReducers(history));

  type StateType = Parameters<typeof appReducer>[0];

  const rootReducer = (state: StateType, action: NavMidlAction) => {
    if (NavMidlActions.has(action.type)) {
      // Preserve public meta and current toasts
      const initialState: Partial<StateType> | undefined = pick(state, [
        'toast',
        'meta.public',
        'router',
      ]);

      return resetReducer(appReducer, action, initialState);
    }

    return appReducer(state, action);
  };

  return rootReducer;
};

export type AppReducers = ReturnType<typeof prepareReducers>;
