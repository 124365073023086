import React, { useCallback, useEffect, useState } from 'react';
import { generatePath } from 'react-router';
import { RoutedComponentProps } from 'src/app-routes';
import { AugmentedSearchReducerState } from 'src/app-state-types';
import { Dispatch } from 'src/components-bl/types';
import { SynonymLanguage } from 'src/services/src/service/augmented-search/synonyms/types';
import { AppliedFilters, getAppliedFiltersWithValue } from 'src/components-dummy';
import { SynonymsPageHeader } from './SynonymsTable/SynonymsTableHeader';
import { synonymsActions } from '../SynonymsActions';
import {
  MissingAugmentedSearchCatalogCardWrapperStyled,
  StyledSkeleton,
} from './SynonymsTablePage.styles';
import { SynonymsFilters, SynonymsFiltersState } from './SynonymsTable/SynonymsFilters';
import { SynonymsList } from './SynonymsTable/SynonymsList';
import { isEmpty, isEqual, startCase } from 'lodash';
import { columns } from './consts';
import { MissingAugmentedSearchCatalogCard } from '../../MissingAugmentedSearchCatalogCard';
import { ValidationErrorKey } from 'src/containers/ToastManager/ActionsNotifications/validation-error-key';

function LoadingSkeleton() {
  return (
    <>
      <StyledSkeleton height={40} variant='rounded' />
      <StyledSkeleton height={20} variant='rounded' />
      <StyledSkeleton height={20} variant='rounded' />
    </>
  );
}

function calculateAppliedFilters(filtersState: SynonymsFiltersState): {
  filters: AppliedFilters;
  isEmpty: boolean;
} {
  const filters = getAppliedFiltersWithValue(filtersState);

  return {
    filters,
    isEmpty: isEmpty(filters),
  };
}

export const initialFiltersState: SynonymsFiltersState = {
  searchTerm: {
    isApplied: true,
    value: undefined,
  },
};

interface SynonymsTableProps extends RoutedComponentProps {
  language: SynonymLanguage;
  shopId: number | undefined;
  dispatch: Dispatch;
  synonymsData?: AugmentedSearchReducerState['synonymsData'];
  hasAugmentedSearchCatalog: boolean | null;
  availableLanguageCodes: string[];
  isReady: boolean;
}

export function SynonymsTablePage({
  language,
  shopId,
  dispatch,
  synonymsData,
  availableLanguageCodes,
  permittedRouteMap,
  hasAugmentedSearchCatalog,
  isReady,
}: SynonymsTableProps): JSX.Element {
  const [filtersState, setFiltersState] = useState<SynonymsFiltersState>(initialFiltersState);
  const [isExportLoading, setIsisExportLoading] = useState<boolean>(false);
  useEffect(() => {
    setFiltersState(initialFiltersState);
  }, [shopId]);
  const [paginationState, setPaginationState] = useState<{ skip: number; limit: number }>({
    skip: 0,
    limit: 50,
  });
  const onPaginationChange = useCallback(({ skip, limit }: { skip: number; limit: number }) => {
    setPaginationState({ skip, limit });
  }, []);

  const resetSkip = useCallback(() => {
    setPaginationState({ skip: 0, limit: paginationState.limit });
  }, [setPaginationState, paginationState.limit]);

  const [appliedFiltersState, setAppliedFiltersState] = useState(
    calculateAppliedFilters(filtersState)
  );

  const navigateToLanguagePage = useCallback(() => {
    if (!permittedRouteMap.augmentedSearchSynonymsLanguagesList) {
      return;
    }

    dispatch(
      synonymsActions.navigateTo({
        navigateTo: generatePath(permittedRouteMap.augmentedSearchSynonymsLanguagesList.path, {
          shopId,
        }),
      })
    );
  }, [shopId, permittedRouteMap, dispatch]);

  const fetchSynonyms = useCallback(async () => {
    if (!SynonymLanguage[startCase(language)]) {
      navigateToLanguagePage();
      return;
    }

    const searchTerm = appliedFiltersState.filters.searchTerm as string | undefined;

    (
      dispatch(
        synonymsActions.getSynonyms({
          shopId,
          language,
          ...paginationState,
          ...appliedFiltersState.filters,
          searchTerm: searchTerm && searchTerm.length > 1 ? searchTerm : undefined,
        })
      ) as any
    )
      .unwrap()
      .catch(error => {
        const errorKey = error.error?.errorKey;

        if (errorKey === ValidationErrorKey.SynonymsLanguageNotEnabledInCatalog) {
          navigateToLanguagePage();
        }
      });
  }, [dispatch, shopId, language, paginationState, appliedFiltersState, navigateToLanguagePage]);

  useEffect(() => {
    if (synonymsData?.shouldFetch) {
      fetchSynonyms();
    }
  }, [synonymsData?.shouldFetch]);

  useEffect(() => {
    fetchSynonyms();
  }, [fetchSynonyms]);

  useEffect(() => {
    const newState = calculateAppliedFilters(filtersState);
    const filtersChanged = !isEqual(newState, appliedFiltersState);

    if (filtersChanged) {
      setAppliedFiltersState(newState);
      resetSkip();
    }
  }, [filtersState, appliedFiltersState, resetSkip]);

  useEffect(() => {
    return () => {
      dispatch(synonymsActions.resetSynonyms());
    };
  }, [dispatch]);

  const navigateToSynonymLanguage = useCallback(
    (navigateToLanguage: SynonymLanguage) => {
      if (!permittedRouteMap.augmentedSearchSynonymsLanguage) {
        return;
      }

      dispatch(
        synonymsActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.augmentedSearchSynonymsLanguage.path, {
            shopId,
            language: navigateToLanguage,
          }),
        })
      );
    },
    [shopId, permittedRouteMap, dispatch]
  );

  const navigateToCreateSynonym = useCallback(() => {
    if (!permittedRouteMap.augmentedSearchCreateSynonym) {
      return;
    }

    dispatch(
      synonymsActions.navigateTo({
        navigateTo: generatePath(permittedRouteMap.augmentedSearchCreateSynonym.path, {
          shopId,
          language,
        }),
      })
    );
  }, [shopId, language, permittedRouteMap, dispatch]);

  const navigateToEditSynonym = useCallback(
    (synonymId: string) => {
      if (!permittedRouteMap.augmentedSearchEditSynonym) {
        return;
      }

      dispatch(
        synonymsActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.augmentedSearchEditSynonym.path, {
            shopId,
            language,
            synonymId,
          }),
        })
      );
    },
    [shopId, language, permittedRouteMap, dispatch]
  );

  const onExportFile = useCallback(async () => {
    setIsisExportLoading(true);
    try {
      await (dispatch(synonymsActions.exportSynonyms({ shopId, language })) as any).unwrap();
    } catch (error) {
      console.error(error);
    } finally {
      setIsisExportLoading(false);
    }
  }, [shopId, language]);

  const onDeleteSynonym = useCallback(
    ({ synonymId }: { synonymId: string }) => {
      dispatch(synonymsActions.deleteSynonym({ shopId, synonymId, language }));
    },
    [shopId, language, dispatch]
  );

  if (!isReady) {
    return <LoadingSkeleton />;
  }

  if (!hasAugmentedSearchCatalog) {
    return (
      <MissingAugmentedSearchCatalogCardWrapperStyled>
        <MissingAugmentedSearchCatalogCard />
      </MissingAugmentedSearchCatalogCardWrapperStyled>
    );
  }

  return (
    <>
      <SynonymsPageHeader
        language={language}
        availableLanguageCodes={availableLanguageCodes}
        navigateToSynonymLanguage={navigateToSynonymLanguage}
        navigateToCreateSynonym={navigateToCreateSynonym}
        onExportFile={onExportFile}
        isExportLoading={isExportLoading}
      />
      <SynonymsFilters onChange={setFiltersState} filters={filtersState} />
      {isReady ? (
        <SynonymsList
          columns={columns}
          synonyms={synonymsData.synonyms}
          language={language}
          permittedRouteMap={permittedRouteMap}
          shopId={shopId}
          dispatch={dispatch}
          paginationState={paginationState}
          totalSynonymsCount={synonymsData.pagination.totalCount}
          onPaginationChange={onPaginationChange}
          onDeleteSynonym={onDeleteSynonym}
          navigateToEditSynonym={navigateToEditSynonym}
          filtersValue={filtersState}
        />
      ) : (
        <LoadingSkeleton />
      )}
    </>
  );
}
