import React, { useMemo } from 'react';
import classNames from 'classnames';
import { PreviewTypeEnum } from 'src/app-types/enums/versioning';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import { DiffsCardChangesTableProps } from '../types';
import { ChangedByTooltip } from './ChangedByTooltip';
import { SystemChangeIndicationIcon } from './SystemChangeIndicationIcon';

const TABLE_HEADER_CLASS = 'syte-diffs-card-changes-table-header';
const TABLE_CELL_CLASS = 'syte-diffs-card-changes-table-cell';

function getControlValueName(previewType: PreviewTypeEnum): string {
  switch (previewType) {
    case PreviewTypeEnum.REVIEW_VERSION:
      return 'Previous value';
    case PreviewTypeEnum.COMPARE_VARIANT:
      return 'Control group';
    default:
      return 'Published value';
  }
}

export const DiffsCardChangesTable = ({
  changes,
  previewType,
  newValueCustomName,
}: DiffsCardChangesTableProps): JSX.Element => {
  const tableRows = useMemo(() => {
    return changes.map(diff => (
      <tr className='syte-diffs-card-changes-table-row' key={diff.id}>
        <td className={classNames(TABLE_CELL_CLASS, 'syte-diffs-card-changes-table-cell-name')}>
          <SystemChangeIndicationIcon isSystemChange={diff.isSystemChange} />
          <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
            {diff.name}
          </Typography>
        </td>
        <td className={TABLE_CELL_CLASS}>{diff.oldValue}</td>
        <td className={`${TABLE_CELL_CLASS} syte-diffs-card-changes-table-cell-new-value`}>
          <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
            {diff.newValue}
          </Typography>
        </td>
        <td className='syte-diffs-card-changes-table-cell-changed-by'>
          <ChangedByTooltip changedBy={diff.changedBy} changedAt={diff.changedAt} />
        </td>
      </tr>
    ));
  }, changes);

  const controlValueName = getControlValueName(previewType);

  return (
    <table className='syte-diffs-card-changes-table'>
      <thead>
        <tr>
          <th className={`${TABLE_HEADER_CLASS} ${TABLE_CELL_CLASS}`}>
            <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
              Configuration
            </Typography>
          </th>
          <th className={`${TABLE_HEADER_CLASS} ${TABLE_CELL_CLASS}`}>
            <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
              {controlValueName}
            </Typography>
          </th>
          <th className={`${TABLE_HEADER_CLASS} ${TABLE_CELL_CLASS}`}>
            <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
              {newValueCustomName ?? 'New Value'}
            </Typography>
          </th>
          <th> </th>
        </tr>
      </thead>
      <tbody>{tableRows}</tbody>
    </table>
  );
};
