import React from 'react';
import { AvailableIcons, Button } from 'src/components-dummy';
import { SynonymLanguage } from 'src/services/src/service/augmented-search/synonyms/types';
import {
  SynonymsButtonsWrapperStyled,
  SynonymsPageHeaderStyled,
  SynonymsPageHeaderTitleContainerStyled,
  SynonymsPageHeaderTitleRowStyled,
} from './SynonymsTableHeader.styles';
import { LanguageSelectorDropdown } from '../LanguageSelectorDropdown/LanguageSelectorDropdown';

export interface SynonymsPageHeaderProps {
  language: SynonymLanguage;
  availableLanguageCodes: string[];
  navigateToSynonymLanguage: (language: SynonymLanguage) => void;
  navigateToCreateSynonym: () => void;
  onExportFile: () => void;
  isExportLoading: boolean;
}

export const SynonymsPageHeader = ({
  language,
  availableLanguageCodes,
  navigateToSynonymLanguage,
  navigateToCreateSynonym,
  onExportFile,
  isExportLoading,
}: SynonymsPageHeaderProps): JSX.Element => {
  return (
    <SynonymsPageHeaderStyled>
      <SynonymsPageHeaderTitleContainerStyled>
        <SynonymsPageHeaderTitleRowStyled>
          <LanguageSelectorDropdown
            language={language}
            availableLanguageCodes={availableLanguageCodes}
            navigateToSynonymLanguage={navigateToSynonymLanguage}
          />
        </SynonymsPageHeaderTitleRowStyled>
        <SynonymsButtonsWrapperStyled>
          <Button
            endIcon={AvailableIcons.Import}
            variant='secondary'
            onClick={onExportFile}
            loading={isExportLoading}
          >
            Export
          </Button>
          <Button variant='primary' onClick={navigateToCreateSynonym}>
            Add Synonyms
          </Button>
        </SynonymsButtonsWrapperStyled>
      </SynonymsPageHeaderTitleContainerStyled>
    </SynonymsPageHeaderStyled>
  );
};
