/**
 * Colors should be included directly in the theme custom section once the POC will be approved (and delete this file).
 */
export const colors = {
  'gray-10': '#6E6E71',
  'gray-20': '#878787',
  'gray-30': '#AFAFAF',
  'gray-50': '#C5C5C5',
  'gray-60': '#DCDCDC',
  'gray-70': '#EBEBEB',
  'gray-80': '#F2F2F2',
  'gray-90': '#F8F8F8',
  'gray-100': '#dcdcdc',
  'gray-110': '#979797',
  'gray-120': '#F8FBFF',
  'gray-disabled': 'rgba(0, 0, 0, 0.26)',
  'orange-10': '#f3b61f',
  'orange-20': '#FFC96B',
  'primary-80': '#CCE3FF',
  'primary-90': '#E5F1FF',
  'primary-main': '#0073FF',
  'primary-dark': '#005CCC',
  'primary-light': '#E5F1FF',
  'primary-light-transparent': 'rgba(229, 241, 255, 0.35)',
  purple: '#6e19b1',
  lightPurple: '#F4E9FF',
  red: '#DC3545',
  'red-10': '#a00614',
  'red-60': '#4F0E1F',
  'red-70': '#490B1C',
  'red-80': '#C42231',
  'red-90': '#FAE1E3',
  bordeaux: '#490B1C',
  'green-success': '#28A745',
  'green-10': '#D8F3F3',
  'green-20': '#66BB6A',
  'green-30': '#002F19',
  'green-40': '#92E6B5',
  lightGreen: '#E1F9E6',
  turquoise: '#38BCB9',
  yellow: '#F3B61F',
  'yellow-80': '#FFC96B',
  'yellow-100': '#E8A500',
  lightYellow: '#FFF0CA',
  white: '#FFFFFF',
  pink: '#BA68C8',
  'pink-10': '#FFC4B0',
  'pink-20': '#FDC9D0',
  success: '#28A745',
  'low-rank': '#F39B96',
  'medium-rank': '#FCBD65',
  'high-rank': '#79C977',
  tertiary: '#EEEEEE',
  'black-10': '#1E1E1E',
  magenta: '#f654c9',
  brown: '#3D2800',
} as const;
