import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/hooks';
import { shopApiKeysSettingsActions } from '../ShopApiKeysSettings';
import { Page, BackButtonTitle, AvailableIcons, Button } from 'src/components-dummy';
import { DiscoveryBannerSettings } from 'src/components-bl';
import { getOldestNonExpiredApiKey } from '../../components-bl/ApiKeysSettings';
import { useFetchSku } from './useFetchSku';
import { ShopRoutes } from '../../types/enums/shop-routes';
import { ButtonsSection } from './DiscoveryBannerContainer.styles';

const BASE_PREVIEW_URL = 'https://search.syte.ai/discoveryBanner.html';

export const DiscoveryBannerContainer = (): JSX.Element | null => {
  const formHeaderRef = useRef<HTMLDivElement>(null);

  const shopId = useAppSelector(state => state.shop.current?.shopId);
  const apiKeys = useAppSelector(state => state.apiKeys.apiKeys);
  const discoveryBannerSettings = useAppSelector(state => state.discoveryBannerSettings);
  const dispatch = useDispatch();
  const navigationPath = `/shops/${shopId}/${ShopRoutes.ShopperExperiences}`;

  const { sku } = useFetchSku({ dispatch, shopId });

  useEffect(() => {
    dispatch(shopApiKeysSettingsActions.getApiKeys({ shopId }));
  }, []);

  const shopApiKey = getOldestNonExpiredApiKey(apiKeys);

  const onPreviewClick = useCallback(() => {
    const url = `${BASE_PREVIEW_URL}?sku=${sku}&shopId=${shopId}&sig=${shopApiKey?.key}`;
    window.open(url, '_blank');
  }, [sku, shopId, shopApiKey]);

  return (
    <Page className='discover-banner-container'>
      <Page.Header>
        <BackButtonTitle path={navigationPath}>Discovery Banner</BackButtonTitle>
        <ButtonsSection>
          <Button
            variant='secondary'
            startIcon={AvailableIcons.PreviewButton}
            onClick={onPreviewClick}
          >
            Preview
          </Button>
          <ButtonsSection ref={formHeaderRef} />
        </ButtonsSection>
      </Page.Header>
      <Page.Content>
        {shopId && (
          <DiscoveryBannerSettings
            discoveryBannerSettings={discoveryBannerSettings}
            shopId={shopId}
            formHeaderElementRef={formHeaderRef}
            dispatch={dispatch}
          />
        )}
      </Page.Content>
    </Page>
  );
};
