import React from 'react';
import { CollapseDrawer } from '../CollapseDrawer/CollapseDrawer';
import { useEditCategoryPage } from './useEditCategoryPage';
import { EditCategoryPageContainer } from './EditCategoryPageContainer';
import { useNavigateToVisualEditorRoute } from '../../hooks/useNavigateToVisualEditorRoute';
import { PermittedRouteMap } from '../../../../app-routes';

interface Props {
  shopId: number;
  permittedRouteMap: PermittedRouteMap;
  className: string;
}

export const EditCategoryPage = ({
  shopId,
  permittedRouteMap,
  className,
}: Props): JSX.Element | null => {
  const { categoryFilterRule, onChange, onSave, onDiscard, isDirty } = useEditCategoryPage({
    shopId,
    permittedRouteMap,
  });

  const { navigateToVisualEditorRoute } = useNavigateToVisualEditorRoute({
    route: permittedRouteMap?.visualEditor,
  });

  return (
    <CollapseDrawer
      open
      className={className}
      onClose={() => navigateToVisualEditorRoute({ shopId })}
    >
      <EditCategoryPageContainer
        rule={categoryFilterRule}
        onChange={onChange}
        onSave={onSave}
        onDiscard={onDiscard}
        isDirty={isDirty}
      />
    </CollapseDrawer>
  );
};
