import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import {
  TypographyType,
  TypographyVariant,
  MenuItem,
  Typography,
  Popover,
} from 'src/components-dummy';
import {
  ListV2Styled,
  DropdownContentStyled,
  SearchInputStyled,
} from './SearchableDropdownWithTrigger.styles';
import { useKeyboardNavigation } from './useKeyboardNavigation';

const EXTRA_SPACE_GAP = 20;
export interface SearchableDropdownMenuProps {
  options: { value: string; text: string }[];
  isMenuSelectEnabled: boolean;
  currentValue?: string;
  innerRef?: any;
  staticWidthInPixels?: number;
  setTriggerComponent: (onAnchorElementSet: (element: HTMLDivElement) => void) => JSX.Element;
  onChange: (selectedValue: string) => void;
}

export const SearchableDropdownMenu = ({
  options,
  currentValue,
  innerRef,
  staticWidthInPixels,
  setTriggerComponent,
  onChange,
}: SearchableDropdownMenuProps): JSX.Element => {
  const [searchInputState, setSearchInputState] = useState('');
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [anchorPosition, setAnchorPosition] = useState<{ top: number; left: number } | null>(null);
  const [menuWidth, setMenuWidth] = useState<number>(staticWidthInPixels || undefined); // Track width
  const [menuHeight, setMenuHeight] = useState<number>(200);
  const listRef = useRef<HTMLUListElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const handleSearchChange = (text: string) => {
    setSearchInputState(text);
  };

  const onClose = useCallback(() => {
    setAnchorEl(null);
    setAnchorPosition(null);
  }, []);

  const onOptionClick = (selectedValue: string) => {
    onChange(selectedValue);
    setSearchInputState('');
    onClose();
  };

  const filteredOptions = useMemo(
    () =>
      options.filter(option => {
        return option.text.toLowerCase().includes(searchInputState.toLowerCase());
      }),
    [searchInputState, options]
  );

  const onAnchorElementSet = useCallback(
    element => {
      if (element) {
        const rect = element.getBoundingClientRect();
        setMenuWidth(staticWidthInPixels || rect.width);

        const spaceBelow = window.innerHeight - rect.bottom;
        const spaceAbove = rect.top;

        const menuHeightWithExtra = menuHeight + EXTRA_SPACE_GAP;

        if (spaceBelow < menuHeightWithExtra && spaceAbove > spaceBelow) {
          // Open above
          setAnchorPosition({
            top: rect.top,
            left: rect.left,
          });
          setAnchorEl(null);
        } else {
          // Open below
          setAnchorEl(element);
          setAnchorPosition(null);
        }
      }
    },
    [staticWidthInPixels]
  );

  const isOpen = !!anchorEl || !!anchorPosition;

  const { focusedIndex, setFocusedIndex, handleKeyDown } = useKeyboardNavigation(
    filteredOptions,
    selectedValue => {
      onOptionClick(selectedValue);
    }
  );

  useEffect(() => {
    if (listRef.current) {
      setMenuHeight(menuRef.current.getBoundingClientRect().height);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!staticWidthInPixels && anchorEl) {
      const rect = anchorEl.getBoundingClientRect();
      setMenuWidth(rect.width); // Dynamically set the menu width
    }
  }, [anchorEl, staticWidthInPixels]);

  return (
    <div ref={innerRef}>
      {setTriggerComponent(onAnchorElementSet)}
      <Popover
        open={isOpen}
        onClose={onClose}
        anchorEl={anchorEl}
        anchorReference={anchorPosition ? 'anchorPosition' : 'anchorEl'}
        anchorPosition={anchorPosition}
        anchorOrigin={{
          vertical: anchorPosition ? 'top' : 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: anchorPosition ? 'bottom' : 'top',
          horizontal: 'left',
        }}
        style={{ marginTop: anchorPosition ? '-8px' : '8px' }}
      >
        <DropdownContentStyled
          width={menuWidth}
          ref={menuRef}
          className={`${isOpen ? 'opened' : 'closed'}`}
        >
          <SearchInputStyled
            placeholder='Search...'
            value={searchInputState}
            onChange={text => handleSearchChange(text)}
            onKeyDown={handleKeyDown}
            disabled={!options.length}
            autoFocus
          />
          <ListV2Styled ref={listRef} tabIndex={-1} onKeyDown={handleKeyDown}>
            {filteredOptions.map((option, index) => {
              const isFocused = index === focusedIndex;

              return (
                <MenuItem
                  selected={option.value === currentValue}
                  key={option.text}
                  value={`${option.value}`}
                  style={{
                    backgroundColor: isFocused ? '#e0e0e0' : 'transparent',
                    cursor: 'pointer',
                  }}
                  onClick={() => onOptionClick(option.value)}
                  onMouseEnter={() => setFocusedIndex(index)}
                  onMouseLeave={() => setFocusedIndex(-1)}
                >
                  <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
                    {option.text}
                  </Typography>
                </MenuItem>
              );
            })}
          </ListV2Styled>
        </DropdownContentStyled>
      </Popover>
    </div>
  );
};
