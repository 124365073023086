import React, { MouseEvent, useCallback } from 'react';
import {
  EllipsisMenuButton,
  Menu,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { MenuActionsStyled, MenuItemStyled } from './RowActionsMenu.styles';

interface RowActionMenuProps {
  menuAnchorElement: HTMLElement | null;
  onMenuClicked: (event: MouseEvent<HTMLElement>) => void;
  onMenuClose: () => void;
  onEditSynonymClick?: (synonymId: string) => void;
  onDeleteSynonymClick?: () => void;
}

export function RowActionsMenu({
  menuAnchorElement,
  onMenuClicked,
  onMenuClose,
  onEditSynonymClick,
  onDeleteSynonymClick,
}: RowActionMenuProps): JSX.Element {
  const isOpen = Boolean(menuAnchorElement);

  const onItemClick = useCallback(
    onClick => {
      onClick();
      onMenuClose();
    },
    [onMenuClose]
  );

  return (
    <MenuActionsStyled>
      <EllipsisMenuButton onClick={onMenuClicked} isActive={isOpen} />
      <Menu
        open={isOpen}
        anchorEl={menuAnchorElement}
        onClose={onMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: -4,
          horizontal: 'right',
        }}
      >
        <MenuItemStyled
          key='edit'
          disabled={!Boolean(onEditSynonymClick)}
          onClick={() => onItemClick(onEditSynonymClick)}
        >
          <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
            Edit
          </Typography>
        </MenuItemStyled>

        <MenuItemStyled
          key='delete'
          disabled={!Boolean(onDeleteSynonymClick)}
          onClick={() => onItemClick(onDeleteSynonymClick)}
          shouldHighlightRed
        >
          <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
            Delete
          </Typography>
        </MenuItemStyled>
      </Menu>
    </MenuActionsStyled>
  );
}
