import React, { RefObject, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { isEqual } from 'lodash';
import { RelevancyTuningItem } from 'src/services';
import { Skeleton } from 'src/components-dummy';
import { Dispatch } from 'src/components-bl/types';
import { FormApiRef } from '../types';
import { relevancyTuningActions } from './relevancyTuning.actions';
import { RelevancyTuningTable } from './components';
import { AugmentedSearchWrapper } from '../AugmentedSearch.styles';
import { MissingAugmentedSearchCatalogCard } from '../MissingAugmentedSearchCatalogCard';

interface RelevancyTuningProps {
  shopId?: number;
  formApiRef: RefObject<FormApiRef>;
  hasAugmentedSearchCatalog: boolean | null;
  relevancyTuning?: RelevancyTuningItem[];
  dispatch: Dispatch;
  onSubmitStatusChange: (canSubmit: boolean) => void;
}

export function RelevancyTuning({
  shopId,
  formApiRef,
  hasAugmentedSearchCatalog,
  relevancyTuning,
  dispatch,
  onSubmitStatusChange,
}: RelevancyTuningProps): JSX.Element {
  const [relevancyTuningDraft, setRelevancyTuningDraft] = useState(relevancyTuning);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    setRelevancyTuningDraft(relevancyTuning);
  }, [relevancyTuning, setRelevancyTuningDraft]);

  useEffect(() => {
    onSubmitStatusChange(isDirty);
    dispatch(relevancyTuningActions.notifyIsDirty({ isDirty }));
  }, [isDirty, dispatch, onSubmitStatusChange]);

  useEffect(() => {
    if (shopId && hasAugmentedSearchCatalog) {
      dispatch(relevancyTuningActions.getRelevancyTuning({ shopId }));
    }

    return () => {
      dispatch(relevancyTuningActions.resetRelevancyTuning());
    };
  }, [shopId, hasAugmentedSearchCatalog, dispatch]);

  useEffect(() => {
    const isFormDirty = !isEqual(relevancyTuning, relevancyTuningDraft);

    setIsDirty(isFormDirty);
  }, [relevancyTuning, relevancyTuningDraft, setIsDirty]);

  const onRelevancyTuningChange = useCallback(
    (updatedRelevancyTuning: RelevancyTuningItem[]) => {
      setRelevancyTuningDraft(updatedRelevancyTuning);
    },
    [setRelevancyTuningDraft]
  );

  const onSubmit = useCallback(
    async (updatedRelevancyTuning?: RelevancyTuningItem[]) => {
      if (shopId && updatedRelevancyTuning) {
        try {
          await (
            dispatch(
              relevancyTuningActions.updateRelevancyTuning({
                shopId,
                relevancyTuning: updatedRelevancyTuning,
              })
            ) as any
          ).unwrap();
        } catch (error) {
          console.error(error);
        }
      }
    },
    [shopId, dispatch]
  );

  const onResetDefaults = useCallback(async () => {
    const tuningsWithDefaults = relevancyTuning?.map(tuning => ({
      ...tuning,
      priority: tuning.defaultPriority,
      active: true,
    }));

    if (tuningsWithDefaults) {
      await onSubmit(tuningsWithDefaults);
    }
  }, [relevancyTuning, onSubmit]);

  useImperativeHandle(
    formApiRef,
    () => {
      return {
        submit: () => onSubmit(relevancyTuningDraft),
      };
    },
    [relevancyTuningDraft, onSubmit]
  );

  const isLoading =
    !shopId ||
    hasAugmentedSearchCatalog === null ||
    (hasAugmentedSearchCatalog && !relevancyTuningDraft);

  if (isLoading) {
    return (
      <AugmentedSearchWrapper>
        <Skeleton height={250} width={700} variant='rounded' />
      </AugmentedSearchWrapper>
    );
  }

  return (
    <AugmentedSearchWrapper>
      {hasAugmentedSearchCatalog ? (
        <RelevancyTuningTable
          relevancyTuning={relevancyTuningDraft as RelevancyTuningItem[]}
          onRelevancyTuningChange={onRelevancyTuningChange}
          onResetDefaults={onResetDefaults}
        />
      ) : (
        <MissingAugmentedSearchCatalogCard />
      )}
    </AugmentedSearchWrapper>
  );
}
