import { AxiosProgressEvent, AxiosResponse } from 'axios';
import { ApiServiceBase } from '../api-service-base';
import {
  AugmentedSearchGeneralSettings,
  SyteProductType,
  RelevancyTuningItem,
  SynonymsData,
  Synonym,
  SynonymAPIResponse,
} from '../types';
import { AugmentedSearchMapper } from './augmented-search.mapper';
import { GetSynonymByIdArguments, GetSynonymsArguments, SynonymLanguage } from './synonyms/types';
import { buildQueryParams, downloadResponseFile } from '../../common/utils';
export class AugmentedSearchService extends ApiServiceBase {
  constructor() {
    super('shops');
  }

  private getUrl(shopId: number): string {
    return `${this.serviceBaseUri}/${shopId}/syte-products/${SyteProductType.AugmentedSearch}`;
  }

  async getGeneralSettings({
    shopId,
  }: {
    shopId: number;
  }): Promise<AugmentedSearchGeneralSettings> {
    const url = `${this.getUrl(shopId)}/general-settings`;

    const response: AxiosResponse<AugmentedSearchGeneralSettings> = await this.httpService.get({
      url,
    });

    return response.data;
  }

  async updateGeneralSettings({
    shopId,
    generalSettings,
  }: {
    shopId: number;
    generalSettings: AugmentedSearchGeneralSettings;
  }): Promise<AugmentedSearchGeneralSettings> {
    const url = `${this.getUrl(shopId)}/general-settings`;

    const response: AxiosResponse<AugmentedSearchGeneralSettings> = await this.httpService.patch({
      url,
      data: generalSettings,
    });

    return response.data;
  }

  async getRelevancyTuning({ shopId }: { shopId: number }): Promise<RelevancyTuningItem[]> {
    const url = `${this.getUrl(shopId)}/relevancy-tuning`;

    const response: AxiosResponse<{ tunings: RelevancyTuningItem[] }> = await this.httpService.get({
      url,
    });

    return AugmentedSearchMapper.mapRelevancyTuning(response);
  }

  async updateRelevancyTuning({
    shopId,
    relevancyTuning,
  }: {
    shopId: number;
    relevancyTuning: RelevancyTuningItem[];
  }): Promise<RelevancyTuningItem[]> {
    const url = `${this.getUrl(shopId)}/relevancy-tuning`;

    const response: AxiosResponse<{
      tunings: RelevancyTuningItem[];
    }> = await this.httpService.patch({
      url,
      data: {
        tunings: relevancyTuning.map(({ dataField, priority, active }) => ({
          dataField,
          priority,
          active,
        })),
      },
    });

    return AugmentedSearchMapper.mapRelevancyTuning(response);
  }

  async getSynonyms({
    shopId,
    language,
    skip,
    limit,
    ...parameters
  }: GetSynonymsArguments): Promise<SynonymsData> {
    const queryStringParams = buildQueryParams({ language, skip, limit, ...parameters });

    const url = `${this.getUrl(shopId)}/synonyms${queryStringParams}`;

    const response: AxiosResponse<SynonymsData> = await this.httpService.get({
      url,
    });

    return AugmentedSearchMapper.mapSynonyms(response);
  }

  async createSynonym({
    shopId,
    language,
    phrase,
  }: {
    shopId: number;
    language: SynonymLanguage;
    phrase: string[];
  }): Promise<Synonym> {
    const url = `${this.getUrl(shopId)}/synonyms?language=${language}`;

    const response: AxiosResponse<SynonymAPIResponse> = await this.httpService.post({
      url,
      data: { phrase },
    });

    return AugmentedSearchMapper.mapSynonym(response.data);
  }

  async deleteSynonym({
    shopId,
    language,
    synonymId,
  }: {
    shopId: number;
    language: SynonymLanguage;
    synonymId: string;
  }): Promise<Synonym> {
    const url = `${this.getUrl(shopId)}/synonyms/${synonymId}?language=${language}`;

    const response: AxiosResponse<SynonymAPIResponse> = await this.httpService.delete({
      url,
    });

    return AugmentedSearchMapper.mapSynonym(response.data);
  }

  async importSynonyms({
    data,
    cancellationSignal,
    onUploadProgress,
  }: {
    data: {
      shopId: number;
      language: SynonymLanguage;
      synonymsFile: File;
    };
    cancellationSignal?: AbortSignal;
    onUploadProgress: (progressEvent: AxiosProgressEvent) => void;
  }): Promise<AxiosResponse> {
    const { shopId, language, synonymsFile } = data;
    const url = `${this.getUrl(shopId)}/synonyms/import?language=${language}`;

    const formData = new FormData();
    formData.append('file', synonymsFile);

    const response = await this.httpService.post({
      url,
      data: formData,
      requestConfig: {
        onUploadProgress,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        signal: cancellationSignal,
      },
    });

    return response;
  }

  async getSynonymById({ shopId, language, synonymId }: GetSynonymByIdArguments): Promise<Synonym> {
    const url = `${this.getUrl(shopId)}/synonyms/${synonymId}?language=${language}`;
    const response: AxiosResponse<SynonymAPIResponse> = await this.httpService.get({
      url,
    });

    return AugmentedSearchMapper.mapSynonym(response.data);
  }

  async editSynonym({
    shopId,
    synonymId,
    language,
    phrase,
  }: {
    shopId: number;
    synonymId: string;
    language: SynonymLanguage;
    phrase: string[];
  }): Promise<Synonym> {
    const url = `${this.getUrl(shopId)}/synonyms/${synonymId}?language=${language}`;

    const response: AxiosResponse<SynonymAPIResponse> = await this.httpService.patch({
      url,
      data: { phrase },
    });

    return AugmentedSearchMapper.mapSynonym(response.data);
  }

  async exportSynonyms({
    shopId,
    language,
  }: {
    shopId: number;
    language: SynonymLanguage;
  }): Promise<void> {
    const url = `${this.getUrl(shopId)}/synonyms/export?language=${language}`;

    const response: AxiosResponse<Blob | undefined> = await this.httpService.post({
      url,
      requestConfig: {
        responseType: 'blob',
      },
    });
    downloadResponseFile({
      response,
      defaultName: `SYNONYMS-EXPORT-${shopId}-${language}-${new Date().toDateString()}.csv`,
    });
  }
}

export const augmentedSearchService = new AugmentedSearchService();
