import React, { useCallback, useMemo } from 'react';
import { RoutedComponentProps } from 'src/app-routes';
import { SectionWithTitle } from '../SectionWithTitle/SectionWithTitle';
import { generatePath } from 'react-router';
import { ProductTile } from './ProductTile';
import { Dispatch } from 'src/components-bl/types';
import { productsSectionActions } from './ProductsSection.actions';
import { ProductTiles } from './ProductsSection.styles';
import { AvailableIcons } from 'src/components-dummy';

export interface ProductsProps extends RoutedComponentProps {
  shopId: number;
  dispatch: Dispatch;
}

export const ProductsSection = ({
  dispatch,
  permittedRouteMap,
  shopId,
}: ProductsProps): JSX.Element => {
  const filteredRoutes = useMemo(() => {
    return [
      {
        key: 'merchandisingRules',
        title: 'Merchandising Rules',
        route: permittedRouteMap.merchandisingRules,
        iconName: AvailableIcons.DependencyGraph,
        paragraphText: 'Boost, bury, and filter products effortlessly',
        cta: 'Go to Rules',
      },
      {
        key: 'collections',
        title: 'Collections',
        route: permittedRouteMap.collections,
        iconName: AvailableIcons.ShoppingCartOnMonitor,
        paragraphText: 'Curate custom product recommendations, and visualize them easily',
        cta: 'View Collections',
      },
      {
        key: 'experiments',
        title: 'AB Test',
        route: permittedRouteMap.experiments,
        iconName: AvailableIcons.Experiments,
        paragraphText:
          'Test and refine merchandising strategies, and configuration to optimize performance',
        cta: 'Run a Test',
      },
      {
        key: 'catalogManager',
        title: 'Catalogue Manager',
        route: permittedRouteMap.catalogManager,
        iconName: AvailableIcons.Dash,
        paragraphText: 'Track catalog status and explore catalog details',
        cta: 'Manage Catalog',
      },
    ];
  }, [permittedRouteMap]);

  const navigateTo = useCallback(
    path => {
      if (!path) return;
      const url = generatePath(path, { shopId });

      dispatch(productsSectionActions.navigateTo({ navigateTo: url }));
    },
    [dispatch, shopId]
  );

  return (
    <SectionWithTitle>
      <ProductTiles>
        {filteredRoutes.map(({ key, title, route, iconName, paragraphText, cta }) => {
          const isDisabled = !route;
          const onClick = () => {
            if (!isDisabled) {
              navigateTo(route.path);
            }
          };

          return (
            <ProductTile
              key={key}
              title={title}
              onClick={onClick}
              iconName={iconName}
              paragraphText={paragraphText}
              cta={cta}
              isDisabled={isDisabled}
            />
          );
        })}
      </ProductTiles>
    </SectionWithTitle>
  );
};
