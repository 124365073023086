import styled from '@emotion/styled';
import { Typography } from 'src/components-dummy';

export const SectionWithTitleStyled = styled.div`
  margin: 48px 0;
`;

export const SubTitleTypographyStyled = styled(Typography)`
  margin-bottom: 48px;
  color: ${({ theme }) => theme.palette.custom['gray-10']};
`;
