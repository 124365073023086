import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { Tooltip } from '../Tooltip';
import { PopupPosition } from '../PopUp';
import { EllipsisWithToolTipStyled } from './EllipsisWithToolTip.style';

const isElementOverflow = (element: HTMLElement): boolean =>
  element.scrollWidth - element.offsetWidth > 0;

export interface EllipsisWithTooltipProps {
  tooltipText: string | React.ReactNode;
  tooltipPosition?: PopupPosition;
  children: React.ReactChild | React.ReactChildren;
  className?: string;
  tooltipClassName?: string;
  maxWidth?: number;
  forceShowTooltip?: boolean;
}

export const EllipsisWithTooltip = ({
  tooltipText,
  tooltipPosition = 'top center',
  children,
  className,
  tooltipClassName,
  maxWidth,
  forceShowTooltip = false,
}: EllipsisWithTooltipProps): JSX.Element => {
  const [elementRef, setElementRef] = useState<HTMLDivElement | null>(null);

  const isOverflow = useMemo(() => {
    if (!elementRef) return false;

    const isNewTextOverflow = isElementOverflow(elementRef as HTMLDivElement);

    return isNewTextOverflow;
  }, [tooltipText, children, elementRef]);

  return (
    <EllipsisWithToolTipStyled
      className={classNames('ellipsis-with-tooltip', className)}
      ref={element => {
        setElementRef(element);
      }}
      maxWidth={maxWidth}
    >
      {isOverflow || forceShowTooltip ? (
        <Tooltip
          value={tooltipText}
          position={tooltipPosition}
          className={classNames('ellipsis-with-tooltip-popup', tooltipClassName)}
        >
          <div className='ellipsis-with-tooltip-content'>{children}</div>
        </Tooltip>
      ) : (
        children
      )}
    </EllipsisWithToolTipStyled>
  );
};
