import { useMemo } from 'react';
import { IS_PRODUCTION } from 'src/services/src/common/configuration';

export enum Environment {
  Localhost = 'Localhost',
  Production = 'Production',
  QA = 'QA',
  Unknown = 'Unknown',
}
interface EnvironmentDetails {
  env: Environment;
  ticket: string | null;
}

export const useEnvironment = (): EnvironmentDetails => {
  const environment = useMemo<EnvironmentDetails>(() => {
    if (IS_PRODUCTION) {
      return { env: Environment.Production, ticket: null };
    }
    const { hostname } = window.location;

    if (hostname.includes('localhost')) {
      return { env: Environment.Localhost, ticket: null };
    }

    if (hostname.includes('develop.syte.app')) {
      const ticket = hostname.split('.develop.syte.app')[0] || null;
      return { env: Environment.QA, ticket };
    }

    return { env: Environment.Unknown, ticket: null };
  }, []);

  return environment;
};
