import React from 'react';
import { TableV2, TableV2InstanceProps } from '../../../../../components-dummy';
import { tableColumns } from '../consts';

interface Props {
  headers: TableV2InstanceProps<any>['headerGroups'][0]['headers'];
}

export const SynonymsTableHeaderColumns: React.FC<Props> = ({ headers }) => {
  const renderHeaderColumn = ({ column }: { column: any }) => {
    return <TableV2.HeadRowCellText>{column.render('header')}</TableV2.HeadRowCellText>;
  };

  return (
    <>
      {headers.map(column => (
        <TableV2.HeadRow
          {...column.getHeaderProps()}
          key={column.id}
          isResizable={column.id !== tableColumns.rowActions.accessor}
        >
          {renderHeaderColumn({ column })}
          {column.id !== tableColumns.rowActions.accessor && (
            <TableV2.HeaderRowCellResizableCursor column={column} />
          )}
        </TableV2.HeadRow>
      ))}
    </>
  );
};
