import {
  SynonymStatus,
  SynonymType,
} from 'src/services/src/service/augmented-search/synonyms/types';
import { SynonymsTableHeadRow } from './types';

export const tableColumns: Record<string, SynonymsTableHeadRow> = {
  synonym: {
    header: 'Synonym',
    accessor: 'phrase',
    minWidth: 120,
  },
  type: {
    header: 'Type',
    accessor: 'type',
    minWidth: 40,
  },
  status: {
    header: 'Status',
    accessor: 'status',
    minWidth: 60,
  },
  lastEdit: {
    header: 'Last Edit',
    accessor: 'updatedAt',
    minWidth: 20,
  },
  rowActions: {
    header: '',
    accessor: 'rowActions',
    minWidth: 10,
  },
};

export const columns = Object.values(tableColumns);

export const displayNameMap: Record<SynonymStatus | SynonymType, string> = {
  [SynonymType.Custom]: 'Custom',
  [SynonymType.Global]: 'Global',
  [SynonymStatus.Indexed]: 'Indexed (Live)',
  [SynonymStatus.PendingIndex]: 'Pending Index',
};
