import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { generatePath } from 'react-router';
import { RoutedComponentProps } from '../../../../app-routes';
import { SynonymLanguage } from '../../../../services/src/service/augmented-search/synonyms/types';
import { synonymsActions } from '../SynonymsActions';
import { TableV2 } from '../../../../components-dummy';
import { Dispatch } from '../../../types';
import { SynonymsFiltersState } from '../SynonymsTablePage/SynonymsTable/SynonymsFilters';

interface UploadNewFileButtonProps extends RoutedComponentProps {
  shopId: number;
  language: SynonymLanguage;
  hasUploadNewFileButton: boolean;
  dispatch: Dispatch;
  filtersValue: SynonymsFiltersState;
}

export const UploadNewFileButton: FunctionComponent<UploadNewFileButtonProps> = ({
  shopId,
  language,
  hasUploadNewFileButton,
  permittedRouteMap,
  dispatch,
  filtersValue,
}) => {
  const navigateToImportRoute = useCallback(() => {
    if (!permittedRouteMap.augmentedSearchSynonymsLanguage) {
      return;
    }

    dispatch(
      synonymsActions.navigateTo({
        navigateTo: generatePath(permittedRouteMap.augmentedSearchImportSynonyms.path, {
          shopId,
          language,
        }),
      })
    );
  }, [shopId, permittedRouteMap, dispatch]);

  const { emptyListMessage, emptyListButtonText, emptyListOnButtonClick } = useMemo(() => {
    const isFilterApplied =
      filtersValue.searchTerm?.value || filtersValue.daysSinceLastEdit || filtersValue.status;

    return {
      emptyListMessage: isFilterApplied ? 'No results' : 'Please upload a Synonyms file',
      emptyListButtonText: isFilterApplied ? undefined : 'Upload new file',
      emptyListOnButtonClick: isFilterApplied ? undefined : navigateToImportRoute,
    };
  }, [filtersValue, navigateToImportRoute]);

  return (
    hasUploadNewFileButton && (
      <TableV2.EmptyList
        width={350}
        message={emptyListMessage}
        buttonText={emptyListButtonText}
        onButtonClick={emptyListOnButtonClick}
      />
    )
  );
};
