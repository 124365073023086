import { Dispatch } from '../../../../types';

export enum VariantsTableActionTypes {
  SelectVariant = 'VariantsTable/SelectVariant',
  CompareVariant = 'VariantsTable/CompareVariant',
  ResetComparedVariant = 'VariantsTable/ResetComparedVariant',
}

export interface VariantsTableSelectVariantActionPayload {
  variantId: string | undefined;
}

export interface VariantsTableCompareVariantActionPayload {
  variantId: string | undefined;
}

export interface VariantsTableSelectVariantAction {
  type: typeof VariantsTableActionTypes.SelectVariant;
  payload: VariantsTableSelectVariantActionPayload;
}

export interface VariantsTableCompareVariantAction {
  type: typeof VariantsTableActionTypes.CompareVariant;
  payload: VariantsTableCompareVariantActionPayload;
}

export interface VariantsTableResetComparedVariantAction {
  type: typeof VariantsTableActionTypes.ResetComparedVariant;
  payload: undefined;
}

export type VariantsTableAction =
  | VariantsTableSelectVariantAction
  | VariantsTableCompareVariantAction
  | VariantsTableResetComparedVariantAction;

const variantsTableActions = {
  selectVariant(
    payload: VariantsTableSelectVariantActionPayload
  ): VariantsTableSelectVariantAction {
    return {
      type: VariantsTableActionTypes.SelectVariant,
      payload,
    };
  },
  compareVariant(
    payload: VariantsTableCompareVariantActionPayload
  ): VariantsTableCompareVariantAction {
    return {
      type: VariantsTableActionTypes.CompareVariant,
      payload,
    };
  },
  resetComparedVariant(): VariantsTableResetComparedVariantAction {
    return {
      type: VariantsTableActionTypes.ResetComparedVariant,
      payload: undefined,
    };
  },
};

export const variantsTableActionMethods = {
  selectVariant(payload: VariantsTableSelectVariantActionPayload) {
    return (dispatch: Dispatch): void => {
      dispatch(variantsTableActions.selectVariant(payload));
    };
  },
  compareVariant(payload: VariantsTableCompareVariantActionPayload) {
    return (dispatch: Dispatch): void => {
      dispatch(variantsTableActions.compareVariant(payload));
    };
  },
  resetComparedVariant() {
    return (dispatch: Dispatch): void => {
      dispatch(variantsTableActions.resetComparedVariant());
    };
  },
};
