import { useEffect, useState, useCallback } from 'react';
import { useValidateSchema } from 'src/hooks';
import { DiscoveryBannerSettingsReducerState } from 'src/app-state-types';
import {
  UseDiscoveryBannerSettingsDraftArgs,
  UseDiscoveryBannerSettingsDraft,
  DiscoveryBannerSettingsRecord,
} from './types';
import { discoveryBannerSettingsValidationSchema } from './constants';
import { discoveryBannerSettingsActionsMethods } from './Actions';
import { isDataDirty } from '../../utils';

export function useDiscoveryBannerSettingsDraft({
  discoveryBannerSettings,
  dispatch,
  shopId,
  selectedVariantId,
  onIsDirty,
  shouldRefetch = false,
}: UseDiscoveryBannerSettingsDraftArgs): UseDiscoveryBannerSettingsDraft {
  const [draftDiscoveryBannerSettings, setDraftDiscoveryBannerSettings] =
    useState(discoveryBannerSettings);

  const { errors, validate, isValid } = useValidateSchema<DiscoveryBannerSettingsRecord>({
    schema: discoveryBannerSettingsValidationSchema,
  });

  const isDirty = isDataDirty(discoveryBannerSettings, draftDiscoveryBannerSettings);

  const onSubmit = (): void => {
    if (isValid) {
      dispatch(
        discoveryBannerSettingsActionsMethods.updateDiscoveryBannerSettings({
          shopId,
          general: draftDiscoveryBannerSettings.general,
          banner: draftDiscoveryBannerSettings.banner,
          button: draftDiscoveryBannerSettings.button,
          icon: draftDiscoveryBannerSettings.icon,
          mobileGrid: draftDiscoveryBannerSettings.mobileGrid,
          desktopGrid: draftDiscoveryBannerSettings.isSameGrid
            ? draftDiscoveryBannerSettings.mobileGrid
            : draftDiscoveryBannerSettings.desktopGrid,
          isSameGrid: draftDiscoveryBannerSettings.isSameGrid,
          strategy: draftDiscoveryBannerSettings.strategy,
          variantId: selectedVariantId,
        })
      );
    }
  };

  const onDiscard = (): void => {
    setDraftDiscoveryBannerSettings(discoveryBannerSettings);
  };

  const onChange = (partialSettings: Partial<DiscoveryBannerSettingsReducerState>) => {
    const updatedState = { ...draftDiscoveryBannerSettings, ...partialSettings };
    validate({ dataToValidate: updatedState });
    setDraftDiscoveryBannerSettings(updatedState);
  };

  const fetchDiscoveryBannerSettings = useCallback(() => {
    dispatch(
      discoveryBannerSettingsActionsMethods.fetchDiscoveryBannerSettings({
        shopId,
        variantId: selectedVariantId,
      })
    );
  }, [shopId, selectedVariantId]);

  const onChangeGeneral = (
    partialSettings: Partial<DiscoveryBannerSettingsReducerState['general']>
  ) => {
    const updatedState = {
      ...draftDiscoveryBannerSettings.general,
      ...partialSettings,
    };
    onChange({ general: updatedState });
  };

  const onChangeHeading = (
    partialSettings: Partial<DiscoveryBannerSettingsReducerState['banner']>
  ) => {
    const updatedState = {
      ...draftDiscoveryBannerSettings.banner,
      ...partialSettings,
    };
    onChange({ banner: updatedState });
  };

  const onChangeButton = (
    partialSettings: Partial<DiscoveryBannerSettingsReducerState['button']>
  ) => {
    const updatedState = {
      ...draftDiscoveryBannerSettings.button,
      ...partialSettings,
    };
    onChange({ button: updatedState });
  };

  const onChangeIcon = (partialSettings: Partial<DiscoveryBannerSettingsReducerState['icon']>) => {
    const updatedState = {
      ...draftDiscoveryBannerSettings.icon,
      ...partialSettings,
    };
    onChange({ icon: updatedState });
  };

  useEffect((): void => {
    setDraftDiscoveryBannerSettings(discoveryBannerSettings);
  }, [discoveryBannerSettings]);

  useEffect(() => {
    const payload = { isDirty: !!isDirty };
    if (onIsDirty) {
      onIsDirty(payload);
    } else {
      dispatch(discoveryBannerSettingsActionsMethods.notifyIsDirty(payload));
    }
  }, [isDirty]);

  useEffect(() => {
    fetchDiscoveryBannerSettings();
  }, [fetchDiscoveryBannerSettings]);

  useEffect(() => {
    if (shouldRefetch) {
      fetchDiscoveryBannerSettings();
    }
  }, [shouldRefetch]);

  return {
    draftDiscoveryBannerSettings,
    errors,
    isValid,
    isDirty,
    onSubmit,
    onDiscard,
    onChange,
    onChangeGeneral,
    onChangeHeading,
    onChangeButton,
    onChangeIcon,
  };
}
