import Joi, { AnySchema, CustomValidator } from 'joi';
import { isNumber, isString } from 'lodash';
import { FieldType, MerchandisingRuleTypes, SyteProductTypes } from 'src/services';
import { getStringEnumValues, isArrayOfNumbers, isArrayOfStrings } from 'src/utils';
import { ConditionRowType, MerchandisingRuleSettings } from '../types';
import { DataFieldLookupTable } from '../useDataFieldsLookupTable';
import { validateRuleRegionsAreAvailable } from '../../merchandisingRules.helpers';
import { defaultRuleSettings } from '../constants';
import { RuleDraft } from './MerchandisingRuleForm.config';
import {
  MAX_POSITION_VALUE,
  MIN_POSITION_VALUE,
} from './components/RuleFormConditionsSection/PinToPosition/constants';

type ValidationContextRuleSettings = Pick<
  MerchandisingRuleSettings,
  'conditionTypesOptions' | 'subRulesConditionTypes'
>;

export interface GetSubTypeSchemaArguments {
  ruleSettingsKey: keyof ValidationContextRuleSettings;
  conditionType?: ConditionRowType;
}

export interface ValidationContext {
  dataFieldsLookupTable: DataFieldLookupTable;
  ruleSettings: ValidationContextRuleSettings;
  availableRegions: string[];
}
const containsSchema = Joi.array()
  .min(1)
  .required()
  .custom((value, helpers) => {
    const { original: tagValues } = helpers;
    const isValid = isArrayOfStrings(tagValues);
    if (!isValid) {
      throw new Error('Invalid Contains value');
    }
    return value;
  }, 'validate Contains')
  .messages({ 'array.min': 'At least one tag should be added' });

const doesNotContainSchema = Joi.array()
  .min(1)
  .required()
  .custom((value, helpers) => {
    const { original: tagValues } = helpers;
    const [equalsValue] = tagValues;
    const isValid =
      typeof equalsValue === 'boolean' ||
      (isString(equalsValue) && equalsValue.trim().length) ||
      isArrayOfStrings(tagValues);
    if (!isValid) {
      throw new Error('Invalid Does Not Contain value');
    }
    return value;
  }, 'validate Equals')
  .messages({
    'any.custom': 'Value is invalid',
    'array.min': 'At least one tag should be added',
  });

export const conditionTypeToConditionValueValidationSchema: Partial<
  Record<MerchandisingRuleTypes.MerchandisingRuleSubType, Joi.ArraySchema>
> = {
  [MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotContain]: doesNotContainSchema,
  [MerchandisingRuleTypes.MerchandisingRuleSubType.Contains]: containsSchema,
  [MerchandisingRuleTypes.MerchandisingRuleSubType.Equals]: Joi.array()
    .length(1)
    .required()
    .custom((value, helpers) => {
      const { original } = helpers;
      const [equalsValue] = original;
      const isValid =
        (isString(equalsValue) && equalsValue.trim().length) || typeof equalsValue === 'boolean';
      if (!isValid) {
        throw new Error('Invalid Equals value');
      }
      return value;
    }, 'validate Equals')
    .messages({ 'any.custom': 'Value is invalid' }),
  [MerchandisingRuleTypes.MerchandisingRuleSubType.HigherThan]: Joi.array()
    .length(2)
    .required()
    .custom((value, helpers) => {
      const { original } = helpers;
      const [firstValue, secondValue] = original;
      const isValid = isNumber(firstValue) && secondValue === null;
      if (!isValid) {
        throw new Error('Invalid HigherThan value');
      }
      return value;
    }, 'validate HigherThan')
    .messages({ 'any.custom': '"Higher than" value can not be empty' }),

  [MerchandisingRuleTypes.MerchandisingRuleSubType.LowerThan]: Joi.array()
    .length(2)
    .required()
    .custom((value, helpers) => {
      const { original } = helpers;
      const [firstValue, secondValue] = original;
      const isValid = firstValue === null && isNumber(secondValue);
      if (!isValid) {
        throw new Error('Invalid LowerThan value');
      }
      return value;
    }, 'validate LowerThan')
    .messages({ 'any.custom': '"Lower than" value can not be empty' }),

  [MerchandisingRuleTypes.MerchandisingRuleSubType.IsBetween]: Joi.array()
    .length(2)
    .required()
    .custom((value, helpers) => {
      const { original } = helpers;
      const [firstValue, secondValue] = original;
      const isValid = isArrayOfNumbers(original) && firstValue < secondValue;
      if (!isValid) {
        throw new Error('Invalid IsBetween value');
      }
      return value;
    }, 'validate IsBetween ')
    .messages({ 'any.custom': 'Invalid is between value' }),
  [MerchandisingRuleTypes.MerchandisingRuleSubType.RelativeRange]: Joi.array()
    .length(2)
    .required()
    .custom((value, helpers) => {
      const { original } = helpers;
      const [firstValue, secondValue] = original;
      const isValid = isArrayOfNumbers(original) && firstValue < secondValue;
      if (!isValid) {
        throw new Error('Invalid RelativeRange value');
      }
      return value;
    }, 'validate RelativeRange ')
    .messages({ 'any.custom': 'Invalid relative range value' }),
};

const searchConditionValidationSchema = {
  enabled: Joi.boolean().required(),
  terms: Joi.alternatives().conditional('enabled', {
    is: true,
    then: Joi.array().items(Joi.string()).min(1).required(),
    otherwise: Joi.array().required(),
  }),
};

const searchConditionForRedirectRuleValidationSchema = {
  enabled: Joi.boolean().invalid(false).required(),
  terms: Joi.array().items(Joi.string()).min(1).required(),
};

const conditionValueValidationSchema = (
  Object.keys(
    conditionTypeToConditionValueValidationSchema
  ) as MerchandisingRuleTypes.MerchandisingRuleSubType[]
).reduce((result, current) => {
  return result.when('subType', {
    is: current,
    then: conditionTypeToConditionValueValidationSchema[current],
  });
}, Joi.array().items().required());

const getConditionTypes = (
  ruleSettings: ValidationContextRuleSettings,
  ruleSettingsKey: keyof ValidationContextRuleSettings,
  conditionType?: ConditionRowType
): Set<MerchandisingRuleTypes.MerchandisingRuleSubType> => {
  const isSubRulesConditionTypes = ruleSettingsKey === 'subRulesConditionTypes';
  const typesArray = isSubRulesConditionTypes
    ? ruleSettings.subRulesConditionTypes
    : conditionType && ruleSettings.conditionTypesOptions?.[conditionType];
  return new Set(typesArray);
};

const getContextValues = ({ context }: { context: Joi.Context | undefined }): ValidationContext => {
  const dataFieldsLookupTable = context?.dataFieldsLookupTable || {};
  const ruleSettings = context?.ruleSettings || defaultRuleSettings;
  const availableRegions = context?.availableRegions || [];

  return {
    dataFieldsLookupTable,
    ruleSettings,
    availableRegions,
  };
};

const getSubTypeSchema = ({
  ruleSettingsKey,
  conditionType,
}: GetSubTypeSchemaArguments): Joi.StringSchema => {
  return Joi.string().when('field', {
    not: undefined,
    then: Joi.required()
      .custom((value, helpers) => {
        const {
          original: operator,
          state: { ancestors },
          prefs: { context },
        } = helpers;

        const dataFieldNameFromSubRule = ancestors[0].field;

        const { dataFieldsLookupTable, ruleSettings } = getContextValues({ context });
        const conditionTypesSetForFiltering = getConditionTypes(
          ruleSettings,
          ruleSettingsKey,
          conditionType
        );
        const validConditionTypesSet = new Set(
          dataFieldsLookupTable[dataFieldNameFromSubRule]?.conditionTypes.filter(type =>
            conditionTypesSetForFiltering.has(type)
          ) || []
        );

        const isValid = validConditionTypesSet.has(operator);

        if (!isValid) {
          throw new Error('Operator is no longer valid');
        }
        return value;
      }, 'validate sub type')
      .messages({
        'any.custom': 'Operator is no longer valid',
        'any.required': 'Operator should not be empty',
      }),
    otherwise: Joi.optional(),
  });
};

export const customDataFieldValidation: CustomValidator = (
  dataFieldName: string,
  { prefs: { context } }
) => {
  const { dataFieldsLookupTable } = getContextValues({ context });

  const exists = !!dataFieldsLookupTable[dataFieldName];
  if (!exists) {
    throw new Error(`Data field doesn't exist`);
  }
  return dataFieldName;
};

const dataFieldValidationSchema = Joi.alternatives().conditional('fieldSource', {
  switch: [
    {
      is: FieldType.CatalogField,
      then: Joi.string()
        .required()
        .custom(customDataFieldValidation, 'validate data field')
        .messages({
          'any.custom': 'The data field was removed because it is invalid',
          'string.empty': 'Data field should not be empty',
          'any.required': 'Data field should not be empty',
        }),
    },
    {
      is: FieldType.AiTag,
      then: Joi.string().required().custom(customDataFieldValidation, 'validate ai tag').messages({
        'any.custom': 'The ai tag was removed because it is invalid',
        'string.empty': 'AI tag should not be empty',
        'any.required': 'AI tag should not be empty',
      }),
    },
  ],
  otherwise: Joi.string().allow('').required(),
});

const fieldTypeValidationSchema = Joi.string()
  .required()
  .messages({ 'any.required': 'Field type should not be empty' });

const sourceConditionValidationSchema = {
  field: dataFieldValidationSchema,
  fieldType: fieldTypeValidationSchema,
  subType: getSubTypeSchema({
    ruleSettingsKey: 'conditionTypesOptions',
    conditionType: ConditionRowType.ApplyWhenCondition,
  }),
  values: Joi.alternatives()
    .conditional('subType', {
      not: undefined,
      then: conditionValueValidationSchema,
      otherwise: Joi.array(),
    })
    .messages({ 'any.required': 'Values can not be empty' }),
};

const filterByConditionValidationSchema = {
  field: dataFieldValidationSchema,
  fieldType: fieldTypeValidationSchema,
  subType: getSubTypeSchema({
    ruleSettingsKey: 'conditionTypesOptions',
    conditionType: ConditionRowType.FilterByCondition,
  }),
  values: Joi.alternatives()
    .conditional('subType', {
      not: undefined,
      then: conditionValueValidationSchema,
      otherwise: Joi.array(),
    })
    .messages({ 'any.required': 'Values can not be empty' }),
};

const subRuleValidationSchema = {
  field: dataFieldValidationSchema,
  fieldType: fieldTypeValidationSchema,
  subType: getSubTypeSchema({ ruleSettingsKey: 'subRulesConditionTypes' }),
  values: Joi.alternatives()
    .conditional('subType', {
      not: undefined,
      then: conditionValueValidationSchema,
      otherwise: Joi.array(),
    })
    .messages({ 'any.required': 'Values can not be empty' }),
  position: Joi.optional(),
};

const appliedDateRangeSchema = {
  startDate: Joi.date().required(),
  endDate: Joi.date().greater(Joi.ref('startDate')).required(),
  timeZone: Joi.string().valid().required(),
};

const redirectRuleSchema = {
  targetUrl: Joi.string()
    .regex(
      /^((https|http):\/\/)?(www\.)?[\w#%+.:=~-]{1,256}\.[\d()A-Za-z]{1,6}\b([\w !#%&'()+,./:=?~\u00C0-\u00FF-]*)((?<=\/)@+)?\b([\w !#%&'()+,./:=?~\u00C0-\u00FF-]*)?$/
    )
    .required()
    .messages({
      'string.pattern.base': 'Incorrect url format',
      'string.empty': 'Target URL is required',
      'string.domain': 'Incorrect domain format',
    }),
  displayName: Joi.string()
    .valid()
    .required()
    .messages({ 'string.empty': 'Display name is required' }),
};

const pinToPositionRulesSchema = {
  values: Joi.alternatives()
    .conditional('subType', {
      not: undefined,
      then: conditionValueValidationSchema,
      otherwise: Joi.array().required(),
    })
    .messages({ 'any.required': 'SKU is required' }),
  position: Joi.number()
    .required()
    .messages({
      'any.required': `Position must be between ${MIN_POSITION_VALUE} and ${MAX_POSITION_VALUE}`,
    }),
};

export const formValidationSchema: Record<
  keyof Omit<RuleDraft, 'id' | 'createdAt' | 'updatedAt' | 'entityId'>,
  AnySchema
> = {
  action: Joi.string()
    .required()
    .valid(...getStringEnumValues(MerchandisingRuleTypes.RuleAction)),
  active: Joi.boolean(),
  kpi: Joi.string().optional().allow('').trim(),
  name: Joi.string().required().trim().min(3).messages({
    'string.empty': 'Name can not be empty',
    'string.min': 'Name should be at least 3 characters long',
  }),
  product: Joi.string()
    .required()
    .valid(...getStringEnumValues(SyteProductTypes.SyteProductType))
    .messages({ 'any.required': 'Product can not be empty' }),
  weight: Joi.number().required().min(0).max(100),
  appliedDateRange: Joi.object().allow(null).keys(appliedDateRangeSchema).optional(),
  searchCondition: Joi.object()
    .when('action', {
      is: MerchandisingRuleTypes.RuleAction.Redirect,
      then: Joi.object().keys(searchConditionForRedirectRuleValidationSchema).required(),
      otherwise: Joi.object().keys(searchConditionValidationSchema).optional(),
    })
    .messages({
      'array.min': 'Please, add at least one term',
      'any.invalid': 'Search condition is required',
    }),
  redirectRule: Joi.object()
    .when('action', {
      is: MerchandisingRuleTypes.RuleAction.Redirect,
      then: Joi.object().keys(redirectRuleSchema).required(),
      otherwise: Joi.object().keys(redirectRuleSchema).optional().allow(null),
    })
    .messages({
      'any.required': 'Please, fill in redirect details',
      'object.base': 'Please, fill in redirect details',
    }),
  context: Joi.array()
    .min(0)
    .items(Joi.string().pattern(/^[a-zA-Z0-9_]+$/, 'valid characters'))
    .messages({
      'string.pattern.name':
        'Context must contain only valid items: letters, numbers, or underscores (no spaces or special characters).',
    }),
  sourceCondition: Joi.array().items(sourceConditionValidationSchema).min(0),
  filterByCondition: Joi.array().items(filterByConditionValidationSchema),
  subRules: Joi.array()
    .when('action', {
      switch: [
        {
          is: MerchandisingRuleTypes.RuleAction.Redirect,
          then: Joi.array().items(subRuleValidationSchema).optional(),
        },
        {
          is: MerchandisingRuleTypes.RuleAction.PinToPosition,
          then: Joi.array()
            .items(pinToPositionRulesSchema)
            .min(1)
            .unique('position')
            .unique('values')
            .messages({
              'array.unique': 'Value should be unique',
            }),
        },
      ],
      otherwise: Joi.array().items(subRuleValidationSchema).min(1),
    })
    .messages({ 'array.min': 'Please, add at least one condition action' }),
  regions: Joi.array()
    .items(Joi.string())
    .custom((selectedRegions, { prefs: { context }, error }) => {
      const { availableRegions } = getContextValues({ context });

      const isUsingInvalidRegion = !validateRuleRegionsAreAvailable({
        selectedRegions,
        availableRegions,
      });

      if (isUsingInvalidRegion) {
        return error('any.invalid-region');
      }

      const isShopMultiLocale = Boolean(availableRegions.length);
      const isNumberSelectedRegionsValid =
        (!isShopMultiLocale && selectedRegions.length === 0) ||
        (isShopMultiLocale && selectedRegions.length > 0);

      if (!isNumberSelectedRegionsValid) {
        return error('any.invalid-number-locales-selected');
      }

      return selectedRegions;
    })
    .messages({
      'any.invalid-number-locales-selected':
        'Please select at least one region to apply the rule for',
      'any.invalid-region':
        'This rule is invalid because one or more of the regions do not exist for this shop',
    }),
};
