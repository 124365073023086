import React, { useEffect, useMemo, useState } from 'react';
import {
  DataUsageModeEnum,
  InsightsFilters,
  kpiEquasionText,
  kpiNameByType,
  KPITypesEnum,
} from 'src/services';
import { useFetchKpiData } from './useFetchKpiData';
import {
  AvailableIcons,
  EllipsisWithTooltip,
  Icon,
  Skeleton,
  Tooltip,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import {
  InsightsKPIFooterStyled,
  InsightsKPIHeaderStyled,
  InsightsKPIStyled,
  KpiInfoWrapperStyled,
  SeparatorStyled,
} from '../Insights.styles';
import { createKpiMainText, customPrecision } from './helpers';
import { KpiDataState } from './kpiItem.types';

interface KPIItemProps {
  shopId: number;
  isWaitingForVerification: boolean;
  dataUsageMode: DataUsageModeEnum;
  kpiType: KPITypesEnum;
  filters: InsightsFilters;
}

const CURRENCY = 'USD';

export const KPIItem = ({
  shopId,
  kpiType,
  filters,
  dataUsageMode,
  isWaitingForVerification,
}: KPIItemProps) => {
  const [kpiState, setKpiState] = useState<KpiDataState>({
    isLoading: isWaitingForVerification,
    isError: false,
  });

  const { debouncedFetchData } = useFetchKpiData({
    shopId,
    kpiType,
    filters,
    dataUsageMode,
    setKpiData: setKpiState,
  });

  useEffect(() => {
    if (!isWaitingForVerification) {
      setKpiState({ isLoading: true, data: null, isError: false });
      debouncedFetchData();
    }
  }, [filters, shopId, isWaitingForVerification]);

  const KpiContent = useMemo(() => {
    const processedValue = customPrecision({ number: kpiState?.data?.value });

    const valueText = createKpiMainText({
      value: processedValue,
      valueType: kpiState?.data?.valueType,
    });

    const mainValue = (
      <EllipsisWithTooltip tooltipText={valueText} maxWidth={400}>
        <Typography variant={TypographyVariant.MediumBold} type={TypographyType.Heading}>
          {valueText}
        </Typography>
      </EllipsisWithTooltip>
    );

    const toolTipValue = kpiEquasionText[kpiType];

    const toolTip = toolTipValue ? (
      <Tooltip value={toolTipValue} position='top center'>
        <KpiInfoWrapperStyled>
          <Icon name={AvailableIcons.InformationV2} />
        </KpiInfoWrapperStyled>
      </Tooltip>
    ) : null;

    switch (kpiType) {
      case KPITypesEnum.AOV:
      case KPITypesEnum.ARPU: {
        return (
          <>
            {mainValue}
            <SeparatorStyled />
            <Typography variant={TypographyVariant.LargeMedium} type={TypographyType.Body}>
              {CURRENCY}
            </Typography>
            {toolTip}
          </>
        );
      }
      default: {
        return (
          <>
            {mainValue}
            {toolTip}
          </>
        );
      }
    }
  }, [kpiType, kpiState.data]);

  return (
    <InsightsKPIStyled>
      <InsightsKPIHeaderStyled>
        {kpiState.isLoading ? (
          <div style={{ height: '48px' }}>
            <Skeleton width={200} height={20} />
          </div>
        ) : (
          <>{KpiContent}</>
        )}
      </InsightsKPIHeaderStyled>
      <InsightsKPIFooterStyled>
        <Typography variant={TypographyVariant.MediumRegular} type={TypographyType.Body}>
          {kpiNameByType[kpiType]}
        </Typography>
      </InsightsKPIFooterStyled>
    </InsightsKPIStyled>
  );
};
