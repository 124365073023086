import styled from '@emotion/styled';

export const ProductTiles = styled.div`
  display: grid;
  gap: 24px;
  width: 100%;

  @media (min-width: 1300px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 1299px) and (min-width: 821px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 820px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
