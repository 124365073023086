import React from 'react';
import classNames from 'classnames';
import { VariantType } from 'src/services';
import {
  Table,
  Column,
  EmptyTable,
  EditableNumericInputCell,
  Button,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';

import { ExperimentVariantNameCell, PRODUCTION_FIELD_NAME } from '../../ExperimentVariantNameCell';
import {
  VariantsTableProps,
  VariantTableRecord,
  SourceCellProps,
  ControlGroupDiffCellProps,
} from './types';
import { useVariantsTable } from './useVariantsTable';
import { variantsTableActionMethods } from './Actions';
import './VariantsTable.scss';

const SourceCell = <T extends Record<string, unknown>, V extends VariantType | string>({
  row,
  dispatch,
  isExperimentEnded,
  isExperimentEditable,
}: SourceCellProps<T, V>): JSX.Element => {
  const variant = (row as any).original as VariantTableRecord;
  const variantType = variant.type;
  const isProd = variantType === PRODUCTION_FIELD_NAME;
  const className = 'variant-cell-source';
  const isBtnDisabled = isExperimentEnded ? false : !isExperimentEditable;
  const btnName = isExperimentEnded ? 'View' : 'Edit';

  const onEditVariant = () => {
    dispatch(variantsTableActionMethods.selectVariant({ variantId: String(variant.id) }));
  };

  return isProd ? (
    <Typography
      className={classNames(className, 'production')}
      type={TypographyType.Body}
      variant={TypographyVariant.MediumRegular}
    >
      Published version
    </Typography>
  ) : (
    <Button
      className={className}
      onClick={onEditVariant}
      variant='primary'
      disabled={isBtnDisabled}
    >
      {btnName}
    </Button>
  );
};

const ControlGroupDiffCell = <T extends Record<string, unknown>, V extends VariantType | string>({
  row,
  dispatch,
}: ControlGroupDiffCellProps<T, V>): JSX.Element => {
  const variant = (row as any).original as VariantTableRecord;
  const variantType = variant.type;
  const isProd = variantType === PRODUCTION_FIELD_NAME;
  const className = 'variant-cell-source';
  const hasCompareButton = isProd;
  const onCompareVariant = () => {
    dispatch(variantsTableActionMethods.compareVariant({ variantId: String(variant.id) }));
  };

  return hasCompareButton ? null : (
    <Button className={className} onClick={onCompareVariant} variant='secondary'>
      {'Compare variant'}
    </Button>
  );
};

export const VariantsTable = ({
  experiment,
  variantsDraft,
  shopId,
  errors,
  isExperimentEditable,
  isExperimentEnded,
  selectedVariantId,
  dispatch,
  updateVariantsState,
}: VariantsTableProps): JSX.Element => {
  const useVariantsTableReturnType = useVariantsTable({
    experiment,
    variantsDraft,
    shopId,
    errors,
    isExperimentEditable,
    isExperimentEnded,
    selectedVariantId,
    dispatch,
    updateVariantsState,
  });

  const { tableState, updateData, getCellProps } = useVariantsTableReturnType;

  return (
    <Table
      data={tableState}
      className='syte-variants-table'
      updateData={updateData}
      disableSortBy={false}
      enableResizing
      getCellProps={getCellProps}
    >
      <Column
        Header='Name'
        id='title'
        accessor='title'
        width={200}
        minWidth={100}
        Cell={ExperimentVariantNameCell}
      />
      <Column Header='Custom Name' id='name' accessor='name' width={200} minWidth={70} />

      <Column
        Header='Allocation'
        id='trafficAllocation'
        accessor='trafficAllocation'
        width={80}
        minWidth={70}
        maxWidth={120}
        Cell={EditableNumericInputCell}
      />
      <Column
        Header='Source'
        id='source'
        accessor='source'
        width={100}
        minWidth={50}
        Cell={SourceCell}
      />
      <Column
        Header='Control Group Diff'
        id='controlGroupDiff'
        accessor='controlGroupDiff'
        width={120}
        minWidth={70}
        Cell={ControlGroupDiffCell}
      />
      <EmptyTable>
        <h1 className='no-rows'>There are no variants in this experiment</h1>
      </EmptyTable>
    </Table>
  );
};
