import { EditExperimentContainerAction } from 'src/containers';
import {
  BannerStrategy,
  defaultGridConfig,
  defaultIconConfig,
  DiscoveryBannerSettingsReducerState,
} from '../app-state-types';
import {
  DiscoveryBannerActionTypes,
  DiscoveryBannerSettingsAction,
  VariantPanelAction,
} from '../components-bl';
import { BannerLayout, ImageLayout } from '../app-state-types/reducer-state-types/discovery-banner';

const initialState: DiscoveryBannerSettingsReducerState = {
  general: {
    bannerLayout: BannerLayout.LEFT_TO_RIGHT,
    imageLayout: ImageLayout.GRID,
    bannerBackgroundColor: '#FFFFFF',
  },
  banner: {
    bannerColor: '#000',
    bannerFontFamily: 'Helvetica,sans-serif',
    bannerFontSize: 18,
  },
  button: {
    buttonBackgroundColor: '#FFFFFF',
    buttonColor: '#000000',
    buttonFontFamily: 'Helvetica,sans-serif',
    buttonFontSize: 16,
    icon: defaultIconConfig,
  },
  icon: defaultIconConfig,
  mobileGrid: defaultGridConfig,
  desktopGrid: defaultGridConfig,
  isSameGrid: true,
  strategy: BannerStrategy.Similars,

  shouldRefetch: false,
};

export function discoveryBannerSettingsReducer(
  state: DiscoveryBannerSettingsReducerState = initialState,
  action?: DiscoveryBannerSettingsAction | EditExperimentContainerAction | VariantPanelAction
): DiscoveryBannerSettingsReducerState {
  switch (action?.type) {
    case DiscoveryBannerActionTypes.GetDiscoveryBannerSettingsSuccess: {
      const { general, banner, button, icon, mobileGrid, desktopGrid, isSameGrid, strategy } =
        action.payload;
      return {
        ...state,
        general,
        banner,
        button,
        icon,
        mobileGrid,
        desktopGrid,
        isSameGrid,
        strategy,
        shouldRefetch: false,
      };
    }
    case DiscoveryBannerActionTypes.GetDiscoveryBannerSettingsRequest:
      return { ...state };
    case DiscoveryBannerActionTypes.GetDiscoveryBannerSettingsError:
      return { ...state };
    case DiscoveryBannerActionTypes.UpdateDiscoveryBannerSettingsSuccess: {
      const { general, banner, button, icon, mobileGrid, desktopGrid, isSameGrid, strategy } =
        action.payload;
      const updatedSettings = {
        ...state,
        general,
        banner,
        button,
        icon,
        mobileGrid,
        desktopGrid,
        isSameGrid,
        strategy,
      };
      return {
        ...state,
        general: updatedSettings.general,
        banner: updatedSettings.banner,
        button: updatedSettings.button,
        icon: updatedSettings.icon,
        mobileGrid: updatedSettings.mobileGrid,
        desktopGrid: updatedSettings.desktopGrid,
        isSameGrid: updatedSettings.isSameGrid,
        strategy: updatedSettings.strategy,
      };
    }
    default:
      return state;
  }
}
