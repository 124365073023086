import React, { useCallback } from 'react';
import {
  LanguageCard,
  cardColors,
  LanguageListLayoutStyled,
} from 'src/components-dummy/LanguageCard';
import { languagePropsMap } from 'src/components-bl/AugmentedSearch/helpers';
import { SynonymsTitleStyled } from 'src/containers/AugmentedSearch/components/SynonymsContainer.styles';
import { Dispatch } from 'src/components-bl/types';
import { generatePath } from 'react-router';
import { synonymsActions } from './SynonymsActions';
import { RoutedComponentProps } from 'src/app-routes';
import { AugmentedSearchWrapper } from '../AugmentedSearch.styles';
import { MissingAugmentedSearchCatalogCard } from '../MissingAugmentedSearchCatalogCard';
import { Skeleton } from 'src/components-dummy';
import {
  SynonymLanguage,
  SynonymLanguageCode,
} from 'src/services/src/service/augmented-search/synonyms/types';
interface SynonymsLanguageProps extends RoutedComponentProps {
  availableLanguages: string[];
  hasAugmentedSearchCatalog: boolean | null;
  shopId: number | undefined;
  dispatch: Dispatch;
}

export function SynonymsLanguagePage({
  availableLanguages,
  hasAugmentedSearchCatalog,
  shopId,
  dispatch,
  permittedRouteMap,
}: SynonymsLanguageProps): JSX.Element {
  const onCardClick = useCallback(
    (languageCode: string) => {
      const language = SynonymLanguageCode[languageCode as keyof typeof SynonymLanguage];
      if (permittedRouteMap.augmentedSearchSynonymsLanguage && language && shopId) {
        dispatch(
          synonymsActions.navigateTo({
            navigateTo: generatePath(permittedRouteMap.augmentedSearchSynonymsLanguage.path, {
              shopId,
              language,
            }),
          })
        );
      }
    },
    [dispatch, shopId, permittedRouteMap.augmentedSearchSynonymsLanguage]
  );

  const getCardColor = (index: number) => {
    const color = cardColors[index % cardColors.length];
    return color;
  };

  const renderLanguageCards = () => {
    return availableLanguages.map((languageCode, index) => {
      const { iconName, displayName } = languagePropsMap[languageCode] || {};

      if (!iconName || !displayName) return null;

      return (
        <LanguageCard
          key={languageCode}
          displayName={displayName}
          iconName={iconName}
          color={getCardColor(index)}
          onClick={() => onCardClick(languageCode)}
        />
      );
    });
  };

  const isLoading = !shopId || hasAugmentedSearchCatalog === null;

  if (isLoading) {
    return (
      <AugmentedSearchWrapper>
        <LanguageListLayoutStyled>
          <Skeleton width={271} height={56} repeat={2} />
        </LanguageListLayoutStyled>
      </AugmentedSearchWrapper>
    );
  }

  return (
    <AugmentedSearchWrapper>
      {hasAugmentedSearchCatalog ? (
        <>
          <SynonymsTitleStyled>I want to organize the language:</SynonymsTitleStyled>
          <LanguageListLayoutStyled>{renderLanguageCards()}</LanguageListLayoutStyled>
        </>
      ) : (
        <MissingAugmentedSearchCatalogCard />
      )}
    </AugmentedSearchWrapper>
  );
}
