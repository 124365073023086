import React, { useCallback, useRef, useState } from 'react';
import { ParseJoiValidateResponse } from 'src/utils';
import { Typography, TypographyType, TypographyVariant, Icon } from 'src/components-dummy';
import { IconSettings } from '../IconSettings';
import { AvailableIcons } from 'src/components-dummy/Icon/AvailableIcons';
import { useOnClickOutside } from 'src/hooks/useOnClickOutside';
import { DiscoveryBannerGeneralSettings } from 'src/services/src/service/types/syte-products/discovery-banner';
import button from '../../Assets/images/button.jpg';
import { DiscoveryBannerSettingsRecord } from '../../types';
import { handleUpdateColor } from '../../colorToHex';
import {
  BannerSetting,
  ChromePickerStyled,
  ColorPickerButton,
  Container,
  Content,
  Header,
  InfoButton,
  InfoButtonTitle,
  InfoModalStyled,
  NumericInputStyled,
  TextBoxStyled,
} from '../../DiscoveryBannerSettings.styles';

export interface CtaButtonProps {
  discoveryBannerSettings: DiscoveryBannerGeneralSettings['generalSettings'];
  onChange: (args: Partial<DiscoveryBannerGeneralSettings['generalSettings']['button']>) => void;
  disabled?: boolean;
  errors: ParseJoiValidateResponse<DiscoveryBannerSettingsRecord>;
}

export const CtaButton = ({
  discoveryBannerSettings,
  onChange,
  disabled = false,
  errors,
}: CtaButtonProps): JSX.Element | null => {
  const [showButtonBackgroundColorPicker, setShowButtonBackgroundColorPicker] = useState(false);
  const buttonBackgroundColorRef = useRef(null);
  useOnClickOutside(buttonBackgroundColorRef, () => {
    setShowButtonBackgroundColorPicker(false);
  });

  const [showButtonFontColorPicker, setShowButtonFontColorPicker] = useState(false);
  const buttonFontColorRef = useRef(null);
  useOnClickOutside(buttonFontColorRef, () => {
    setShowButtonFontColorPicker(false);
  });

  const [showButton, setButtonModalIsOpened] = useState(false);
  const onButtonModalClose = () => {
    setButtonModalIsOpened(false);
  };

  const onShowIconToggle = useCallback(() => {
    onChange({
      icon: {
        ...discoveryBannerSettings.button.icon,
        showIcon: !discoveryBannerSettings.button.icon.showIcon,
      },
    });
  }, [onChange, discoveryBannerSettings]);

  const onIconColorChange = useCallback(
    (iconColor: string) => {
      onChange({
        icon: {
          ...discoveryBannerSettings.button.icon,
          iconColor,
        },
      });
    },
    [onChange, discoveryBannerSettings]
  );

  return (
    <Container>
      <Header>
        <Typography variant={TypographyVariant.MediumBold} type={TypographyType.Body}>
          CTA button
        </Typography>
        <InfoButton onClick={() => setButtonModalIsOpened(true)}>
          <InfoButtonTitle variant={TypographyVariant.SmallRegular}>
            What is Shop similar button
          </InfoButtonTitle>
          <Icon name={AvailableIcons.TooltipQuestionmarkBlue} />
        </InfoButton>
        <InfoModalStyled
          isOpened={showButton}
          closeBtnTxt='Close'
          onClickOutside={onButtonModalClose}
          title='What is Shop similar button?'
          maxWidth={50}
        >
          <img src={button} alt='Button example' />
        </InfoModalStyled>
      </Header>
      <Content>
        <BannerSetting ref={buttonBackgroundColorRef}>
          <TextBoxStyled
            label='Button Background Color'
            placeholder={discoveryBannerSettings.button.buttonBackgroundColor}
            error={!!errors.name}
            value={discoveryBannerSettings.button.buttonBackgroundColor}
            fullWidth
            onChange={backgroundColor => {
              onChange({ buttonBackgroundColor: backgroundColor });
            }}
            disabled={disabled}
          >
            <ColorPickerButton
              type='button'
              aria-label='background-color-mock'
              onClick={() => setShowButtonBackgroundColorPicker(!showButtonBackgroundColorPicker)}
              style={{
                backgroundColor: discoveryBannerSettings.button.buttonBackgroundColor,
              }}
              disabled={disabled}
            />
            {showButtonBackgroundColorPicker && (
              <ChromePickerStyled
                color={discoveryBannerSettings.button.buttonBackgroundColor}
                onChange={updatedColor => {
                  handleUpdateColor(updatedColor, newColor => {
                    onChange({ buttonBackgroundColor: newColor });
                  });
                }}
              />
            )}
          </TextBoxStyled>
        </BannerSetting>
        <BannerSetting ref={buttonFontColorRef}>
          <TextBoxStyled
            label='Button Text Color'
            placeholder={discoveryBannerSettings.button.buttonColor}
            error={!!errors.name}
            value={discoveryBannerSettings.button.buttonColor}
            fullWidth
            onChange={fontColor => {
              onChange({ buttonColor: fontColor });
            }}
            disabled={disabled}
          >
            <ColorPickerButton
              type='button'
              aria-label='background-color-mock'
              onClick={() => setShowButtonFontColorPicker(!showButtonFontColorPicker)}
              style={{
                backgroundColor: discoveryBannerSettings.button.buttonColor,
              }}
              disabled={disabled}
            />
            {showButtonFontColorPicker && (
              <ChromePickerStyled
                color={discoveryBannerSettings.button.buttonColor}
                onChange={updatedColor => {
                  handleUpdateColor(updatedColor, newColor => {
                    onChange({ buttonColor: newColor });
                  });
                }}
              />
            )}
          </TextBoxStyled>
        </BannerSetting>
        <BannerSetting>
          <TextBoxStyled
            label='Button Font Family'
            placeholder='sans-serif'
            error={!!errors.name}
            value={discoveryBannerSettings.button.buttonFontFamily}
            fullWidth
            onChange={fontFamily => {
              onChange({ buttonFontFamily: fontFamily });
            }}
            disabled={disabled}
          />
        </BannerSetting>
        <BannerSetting>
          <Typography variant={TypographyVariant.MediumRegular} type={TypographyType.Body}>
            Button Font Size
          </Typography>
          <NumericInputStyled
            placeholder='24'
            error={!!errors.numberOfResultsToDisplay}
            value={discoveryBannerSettings.button.buttonFontSize}
            onChange={value => {
              onChange({ buttonFontSize: value });
            }}
            disabled={disabled}
          />
        </BannerSetting>
        <IconSettings
          title='Show Button Icon'
          showIcon={discoveryBannerSettings.button.icon.showIcon}
          iconColor={discoveryBannerSettings.button.icon.iconColor}
          onShowIconToggle={onShowIconToggle}
          onIconColorChange={onIconColorChange}
          errors={errors}
          disabled={disabled}
        />
      </Content>
    </Container>
  );
};
