import { IKpiDataReponse, KPIValueTypeEnum } from 'src/services/src';
import { addSeparatorsToNumber } from 'src/services/src/common/utils';

export const createKpiMainText = ({
  value,
  valueType,
}: {
  value: number;
  valueType: IKpiDataReponse['valueType'];
}): string => {
  if (!value) return '0';

  switch (valueType) {
    case KPIValueTypeEnum.Percentage:
      return `${value.toFixed(2)}%`;
    case KPIValueTypeEnum.Number:
    case KPIValueTypeEnum.Money:
    default:
      return `${addSeparatorsToNumber(value)}`;
  }
};

export const customPrecision = ({
  number,
  precision = 4,
}: {
  number?: number;
  precision?: number;
}): number => {
  if (!number) return 0;

  const [integerPart, decimalPart] = number.toString().split('.');

  if (!decimalPart) return number;

  const truncatedDecimal = decimalPart.slice(0, precision);
  return Number(`${integerPart}.${truncatedDecimal}`);
};
