import { ChromePicker } from 'react-color';
import styled from '@emotion/styled';
import { InfoModal, NumericInput, Select, TextBox, Typography } from '../../components-dummy';

export const DiscoverBannerSettingsContainer = styled.div`
  padding-bottom: 60px;
  color: #1e1e1e;
`;

export const DiscoverBannerSettingsDescription = styled(Typography)`
  padding-bottom: 8px;
`;

export const Container = styled.div`
  width: 100%;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  padding: 20px 15px;
  margin-top: 48px;
`;

export const Header = styled.div`
  border-bottom: 1px solid #dedede;
  padding-bottom: 10px;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
`;

export const InfoButton = styled.button`
  display: flex;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
`;

export const InfoButtonTitle = styled(Typography)`
  color: #0073ff;
  padding-right: 10px;
`;

export const Content = styled.div`
  padding-top: 24px;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 30px;
`;

export const BannerSetting = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 200px;
`;

export const TextBoxStyled = styled(TextBox)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  label.MuiFormLabel-root {
    margin-bottom: 0 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    display: initial !important;
    transform: unset !important;
  }
  .input-base-wrapper {
    margin-top: 8px;
    align-items: center;
  }
`;

export const ColorPickerButton = styled.button`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 1px solid #404040;
  display: inline-block;
  visibility: visible;
  margin-right: 5px;
  cursor: pointer;

  :disabled {
    cursor: auto;
  }
`;

export const Dropdown = styled(Select)`
  min-width: 200px;
  padding-top: 8px;
`;

export const InfoModalAlignmentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding: 0 40px 40px;
  gap: 24px;
`;

export const InfoCard = styled.div`
  display: grid;
`;

export const InfoCardTitle = styled(Typography)`
  padding: 0 0 10px 5px;
`;

export const InfoModalLayoutContainer = styled(InfoModalAlignmentContainer)`
  flex-direction: column;
`;

export const InfoModalLayoutContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ChromePickerStyled = styled(ChromePicker)`
  margin: 40px 0 0 40px;
  position: absolute;
  z-index: 9999;
`;

export const CameraIconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const CameraIconTitle = styled(Typography)`
  color: #6e6e71;
`;

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const ToggleTitle = styled(Typography)`
  color: #6e6e71;
`;

export const NumericInputStyled = styled(NumericInput)`
  margin-top: 8px;
`;

export const InfoModalStyled = styled(InfoModal)<{ maxWidth: number }>`
  .syte-modal-content {
    max-height: 100%;
    max-width: ${({ maxWidth }) => `${maxWidth}%`};
  }
  img {
    max-width: 90%;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentTitle = styled(Typography)`
  padding-top: 32px;
`;

export const DropdownContainer = styled.div`
  width: 200px;
  padding-top: 48px;
`;
