import styled from '@emotion/styled';
import { ConfirmationDialog, Typography } from '../../../../components-dummy';
import { FileUploadButton } from '../../../../components-dummy/FileUploader';
import { LinkButton } from '../../../../components-dummy/LinkButton';

export const UploadConfirmationDialogStyled = styled(ConfirmationDialog)`
  width: 520px;
  height: 375px;
  color: #1e1e1e;
`;

export const UploadProgressStyled = styled(Typography)`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const FileUploadButtonStyled = styled(FileUploadButton)`
  margin-top: 30px;
`;

export const ButtonContentStyled = styled.span`
  display: flex;
  gap: 10px;

  svg {
    path {
      stroke: ${({ theme }) => theme.palette.common.white};
    }
  }
`;

export const DownloadExampleLinkButtonStyled = styled(LinkButton)`
  display: flex;
  align-items: center;
  margin-top: 10px;

  svg {
    margin-right: 5.5px;
    path {
      stroke: ${({ theme }) => {
        return theme.palette.custom['primary-main'];
      }} !important;
    }
  }
`;
