import { KPITypesEnum } from 'src/services';

export const KPIS_ORDERED_LIST = [
  KPITypesEnum.Sessions,
  KPITypesEnum.Transactions,
  KPITypesEnum.ARPU,
  KPITypesEnum.CTR,
  KPITypesEnum.CVR,
  KPITypesEnum.AOV,
  KPITypesEnum.Impressions,
  KPITypesEnum.Users,
];
