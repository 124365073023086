import React, { useCallback, useState } from 'react';
import { ParseJoiValidateResponse } from 'src/utils';
import { Typography, TypographyType, TypographyVariant, Icon } from 'src/components-dummy';
import { IconSettings } from '../IconSettings';
import { AvailableIcons } from 'src/components-dummy/Icon/AvailableIcons';
import { DiscoveryBannerGeneralSettings } from 'src/services/src/service/types/syte-products/discovery-banner';
import cameraIcon from '../../Assets/images/cameraIcon.jpg';
import { DiscoveryBannerSettingsRecord } from '../../types';
import {
  Container,
  Content,
  Header,
  InfoButton,
  InfoButtonTitle,
  InfoModalStyled,
} from '../../DiscoveryBannerSettings.styles';

export interface CameraIconProps {
  discoveryBannerSettings: DiscoveryBannerGeneralSettings['generalSettings'];
  onChange: (args: Partial<DiscoveryBannerGeneralSettings['generalSettings']['icon']>) => void;
  disabled?: boolean;
  errors: ParseJoiValidateResponse<DiscoveryBannerSettingsRecord>;
}

export const CameraIcon = ({
  discoveryBannerSettings,
  onChange,
  disabled = false,
  errors,
}: CameraIconProps): JSX.Element | null => {
  const [showCameraIcon, setModalCameraIconIsOpened] = useState(false);
  const onCameraIconModalClose = () => {
    setModalCameraIconIsOpened(false);
  };

  const onShowIconToggle = useCallback(() => {
    onChange({
      showIcon: !discoveryBannerSettings.icon.showIcon,
    });
  }, [onChange, discoveryBannerSettings]);

  const onIconColorChange = useCallback(
    (iconColor: string) => {
      onChange({ iconColor });
    },
    [onChange]
  );

  return (
    <Container>
      <Header>
        <Typography variant={TypographyVariant.MediumBold} type={TypographyType.Body}>
          Camera icon
        </Typography>
        <InfoButton onClick={() => setModalCameraIconIsOpened(true)}>
          <InfoButtonTitle variant={TypographyVariant.SmallRegular}>
            What is camera icon
          </InfoButtonTitle>
          <Icon name={AvailableIcons.TooltipQuestionmarkBlue} />
        </InfoButton>
        <InfoModalStyled
          isOpened={showCameraIcon}
          closeBtnTxt='Close'
          onClickOutside={onCameraIconModalClose}
          title='What is camera icon?'
          maxWidth={50}
        >
          <img src={cameraIcon} alt='Camera icon example' />
        </InfoModalStyled>
      </Header>
      <Content>
        <IconSettings
          title='Show Camera Icon'
          showIcon={discoveryBannerSettings.icon.showIcon}
          iconColor={discoveryBannerSettings.icon.iconColor}
          onShowIconToggle={onShowIconToggle}
          onIconColorChange={onIconColorChange}
          errors={errors}
          disabled={disabled}
        />
      </Content>
    </Container>
  );
};
