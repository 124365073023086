import styled from '@emotion/styled';
import React from 'react';
import { ListV2, SearchInput, Typography } from 'src/components-dummy';

export const SearchInputStyled = styled(SearchInput)`
  padding-bottom: 5px;
`;

const DropdownContentBase = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
  (props, ref) => <div {...props} ref={ref} />
);

export const DropdownContentStyled = styled(DropdownContentBase)<{
  width: number;
}>`
  padding: 8px;
  width: ${({ width }) => `${width}px`};
  border-radius: 8px;

  .input-base-wrapper {
    background-color: ${({ theme }) => theme.palette.custom['gray-80']};
  }

  p:first-of-type {
    padding-bottom: 10px;
    padding-left: 10px;
  }

  .Mui-selected {
    background: rgba(25, 118, 210, 0.08) !important;
  }
`;

export const ListV2Styled = styled(ListV2)`
  width: 100%;
  padding: 0;

  max-height: 165px;
  min-height: 20px;

  overflow: hidden;
  margin: 0;
  gap: 16px;

  &:hover {
    overflow: auto;
  }

  li {
    margin-top: 2px;
    padding: 8px;
    border-radius: 4px;
  }

  li:hover {
    background: ${({ theme }) => theme.palette.custom['gray-90']};
    cursor: pointer;
  }
`;

/**
 * DropdownStyled
 */
export const DropdownStyled = styled.div<{ isOpened: boolean }>`
  display: flex;
  width: 100%;
  padding: 12px;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;

  border-radius: 4px;
  background: ${({ isOpened }) =>
    isOpened ? 'rgb(204, 227, 255)' : 'var(--greys-grey-80-crads-stroke, #f2f2f2)'};
  justify-content: space-between;
  overflow: hidden;
`;

export const ExpandMoreIconWrapperStyled = styled.div<{ isDown: boolean }>`
  display: flex;
  align-items: center;
  transform: ${({ isDown }) => (isDown ? 'rotateX(180deg)' : 'none')};
  svg {
    stroke-width: 1.5;
  }
`;

const SearchableDropdownWithTriggerBase = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>((props, ref) => <div {...props} ref={ref} />);

export const SearchableDropdownWithTriggerStyled = styled(SearchableDropdownWithTriggerBase)`
  display: flex;
  width: 100%;
  & > div:first-of-type {
    width: 100%;
  }
`;

export const PlaceholderStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-20']};
`;

export const EllipsisWithTooltipWrapperStyled = styled.div`
  height: 100%;
  width: 90%;
  display: flex;
  align-items: center;
  overflow: hidden;
  flex: 1;

  .ellipsis-with-tooltip {
    height: 100%;
    display: flex;
    align-items: center;
    width: unset;
  }
`;
