import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  InsightsContainerStyled,
  InsightsContentStyled,
  InsightsContentTitleStyled,
  InsightsHeaderActionsStyled,
  InsightsHeaderFiltersStyled,
  InsightsHeaderStyled,
  InsightsKPIsListStyled,
  LastUpdatedAtStyled,
  SelectProductWrapperStyled,
  SelectTimeRangeStyled,
  SelectTimeRangeWrapperStyled,
} from './Insights.styles';
import {
  AvailableIcons,
  Button,
  Icon,
  MenuItem,
  SelectOnChangeEvent,
  SelectType,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import {
  InsightsFilters,
  SupportedTimeIntervals,
  TimeIntervalsEnum,
  presetTimeRangeTextByValue,
} from 'src/services/src';
import { KPIItem } from './KpiItem/KpiItem';
import { Dispatch } from 'src/components-dummy/types';
import { SearchableDropdownWithTrigger } from '../CatalogManager/CatalogExplorer/CatalogExplorerDisplayPreferences/SearchableDropdownWithTrigger/SearchableDropdownWithTrigger';
import { ALL_PRODUCTS_OPTION, useInsights } from './useInsights';
import { SectionWithTitle } from '../Home/components/SectionWithTitle/SectionWithTitle';
import { getLastUpdatedText } from './insights.helpers';
import { KPIS_ORDERED_LIST } from './insights.config';

const filtersStateInitial = {
  timeRange: TimeIntervalsEnum.Last1Week,
  product: undefined,
};

interface InsightsProps {
  shopId: number;
  dispatch: Dispatch;
}
export const Insights = ({ shopId, dispatch }: InsightsProps) => {
  const [filtersState, setFiltersState] = useState<InsightsFilters>(filtersStateInitial);

  const { manageInitialData, shopMetdata, productsNames, kpisTypesList, isWaitingForProducts } =
    useInsights({
      shopId,
      dispatch,
    });

  const onTimeRangeChange: SelectOnChangeEvent = event => {
    setFiltersState(prev => {
      return { ...prev, timeRange: event.target.value as TimeIntervalsEnum };
    });
  };

  const onOpenTableau = () => {
    window.open('https://eu-west-1a.online.tableau.com/#/site/syteanalytics/home', '_blank');
  };

  const onProductFilter = useCallback((product: string) => {
    setFiltersState(prev => ({ ...prev, product }));
  }, []);

  const mapProductNamesToMenu = (productNames: string[]) => {
    return productNames.map(name => {
      return {
        value: name,
        text: name,
      };
    });
  };

  useEffect(() => {
    manageInitialData();
  }, [shopId]);

  const lastUpdatedAt = useMemo(() => {
    if (!shopMetdata?.updatedBatchIdAt) return null;

    const isCachedData = [TimeIntervalsEnum.Last1Week, TimeIntervalsEnum.Last1Month].includes(
      filtersState?.timeRange
    );

    if (!isCachedData) return null;

    return (
      <Typography variant={TypographyVariant.SmallRegular} type={TypographyType.Body}>
        *Last updated{' '}
        {getLastUpdatedText({
          updatedAt: new Date(shopMetdata.updatedBatchIdAt),
          createdAt: new Date(),
        })}
      </Typography>
    );
  }, [shopMetdata?.updatedBatchIdAt, filtersState?.timeRange]);

  const productNamesSelectOptions = useMemo(() => {
    const options = mapProductNamesToMenu(productsNames || []);
    return [ALL_PRODUCTS_OPTION, ...options];
  }, [productsNames]);

  const orderedKPIs = useMemo(() => {
    if (!kpisTypesList) return [];

    const kpisTypesListSet = new Set([...kpisTypesList]);

    return KPIS_ORDERED_LIST.reduce((prev, next) => {
      if (kpisTypesListSet.has(next)) {
        prev.push(next);
      }

      return prev;
    }, []);
  }, [kpisTypesList]);

  if (!shopMetdata) {
    return null;
  }

  return (
    <SectionWithTitle title='Syte Spotlight'>
      <InsightsContainerStyled>
        <InsightsHeaderStyled>
          <InsightsHeaderFiltersStyled>
            <SelectProductWrapperStyled>
              <SearchableDropdownWithTrigger
                options={productNamesSelectOptions}
                staticWidthInPixel={369}
                value={filtersState.product}
                onChange={onProductFilter}
                disabled={isWaitingForProducts}
              />
              <Icon className='filter-icon' name={AvailableIcons.Filter} />
            </SelectProductWrapperStyled>
            <SelectTimeRangeWrapperStyled>
              <SelectTimeRangeStyled
                type={SelectType.Menu}
                value={filtersState.timeRange}
                prefixId='insights'
                onChange={onTimeRangeChange}
              >
                {SupportedTimeIntervals.map(option => {
                  return (
                    <MenuItem key={option} value={option}>
                      {presetTimeRangeTextByValue[option]}
                    </MenuItem>
                  );
                })}
              </SelectTimeRangeStyled>
              <Icon className='calendar-icon' name={AvailableIcons.Calendar} />
            </SelectTimeRangeWrapperStyled>
            <LastUpdatedAtStyled>{lastUpdatedAt}</LastUpdatedAtStyled>
          </InsightsHeaderFiltersStyled>
          <InsightsHeaderActionsStyled>
            <Button variant='outlined' onClick={onOpenTableau} size='small'>
              Explore in Tableau
            </Button>
          </InsightsHeaderActionsStyled>
        </InsightsHeaderStyled>
        <InsightsContentStyled>
          <InsightsContentTitleStyled>
            <Typography variant={TypographyVariant.MediumMedium} type={TypographyType.Body}>
              Syte by the Numbers
            </Typography>
          </InsightsContentTitleStyled>
          <InsightsKPIsListStyled>
            {orderedKPIs.map(kpiType => {
              return (
                <KPIItem
                  key={kpiType}
                  shopId={shopId}
                  kpiType={kpiType}
                  filters={filtersState}
                  dataUsageMode={shopMetdata.dataUsageMode}
                  isWaitingForVerification={false}
                />
              );
            })}
          </InsightsKPIsListStyled>
        </InsightsContentStyled>
      </InsightsContainerStyled>
    </SectionWithTitle>
  );
};
