import { Synonym } from '../../types';

export interface PaginationArguments {
  skip: number;
  limit: number;
}

export interface FiltersArguments {
  searchTerm?: string;
  type?: SynonymType;
  status?: SynonymStatus;
  daysSinceLastEdit?: number;
}

export interface GetSynonymsReturnType {
  synonyms: Synonym[];
  pagination: {
    totalCount: number;
  };
}

export interface GetSynonymsArguments extends FiltersArguments, PaginationArguments {
  shopId: number;
  language: SynonymLanguage;
}

export interface GetSynonymByIdArguments
  extends Omit<GetSynonymsArguments, 'filters' | 'pagination' | 'skip' | 'limit'> {
  synonymId: string;
}

export enum SynonymType {
  Custom = 'custom',
  Global = 'global',
}

export enum SynonymStatus {
  PendingIndex = 'pending_index',
  Indexed = 'indexed',
}

export enum SynonymLanguage {
  English = 'english',
  French = 'french',
  German = 'german',
  Italian = 'italian',
  Korean = 'korean',
  Polish = 'polish',
  Portuguese = 'portuguese',
  Spanish = 'spanish',
}

export enum SynonymLanguageCode {
  en = SynonymLanguage.English,
  fr = SynonymLanguage.French,
  de = SynonymLanguage.German,
  it = SynonymLanguage.Italian,
  ko = SynonymLanguage.Korean,
  pl = SynonymLanguage.Polish,
  pt = SynonymLanguage.Portuguese,
  es = SynonymLanguage.Spanish,
}
