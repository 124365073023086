import React from 'react';
import {
  AvailableIcons,
  Button,
  Icon,
  Modal,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { Experiment } from 'src/services';
import { Dispatch } from '../../../types';
import { variantsTableActionMethods } from '../VariantsTable';
import { ChangesPreview } from '../../../FeatureChanges';
import { PreviewTypeEnum } from '../../../../app-types/enums/versioning';

interface ICompareVariantModalProps {
  comparedVariantId: string | undefined;
  dispatch: Dispatch;
  experiment?: Experiment;
}

export const CompareVariantModal: React.FC<ICompareVariantModalProps> = ({
  comparedVariantId,
  experiment,
  dispatch,
}) => {
  const comparedVariant = experiment?.variants?.find(variant => variant.id === comparedVariantId);

  if (!comparedVariant) {
    return null;
  }

  const onCloseModal = () => {
    dispatch(variantsTableActionMethods.resetComparedVariant());
  };

  return (
    <Modal
      className='syte-review-changes-dialog'
      isOpened={Boolean(comparedVariantId)}
      onClickOutside={onCloseModal}
    >
      <>
        <div className='syte-review-changes-dialog-header'>
          <div className='syte-review-changes-dialog-header-title'>
            <Typography
              className='first-row'
              type={TypographyType.Heading}
              variant={TypographyVariant.ExtraSmallBold}
            >
              {`${comparedVariant.name} - Review changes`}
            </Typography>
          </div>
          <Icon
            className='syte-review-changes-dialog-header-icon'
            name={AvailableIcons.Close}
            onClick={onCloseModal}
          />
        </div>

        <div className='syte-review-changes-dialog-content'>
          <ChangesPreview
            changedEntities={comparedVariant.versionChangesSummary.changedEntities}
            previewType={PreviewTypeEnum.COMPARE_VARIANT}
            newValueCustomName={comparedVariant.name}
            readOnly
          />
        </div>
        <div className='syte-review-changes-dialog-footer'>
          <Button variant='secondary' className='cancel-button' onClick={onCloseModal}>
            Close
          </Button>
        </div>
      </>
    </Modal>
  );
};
