import {
  AccountListActionTypes,
  AccountListSectionActionTypes,
  BoughtTogetherActionTypes,
  catalogsListPageActions,
  CollectionRulesActionTypes,
  CollectionSettingsActionTypes,
  CollectionsListActionTypes,
  dataFieldsTableFormActions,
  deepTagReportHowToUploadActions,
  deepTagReportsListActions,
  deepTagReportsListItemActions,
  deepTagsReportProductPageHeaderActions,
  DiscoveryBannerActionTypes,
  EnableUiTestActionTypes,
  ExperimentListActionTypes,
  FeedsMetadataActionTypes,
  lexiconPageHeaderActions,
  lexiconTableActions,
  importLexiconModalActions,
  PersonalizationActionTypes,
  PublishStatusIndicationActionTypes,
  RankingStrategyActionTypes,
  RecEnginesActionTypes,
  RecentlyViewedActionTypes,
  shopFeatureTogglesDeepTagsToggleActions,
  ShopFeatureTogglesFormActionTypes,
  ShopGeneralSettingsActionTypes,
  ShopTheLookActionTypes,
  SimilarItemsActionTypes,
  textSimilarityToggleActions,
  UiTemplateActionTypes,
  UiTemplatesListActionTypes,
  UserListActionTypes,
  versionsTableActions,
  notificationsActions,
  exportLexiconModalActions,
  augmentedSearchSettingsActions,
  relevancyTuningActions,
  UseKnnActionTypes,
  publishNotificationBannerActions,
  synonymsActions,
} from 'src/components-bl';
import { editThematicTagContainerActions } from 'src/containers/Lexicon';
import { adminSearchModalActions } from 'src/components-bl/AdminSearchModal/AdminSearchModal.actions';
import { merchandisingRulesListPageActions } from 'src/components-bl/MerchandisingRules/components/MerchandisingRulesListPage/MerchandisingRulesListPage.actions';
import { dataFieldValuesAutoSuggestActions } from 'src/components-bl/MerchandisingRules/components/DataFieldsCondition/DataFieldValuesAutoSuggest/DataFieldValuesAutoSuggest.actions';
import {
  deepTagReportProductsContainerActions,
  deepTagReportsContainerActions,
  deepTagReportSFTPSettingsModalContainerActions,
  EditCollectionActionTypes,
  editExistingCatalogModalContainerActions,
  EditExperimentContainerActionTypes,
  filterSetsActions,
  loginActions,
  shopAiModelSettingsActions,
  shopApiKeysSettingsActions,
  shopApiKeysSettingsModalContainerActions,
  shopRoutesActions,
  SideNavContainerActionTypes,
  useShopVersionsWSActions,
  useValidateLocaleActions,
  augmentedSearchContainerActions,
  appHeaderActions,
  verifyOneTimeLinkActions,
} from 'src/containers';
import { deepTagsProductContainerActions } from 'src/containers/DeepTagReports/components/DeepTagsProductContainer/Actions/DeepTagsProductContainerActions';
import { rootContainerActions } from 'src/root-container/Actions';
import { merchandisingRulesListContainerActions } from 'src/containers/MerchandisingRules/MerchandisingRulesListContainer';
import { createMerchandisingRuleContainerActions } from 'src/containers/MerchandisingRules/CreateMerchandisingRuleContainer';
import { editMerchandisingRuleContainerActions } from 'src/containers/MerchandisingRules/EditMerchandisingRuleContainer';
import { merchandisingRulesManagementActions } from 'src/components-bl/MerchandisingRules/components/MerchandisingRulesManagement/MerchandisingRulesManagement.actions';
import { merchandisingRulesFormActions } from 'src/components-bl/MerchandisingRules/components/MerchandisingRuleForm';
import { useValidateMerchandisingRuleLimitActions } from 'src/components-bl/MerchandisingRules/components/MaxMerchandisingRulesLimit/use-validate-merchandising-rule-limit.actions';
import { lexiconItemAutoCompleteActions } from 'src/components-bl/Lexicon/components/LexiconTable/Components/LexiconItemAutoComplete';
import { shopListActions } from 'src/components-bl/ShopList/Actions';
import { catalogFormContainerActions } from 'src/containers/CatalogManager/CatalogFormContainer';
import { lexiconSettingsContainerActions } from 'src/containers/LexiconSettingsContainer/Actions/LexiconSettingsContainer.actions';
import { lexiconsContainerActions } from 'src/containers/Lexicon/components/LexiconsContainer/LexiconsContainer.actions';
import { deepTagReportsContainerSwitchActions } from 'src/containers/DeepTagReports/components/DeepTagReportsContainerSwitch/DeepTagReportsContainerSwitch.actions';
import { thematicTagFormActions } from 'src/components-bl/Lexicon/components/ThematicTags/ThematicTagForm/ThematicTagForm.actions';
import { ShopperExperiencesActionTypes } from 'src/components-bl/ShopperExperiences/Actions';
import { catalogManagerContainerActions } from 'src/containers/CatalogManager/CatalogManagerContainer.actions';
import { merchandisingRulesActions } from 'src/components-bl/MerchandisingRules/merchandisingRules.actions';
import { CatalogFeedUploadLogContainerActions } from 'src/containers/CatalogManager/CatalogFeedUploadLogContainer';
import { CatalogUploadErrorReportListContainerActions } from 'src/containers/CatalogManager/CatalogUploadErrorReportListContainer';
import { editFilterContainerActions, filtersContainerActions } from 'src/containers/Filters';
import { notificationCardActions } from 'src/components-bl/Notifications/components/NotificationCard';
import { createFilterPageActions, filtersListPageActions } from 'src/components-bl/Filters';
import { dataFieldAutoSuggestionActions } from 'src/components-bl/Galleries/CustomInspirations/components';
import { useNotificationsWSActions } from 'src/containers/NotificationsContainer/useNotificationsWS';
import { notificationsPopupActions } from 'src/components-bl/Notifications/components/NotificationsPopup/notificationsPopup.actions';
import {
  galleriesTablePageActions,
  editCustomInspirationsGalleryPageActions,
  editInspirationalImagesGalleryPageActions,
  viewInspirationalImagesGalleryPageActions,
  tagImageModalActions,
} from 'src/components-bl/Galleries';
import { deepTagsReportProductsBulkLabelActions } from 'src/components-bl/DeepTagReports/components/DeepTagReportProductsBulkLabelModal';
import { reviewVersionActions } from 'src/components-bl/Versioning/ReviewVersion';
import { useLexiconWSActions } from 'src/components-bl/Lexicon/hooks/useLexiconWS';
import { skuAutoSuggestionActions } from 'src/components-bl/MerchandisingRules/components/MerchandisingRuleForm/components/RuleFormConditionsSection/PinToPosition/SkuAutoSuggestion/SkuAutoSuggestion.actions';
import { lexiconRuleFormActions } from 'src/components-bl/Lexicon/components/LexiconRules/LexiconRuleForm';
import { editLexiconRuleContainerActions } from 'src/containers/Lexicon/components/EditLexiconRuleContainer';
import { navigationActions, NavMidlTypes, toastMiddlewareActions } from '../../middleware-actions';
import { ResultsModalActions } from '../../containers/ResultsModalSettings/components/Actions';
import { visualEditorGetRequestsActionsTypes } from '../../components-bl';
import { CatalogExplorerPageActions } from 'src/components-bl/CatalogManager/CatalogExplorer/CatalogExplorerPage';
import { editCollectionRulesContainerActions } from '../../components-bl/Collections/EditCollectionRules/CollectionRules/CollectionRules/EditCollectionRulesContainerActions';
import { collectionDetailsActions } from '../../components-bl/Collections/EditCollectionRules/CollectionDetails/CollectionDetailsActions';
import { useGalleriesWSActions } from 'src/containers/Galleries/components/CustomInspirations/useGalleriesWS';
import { catalogExplorerContainerActions } from 'src/containers/CatalogManager/CatalogExplorerContainer';
import { lastUserLocationActions } from 'src/components-bl/Home/components/LastUserLocation/LastUserLocation.actions';
import { insightsActions } from 'src/components-bl/Insights/Insights.actions';

export const errorActionsBlackList = new Set([
  rootContainerActions.me.rejected.type,
  catalogFormContainerActions.getShopSFTPSettings.rejected.type,
  importLexiconModalActions.importLexicon.rejected.type,
  CatalogExplorerPageActions.getProducts.rejected.type,
  editCustomInspirationsGalleryPageActions.getApiKeys.rejected.type,
  synonymsActions.importSynonyms.rejected.type,
  synonymsActions.createSynonym.rejected.type,
  synonymsActions.editSynonym.rejected.type,
  insightsActions.getShopInsightsMetdata.rejected.type,
]) as Set<string>;

export const successActionsBlackList = new Set([
  rootContainerActions.me.fulfilled.type,
  rootContainerActions.getPublicMetadata.fulfilled.type,
  rootContainerActions.getPublicMetadata.rejected.type,
  rootContainerActions.initApp.fulfilled.type,
  rootContainerActions.checkExperimentRunning.fulfilled.type,

  navigationActions.confirmNav.type,

  shopListActions.getAllShops.fulfilled.type,

  RankingStrategyActionTypes.GetWeightsSuccess,

  NavMidlTypes.LogoutSuccess,

  dataFieldsTableFormActions.getDataFields.fulfilled.type,

  loginActions.login.fulfilled.type,

  AccountListSectionActionTypes.SearchAccountsSuccess,
  AccountListActionTypes.GetAccountSuccess,

  UserListActionTypes.GetAllUsersSuccess,

  PublishStatusIndicationActionTypes.GetDraftSuccess,
  publishNotificationBannerActions.getDataFields.pending.type,
  publishNotificationBannerActions.getDataFields.fulfilled.type,
  RankingStrategyActionTypes.GetDataFieldsSuccess,
  ExperimentListActionTypes.GetExperimentsSuccess,
  EditExperimentContainerActionTypes.GetExperimentSuccess,

  appHeaderActions.experimentCreated.fulfilled.type,
  appHeaderActions.experimentUpdated.fulfilled.type,

  SideNavContainerActionTypes.GetExperimentSettingsSuccess,
  ShopFeatureTogglesFormActionTypes.GetFeatureTogglesSuccess,
  ShopGeneralSettingsActionTypes.GetSuccess,
  filterSetsActions.getFilters.fulfilled.type,
  filterSetsActions.getDataFields.fulfilled.type,
  FeedsMetadataActionTypes.DeepTagsSuccess,
  RecentlyViewedActionTypes.GetRecentlyViewedSettingsSuccess,
  DiscoveryBannerActionTypes.GetDiscoveryBannerSettingsSuccess,
  CollectionsListActionTypes.GetCollectionsSuccess,
  CollectionsListActionTypes.SortCollectionsSuccess,
  RecEnginesActionTypes.GetRecEnginesGeneralSettingsSuccess,
  ShopTheLookActionTypes.GetShopTheLookSettingsSuccess,
  BoughtTogetherActionTypes.GetBoughtTogetherSettingsSuccess,
  SimilarItemsActionTypes.GetSimilarItemsSettingsSuccess,
  PersonalizationActionTypes.GetPersonalizationSettingsSuccess,
  PersonalizationActionTypes.GetDataFieldsRequest,
  PersonalizationActionTypes.GetDataFieldsSuccess,
  PersonalizationActionTypes.GetDataFieldsError,
  EnableUiTestActionTypes.GetEnableUiTestSuccess,
  CollectionRulesActionTypes.GetRuleGroupsSuccess,
  EditCollectionActionTypes.GetCollectionSuccess,
  CollectionSettingsActionTypes.SaveSuccess,
  CollectionRulesActionTypes.SaveDiffSuccess,
  ShopperExperiencesActionTypes.GetShopperExperiencesSuccess,

  UiTemplatesListActionTypes.GetUiTemplatesSuccess,
  UiTemplateActionTypes.GetUiTemplateSuccess,
  UseKnnActionTypes.GetUseKnnSuccess,

  shopFeatureTogglesDeepTagsToggleActions.getShopProductsDeepTagsPermission.fulfilled.type,

  augmentedSearchSettingsActions.getGeneralSettings.fulfilled.type,

  relevancyTuningActions.getRelevancyTuning.fulfilled.type,

  augmentedSearchContainerActions.getShopCatalogs.fulfilled.type,

  collectionDetailsActions.getDataFields.fulfilled.type,
  editCollectionRulesContainerActions.getDataFields.fulfilled.type,

  deepTagReportsListActions.getReports.fulfilled.type,
  deepTagReportProductsContainerActions.getReport.fulfilled.type,
  deepTagReportProductsContainerActions.getReportProducts.fulfilled.type,
  deepTagsProductContainerActions.getReportProducts.fulfilled.type,
  deepTagsProductContainerActions.getGeneratedDataSettings.rejected.type,
  deepTagsProductContainerActions.getGeneratedDataSettings.fulfilled.type,
  deepTagsReportProductPageHeaderActions.exportReportToFile.fulfilled.type,
  deepTagReportsListItemActions.exportReportToFile.fulfilled.type,
  deepTagReportsContainerActions.getConfiguration.fulfilled.type,
  deepTagReportHowToUploadActions.getSFTPSettings.fulfilled.type,
  deepTagsProductContainerActions.getShopLexiconByLocale.fulfilled.type,
  deepTagsProductContainerActions.generateText.fulfilled.type,
  deepTagReportsContainerSwitchActions.getLexiconsMetadataList.fulfilled.type,
  deepTagReportProductsContainerActions.getLabelsGeneralConfiguration.fulfilled.type,
  deepTagReportProductsContainerActions.getLexiconSettings.fulfilled.type,
  deepTagsReportProductsBulkLabelActions.getLabelsGeneralConfiguration.fulfilled.type,
  deepTagsReportProductsBulkLabelActions.getReportProducts.fulfilled.type,

  lexiconsContainerActions.getLexiconsMetadataList.fulfilled.type,
  useValidateLocaleActions.getLexiconSettings.fulfilled.type,

  lexiconPageHeaderActions.getLexiconMetadata.fulfilled.type,
  lexiconItemAutoCompleteActions.getAvailableTranslations.fulfilled.type,
  lexiconItemAutoCompleteActions.getAvailableVerticals.fulfilled.type,
  lexiconTableActions.getLexiconTags.fulfilled.type,
  lexiconTableActions.getLexiconTagsSummary.fulfilled.type,
  importLexiconModalActions.importLexicon.fulfilled.type,
  exportLexiconModalActions.exportLexicon.fulfilled.type,
  useLexiconWSActions.getLexiconMetadata.fulfilled.type,
  editLexiconRuleContainerActions.getLexiconRule.fulfilled.type,

  merchandisingRulesFormActions.updateAndGetMerchRule.fulfilled.type,
  merchandisingRulesFormActions.getRulesOverview.fulfilled.type,
  merchandisingRulesFormActions.getRulesCountForValidation.fulfilled.type,
  useValidateMerchandisingRuleLimitActions.getRulesCountForValidation.fulfilled.type,
  merchandisingRulesActions.getShopLocales.fulfilled.type,
  merchandisingRulesListPageActions.getRules.fulfilled.type,
  merchandisingRulesListContainerActions.getDataFields.fulfilled.type,
  merchandisingRulesListPageActions.getRulesOverview.fulfilled.type,

  skuAutoSuggestionActions.getValues.fulfilled.type,
  skuAutoSuggestionActions.getProducts.fulfilled.type,
  createMerchandisingRuleContainerActions.getDataFields.fulfilled.type,
  deepTagReportSFTPSettingsModalContainerActions.getShopSFTPSettings.fulfilled.type,
  catalogFormContainerActions.getShopSFTPSettings.fulfilled.type,

  shopRoutesActions.loadShop.fulfilled.type,

  adminSearchModalActions.navigateToShop.type,
  adminSearchModalActions.navigateToAccount.fulfilled.type,
  adminSearchModalActions.navigateToUser.fulfilled.type,
  adminSearchModalActions.navigateTo.type,

  editMerchandisingRuleContainerActions.getRule.fulfilled.type,
  dataFieldValuesAutoSuggestActions.getValues.fulfilled.type,
  merchandisingRulesManagementActions.getRule.fulfilled.type,
  merchandisingRulesManagementActions.getDataFields.fulfilled.type,
  catalogsListPageActions.getCatalogs.fulfilled.type,
  editExistingCatalogModalContainerActions.getCatalog.fulfilled.type,
  catalogFormContainerActions.getSingleCatalog.fulfilled.type,
  catalogFormContainerActions.getShopSFTPSettings.fulfilled.type,
  catalogManagerContainerActions.getCatalogs.fulfilled.type,
  CatalogFeedUploadLogContainerActions.getCatalogFeedUploadFileContent.fulfilled.type,
  CatalogFeedUploadLogContainerActions.getCatalogFeedUploadFileContent.pending.type,
  CatalogExplorerPageActions.getProducts.fulfilled.type,
  catalogExplorerContainerActions.getCatalogDataFields.fulfilled.type,
  catalogExplorerContainerActions.getAITagsDataFields.fulfilled.type,
  catalogExplorerContainerActions.getLexiconsMetadataList.fulfilled.type,
  CatalogUploadErrorReportListContainerActions.getCatalogUploadErrorReport.fulfilled.type,
  CatalogUploadErrorReportListContainerActions.getCatalogUploadErrorReport.pending.type,

  shopAiModelSettingsActions.getAIModelListWithShopModel.fulfilled.type,
  lexiconSettingsContainerActions.getLexiconSettings.fulfilled.type,
  useShopVersionsWSActions.getDraft.fulfilled.type,

  thematicTagFormActions.getAvailableKeys.fulfilled.type,
  thematicTagFormActions.getAvailableTranslations.fulfilled.type,

  lexiconRuleFormActions.getAvailableKeys.fulfilled.type,

  editThematicTagContainerActions.getThematicTag.fulfilled.type,

  shopApiKeysSettingsActions.getApiKeys.fulfilled.type,
  shopApiKeysSettingsModalContainerActions.getApiKey.fulfilled.type,

  filtersListPageActions.getFilterSet.fulfilled.type,
  filtersListPageActions.rearrangeFiltersOrder.fulfilled.type,
  filtersContainerActions.getDataFields.fulfilled.type,
  editFilterContainerActions.getFilter.fulfilled.type,
  createFilterPageActions.getFilterSet.fulfilled.type,
  editFilterContainerActions.getFilterSet.fulfilled.type,
  filtersContainerActions.getDataFields.fulfilled.type,
  ResultsModalActions.getResultsModalGeneralSettings.fulfilled.type,

  versionsTableActions.getShopVersions.fulfilled.type,
  reviewVersionActions.getVersion.fulfilled.type,
  textSimilarityToggleActions.getTextSimilarity.fulfilled.type,
  ...visualEditorGetRequestsActionsTypes,
  galleriesTablePageActions.getGalleries.fulfilled.type,
  editCustomInspirationsGalleryPageActions.getGallery.fulfilled.type,
  editCustomInspirationsGalleryPageActions.startTagsDetection.fulfilled.type,
  editCustomInspirationsGalleryPageActions.getApiKeys.fulfilled.type,
  editInspirationalImagesGalleryPageActions.getGallery.fulfilled.type,
  useGalleriesWSActions.getGallery.fulfilled.type,
  viewInspirationalImagesGalleryPageActions.getGallery.fulfilled.type,

  dataFieldAutoSuggestionActions.getValues.fulfilled.type,

  notificationsActions.getNotifications.pending.type,
  notificationsActions.getNotifications.fulfilled.type,

  notificationCardActions.updateNotification.fulfilled.type,
  notificationCardActions.updateNotification.pending.type,
  notificationCardActions.deleteNotification.fulfilled.type,
  notificationCardActions.deleteNotification.pending.type,
  notificationCardActions.navigateTo.type,

  notificationsPopupActions.markAllAsRead.fulfilled.type,
  notificationsPopupActions.markAllAsRead.pending.type,
  useNotificationsWSActions.create.type,
  useNotificationsWSActions.delete.type,
  useNotificationsWSActions.unreadCount.type,
  useNotificationsWSActions.view.type,

  tagImageModalActions.startImageTagsDetection.fulfilled.type,
  toastMiddlewareActions.forceLogout.fulfilled.type,

  lastUserLocationActions.getCurrentUser.fulfilled.type,
  synonymsActions.getSynonyms.fulfilled.type,
  synonymsActions.getSynonymById.fulfilled.type,
  synonymsActions.importSynonyms.fulfilled.type,

  insightsActions.getLiveProductsNames.fulfilled.type,
  insightsActions.getShopInsightsMetdata.fulfilled.type,
]) as Set<string>;

export const actionsToIgnoreUnauthorized = new Set([
  rootContainerActions.me.rejected.type,
  toastMiddlewareActions.forceLogout.rejected.type,
  toastMiddlewareActions.forceLogout.fulfilled.type,
  loginActions.login.rejected.type,
  verifyOneTimeLinkActions.verifyToken.rejected.type,
  loginActions.resendTwoFactorAuthLink.rejected.type,
]) as Set<string>;
