import React from 'react';
import {
  SelectOnChangeEvent,
  Switch,
  TypographyType,
  TypographyVariant,
} from '../../../../components-dummy';
import { GridSetting } from './GridSetting';
import { DiscoveryBannerGeneralSettings } from '../../../../services';
import {
  Content,
  ContentTitle,
  ToggleContainer,
  ToggleTitle,
} from '../../DiscoveryBannerSettings.styles';

interface GridLayoutSettingsProps {
  discoveryBannerSettings: DiscoveryBannerGeneralSettings['generalSettings'];
  handleConfigChange: (section: string, field: string) => SelectOnChangeEvent;
  onToggle: VoidFunction;
  disabled?: boolean;
}

export const GridLayoutSettings: React.FC<GridLayoutSettingsProps> = ({
  discoveryBannerSettings,
  handleConfigChange,
  onToggle,
  disabled,
}) => (
  <>
    <ContentTitle variant={TypographyVariant.MediumBold} type={TypographyType.Body}>
      Grid Layout
    </ContentTitle>
    <ContentTitle variant={TypographyVariant.MediumRegular} type={TypographyType.Body}>
      Mobile
    </ContentTitle>
    <Content>
      <GridSetting
        title='Image Columns'
        value={discoveryBannerSettings?.mobileGrid?.columns}
        onChange={handleConfigChange('mobileGrid', 'columns')}
        disabled={disabled}
      />
      <GridSetting
        title='Image Rows'
        value={discoveryBannerSettings?.mobileGrid?.rows}
        onChange={handleConfigChange('mobileGrid', 'rows')}
        disabled={disabled}
      />
      <ToggleContainer>
        <Switch.TitleTemplate>
          <ToggleTitle variant={TypographyVariant.MediumRegular} type={TypographyType.Body}>
            Same layout for desktop
          </ToggleTitle>
        </Switch.TitleTemplate>
        <Switch
          checked={discoveryBannerSettings.isSameGrid}
          onChange={onToggle}
          disabled={disabled}
        />
      </ToggleContainer>
    </Content>
    {!discoveryBannerSettings.isSameGrid && (
      <>
        <ContentTitle variant={TypographyVariant.MediumRegular} type={TypographyType.Body}>
          Desktop
        </ContentTitle>
        <Content>
          <GridSetting
            title='Image Columns'
            value={discoveryBannerSettings?.desktopGrid?.columns}
            onChange={handleConfigChange('desktopGrid', 'columns')}
            disabled={disabled}
          />
          <GridSetting
            title='Image Rows'
            value={discoveryBannerSettings?.desktopGrid?.rows}
            onChange={handleConfigChange('desktopGrid', 'rows')}
            disabled={disabled}
          />
        </Content>
      </>
    )}
  </>
);
