import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEqual } from 'lodash';
import { useAppSelector } from '../../../../hooks';
import { visualEditorActions } from '../../state';
import { useNavigateToVisualEditorRoute } from '../../hooks/useNavigateToVisualEditorRoute';
import { CategoryFilterRule } from '../../types/category-filter-rule';
import { PermittedRouteMap } from '../../../../app-routes';

export const useEditCategoryPage = ({
  shopId,
  permittedRouteMap,
}: {
  shopId: number;
  permittedRouteMap: PermittedRouteMap;
}) => {
  const initialCategoryFilterRule = useAppSelector(state => state.visualEditor.categoryFilterRule);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(visualEditorActions.getCategoryFilterRule({ shopId }));
  }, [shopId]);

  const { navigateToVisualEditorRoute } = useNavigateToVisualEditorRoute({
    route: permittedRouteMap?.visualEditor,
  });

  const closeCategoryPageDrawer = useCallback(() => {
    dispatch(visualEditorActions.notifyIsDirty({ isDirty: false }));
    navigateToVisualEditorRoute({ shopId });
  }, [dispatch, navigateToVisualEditorRoute, shopId]);

  const [categoryFilterRule, setCategoryFilterRule] = useState<CategoryFilterRule | undefined>(
    initialCategoryFilterRule
  );

  const onChange = (newRule: CategoryFilterRule) => {
    setCategoryFilterRule(newRule);
  };

  const onSave = () => {
    dispatch(
      visualEditorActions.updateCategoryFilterRule({
        shopId,
        payload: { rule: categoryFilterRule },
      })
    );
    closeCategoryPageDrawer();
  };

  const onDiscard = () => {
    setCategoryFilterRule(initialCategoryFilterRule);
    closeCategoryPageDrawer();
  };

  useEffect(() => {
    setCategoryFilterRule(initialCategoryFilterRule);
  }, [initialCategoryFilterRule]);

  const isDirty = useMemo(() => {
    return !isEqual(initialCategoryFilterRule, categoryFilterRule);
  }, [initialCategoryFilterRule, categoryFilterRule]);

  useEffect(() => {
    dispatch(visualEditorActions.notifyIsDirty({ isDirty }));
  }, [dispatch, isDirty]);

  return {
    closeCategoryPageDrawer,
    categoryFilterRule,
    onChange,
    onSave,
    onDiscard,
    isDirty,
  };
};
