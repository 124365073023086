import React, { ChangeEvent, useCallback } from 'react';
import { getStringEnumValues } from '../../../utils';
import {
  MenuItem,
  SelectType,
  Typography,
  TypographyType,
  TypographyVariant,
} from '../../../components-dummy';
import { BannerStrategy, StrategyOptions } from '../../../app-state-types';
import { Dropdown, DropdownContainer } from '../DiscoveryBannerSettings.styles';
import { DiscoveryBannerGeneralSettings } from '../../../services';

const bannerStrategyDropDownOptions = getStringEnumValues(BannerStrategy).map(value => {
  return {
    value,
    text: StrategyOptions[value],
  };
});

interface StrategyProps {
  strategy: BannerStrategy;
  onChange: (args: Partial<DiscoveryBannerGeneralSettings['generalSettings']>) => void;
  disabled?: boolean;
}

export const Strategy = ({ strategy, onChange, disabled }: StrategyProps) => {
  const onStrategyChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange({ strategy: event.target.value as BannerStrategy });
    },
    [onChange]
  );

  return (
    <DropdownContainer>
      <Typography variant={TypographyVariant.MediumRegular} type={TypographyType.Body}>
        Recommendation Strategy
      </Typography>
      <Dropdown
        placeholder='Select strategy'
        type={SelectType.Menu}
        onChange={onStrategyChange}
        value={strategy}
        disabled={disabled}
      >
        {bannerStrategyDropDownOptions.map((option: { value: string; text: string }) => (
          <MenuItem key={option.value} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
      </Dropdown>
    </DropdownContainer>
  );
};
