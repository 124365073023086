export enum KPITypesEnum {
  Transactions = 'transactions',
  Sessions = 'sessions',
  Impressions = 'impressions', // users-engagement
  Users = 'users',
  CTR = 'ctr',
  CVR = 'cvr',
  AOV = 'aov', // Syte Average Order Value (AOV)
  ARPU = 'arpu', // Syte Average Order Value per User
}

export enum TimeIntervalsEnum {
  Last1Week = 'last_1_week',
  Last1Month = 'last_1_month',
}

export const SupportedTimeIntervals = [TimeIntervalsEnum.Last1Week, TimeIntervalsEnum.Last1Month];

export const presetTimeRangeTextByValue = {
  [TimeIntervalsEnum.Last1Week]: 'Last Week',
  [TimeIntervalsEnum.Last1Month]: 'Last Month',
};

export interface InsightsFilters {
  timeRange?: TimeIntervalsEnum;
  product?: string;
}

export interface IKpiDataReponse {
  value: number;
  valueType: KPIValueTypeEnum;
}

export enum KPIValueTypeEnum {
  Number = 'number',
  Percentage = 'percentage',
  Money = 'money',
}

export const kpiNameByType = {
  [KPITypesEnum.Transactions]: 'Syte Transactions',
  [KPITypesEnum.Sessions]: 'Syte Sessions',
  [KPITypesEnum.Impressions]: 'Syte Impressions',
  [KPITypesEnum.Users]: 'Syte Users',
  [KPITypesEnum.CTR]: 'Syte CTR',
  [KPITypesEnum.CVR]: 'Syte CVR',
  [KPITypesEnum.AOV]: 'Syte AOV',
  [KPITypesEnum.ARPU]: 'Syte ARPU',
};

export const kpiEquasionText = {
  [KPITypesEnum.AOV]: 'Syte Revenue / Syte Transactions',
  [KPITypesEnum.ARPU]: 'Syte Revenue / Syte Users',
  [KPITypesEnum.CVR]: 'Syte Transactions / Syte Users',
  [KPITypesEnum.CTR]: 'Syte Sessions / Syte Clicks',
  [KPITypesEnum.Transactions]: 'Syte Transactions',
  [KPITypesEnum.Sessions]: 'Syte Sessions',
  [KPITypesEnum.Users]: 'Syte Users',
  [KPITypesEnum.Impressions]: 'Syte Impressions',
};

/**
 * Insights  - shop metdata
 */
export enum DataUsageModeEnum {
  Cached = 'cached', // Only fetch stored data (mongo db).
  Live = 'live', // Only fetch live data
  Hybrid = 'hybrid', // Fetch stored if it exists, otherwise fetch live
  Dynamic = 'dynamic', // Decision made dynamically by the backend
}

export interface IInsightsShopMetadataAPIModel {
  isEnabled: boolean;
  currentBatchId?: string;
  updatedBatchIdAt?: Date;
  productsNames?: string[];
  kpiTypes?: KPITypesEnum[];
  timeIntervals?: TimeIntervalsEnum[];
  dataUsageMode?: DataUsageModeEnum; // default: "cached" (stored) if will changed to "live" will fetch data directly from snowflake (not stored)
  shouldUpdatePeriodically?: boolean; // will continue to fetch and store data from snowflake (into insightAnalytics docs)
  hasSessionsInTheLastWeek: boolean;
}
