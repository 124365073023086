import styled from '@emotion/styled';
import { Icon } from 'src/components-dummy';

export const EnvironmentCard = styled.div`
  display: flex;
  align-items: center;
  width: 150px;
  height: 40px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.custom['gray-100']};
  background: ${({ theme }) => theme.palette.custom.yellow};
  border-radius: 4px;
  position: absolute;
  top: 20px;
  left: 275px;
  padding: 0 10px;
  gap: 8px;

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0;
    color: ${({ theme }) => theme.palette.custom['gray-10']};
  }

  svg {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
  }

  svg path:nth-of-type(1) {
    fill: ${({ theme }) => theme.palette.custom['gray-10']} !important;
  }
  svg path:nth-of-type(2) {
    fill: ${({ theme }) => theme.palette.custom.yellow} !important;
  }
  svg path:nth-of-type(3) {
    fill: ${({ theme }) => theme.palette.custom.yellow} !important;
  }
`;

export const EnvironmentIconStyled = styled(Icon)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
