import { insightsActions } from 'src/components-bl/Insights/Insights.actions';
import { createReducer } from '@reduxjs/toolkit';
import { InsightsReducerState } from '../app-state-types/reducer-state-types/insights';
const initialState: InsightsReducerState = {
  insightsShopMetadata: undefined,
};
export const insightsReducer = createReducer(initialState, builder => {
  builder.addCase(insightsActions.getShopInsightsMetdata.pending, state => {
    return {
      ...state,
      insightsShopMetdata: undefined,
    };
  });
  builder.addCase(insightsActions.getShopInsightsMetdata.fulfilled, (state, action) => {
    return {
      ...state,
      insightsShopMetdata: action.payload.insightsShopMetadata,
    };
  });
});
