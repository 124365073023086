import React from 'react';
import { RoutedComponentProps } from 'src/app-routes';
import { Dispatch, SynonymsLanguagePage } from 'src/components-bl';

interface SynonymsLanguagesListContainerProps extends RoutedComponentProps {
  shopId: number;
  hasAugmentedSearchCatalog: boolean | null;
  availableLanguageCodes: string[];
  dispatch: Dispatch;
}

export const SynonymsLanguagesListContainer = ({
  shopId,
  hasAugmentedSearchCatalog,
  availableLanguageCodes,
  permittedRouteMap,
  dispatch,
}: SynonymsLanguagesListContainerProps): JSX.Element => {
  return (
    <SynonymsLanguagePage
      shopId={shopId}
      hasAugmentedSearchCatalog={hasAugmentedSearchCatalog}
      dispatch={dispatch}
      availableLanguages={availableLanguageCodes}
      permittedRouteMap={permittedRouteMap}
    />
  );
};
