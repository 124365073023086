import styled from '@emotion/styled';
import { Select } from 'src/components-dummy';

/**
 * Insights
 */
export const InsightsContainerStyled = styled.div`
  display: flex;
  width: fit-content;
  flex-direction: column;
  min-height: 429px;
  min-width: 100%;
  padding: 27px 53px 47px 49px;
  min-width: 872px;
  border-radius: 24px;
  gap: 5px;
  background: ${({ theme }) => theme.palette.custom.white};
  box-shadow:
    0px 12px 42px -4px rgba(0, 0, 0, 0.12),
    0px 8px 18px -6px rgba(0, 0, 0, 0.12);
`;

/**
 * Header
 */
export const InsightsHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const InsightsHeaderFiltersStyled = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const InsightsHeaderActionsStyled = styled.div`
  display: flex;
`;

export const InsightsContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InsightsContentTitleStyled = styled.div`
  color: #7b7b7e;
  padding: 25px 0px;
`;

export const SelectTimeRangeStyled = styled(Select)`
  .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input {
    display: flex;
    align-items: center;
    padding: 5px 30px 5px 34px;
    height: 26px;
    color: ${({ theme }) => theme.palette.custom['gray-10']};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    border: 1px solid white;
    border-radius: 4px;
  }

  svg.syte-basic-icon.select-caret {
    right: 8px;
    & > path {
      stroke: ${({ theme }) => theme.palette.custom['gray-10']};
      stroke-width: 1.8;
    }
  }

  &[data-id='insights_menuSelect-false'] {
    .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input {
      color: var(--Primary-Main, #0073ff);
      border: 1px solid #0073ff;
      padding: 5px 30px 5px 34px;
      background: var(--Common-White, #fff);
    }

    svg.syte-basic-icon.select-caret path {
      stroke: #0073ff;
    }
  }
`;

export const SelectTimeRangeWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  svg.syte-basic-icon.calendar-icon {
    position: absolute;
    left: 8px;
    z-index: 1;
  }

  [data-id='insights_menuSelect-false'] ~ svg.syte-basic-icon.calendar-icon path {
    stroke: #0073ff;
  }
`;

export const SelectProductWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  .filter-icon {
    path {
      stroke: #0073ff;
      stroke-width: 1;
    }
    position: absolute;
    left: 8px;
  }

  .drop-down-select {
    display: flex;
    height: 26px;
    padding: 5px 8px 5px 34px;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #0073ff;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  .drop-down-select {
    background: ${({ theme }) => theme.palette.custom.white};
    color: #0073ff;
  }

  .select-caret {
    width: 15px;
    height: 15px;
    stroke: #0073ff;
    fill: #0073ff;
    stroke-width: 0.8;

    path {
      stroke: #0073ff;
      fill: #0073ff;
    }
  }

  .opened {
    .select-caret {
      stroke: ${({ theme }) => theme.palette.custom['gray-10']};
      fill: ${({ theme }) => theme.palette.custom['gray-10']};

      path {
        stroke: ${({ theme }) => theme.palette.custom['gray-10']};
        fill: ${({ theme }) => theme.palette.custom['gray-10']};
      }
    }

    .drop-down-select {
      border: 1px solid transparent;
      background: #cce3ff;
      color: ${({ theme }) => theme.palette.custom['gray-10']};
    }
  }

  .opened ~ .filter-icon {
    path {
      stroke: ${({ theme }) => theme.palette.custom['gray-10']};
      fill: #cce3ff;
    }

    rect {
      fill: #cce3ff;
    }
  }
`;

export const LastUpdatedAtStyled = styled.div`
  color: ${({ theme }) => theme.palette.custom['gray-20']};
`;

/**
 * Kpis
 */
export const InsightsKPIsListStyled = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-start;
`;

export const InsightsKPIStyled = styled.div`
  display: flex;
  width: calc(33.33% - 16px);
  min-width: 240px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  height: 112px;

  border-radius: 8px;
  background: ${({ theme }) => theme.palette.custom.white};

  box-shadow:
    0px 2px 4px -2px rgba(0, 0, 0, 0.08),
    0px 2px 8px -2px rgba(0, 0, 0, 0.08);
`;

export const InsightsKPIHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  min-height: 32px;
  margin-bottom: 16px;
`;

export const InsightsKPIFooterStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const SeparatorStyled = styled.div`
  width: 1px;
  height: 20px;
  background: ${({ theme }) => theme.palette.custom['black-10']};
`;

export const KpiInfoWrapperStyled = styled.div`
  & > svg {
    width: 21.4px;
    height: 21.4px;
    display: flex;
    align-items: center;

    path {
      fill: #dcdcdc;
      stroke: white;
    }
  }
`;
