import React from 'react';
import { CollapseDrawer } from '../CollapseDrawer/CollapseDrawer';
import { useEditMerchRule } from '../../hooks';
import { useIsFeaturePermitted } from '../../../../hooks';
import { useEditCategoryPage } from '../EditCategoryPage';
import { EditMerchandisingRuleContainer } from './EditMerchRuleContainer';
import { useNavigateToVisualEditorRoute } from '../../hooks/useNavigateToVisualEditorRoute';
import { ShopFeature } from '../../../../app-types';
import { PermittedRouteMap } from '../../../../app-routes';
import { Mode } from '../../types/mode';
import { ShopFeatureToggles } from '../../../../services/src/service/types/shops';

interface Props {
  shopId: number;
  permittedRouteMap: PermittedRouteMap;
  className: string;
  featureToggles: ShopFeatureToggles;
}

export const CreateMerchandisingRule = ({
  shopId,
  permittedRouteMap,
  className,
  featureToggles,
}: Props): JSX.Element | null => {
  const {
    submitCreateMerchRuleForm,
    closeMerchandisingRulesDrawer,
    merchRuleFormKey,
    entityId,
    merchRuleProduct,
    isMerchRuleFormSubmitting,
  } = useEditMerchRule(shopId, permittedRouteMap);
  const { categoryFilterRule } = useEditCategoryPage({
    shopId,
    permittedRouteMap,
  });
  const isMerchRulesAiTagsEnabled = useIsFeaturePermitted(ShopFeature.MerchandisingRulesAiTags);
  const { navigateToVisualEditorRoute } = useNavigateToVisualEditorRoute({
    route: permittedRouteMap?.visualEditor,
  });

  return (
    <CollapseDrawer
      open
      className={className}
      onClose={() => navigateToVisualEditorRoute({ shopId })}
    >
      <EditMerchandisingRuleContainer
        key={merchRuleFormKey}
        shopId={shopId}
        mode={Mode.create}
        entityId={entityId}
        rule={null}
        predefinedProduct={merchRuleProduct}
        isAiTagsEnabled={isMerchRulesAiTagsEnabled}
        onSave={submitCreateMerchRuleForm}
        onClose={closeMerchandisingRulesDrawer}
        isSubmitting={isMerchRuleFormSubmitting}
        categoryFilterRule={categoryFilterRule}
        featureToggles={featureToggles}
      />
    </CollapseDrawer>
  );
};
